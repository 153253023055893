import { Component } from '@angular/core';

@Component({
  selector: 'app-account-info-public',
  templateUrl: './account-info-public.component.html',
  styleUrls: ['./account-info-public.component.scss']
})
export class AccountInfoPublicComponent {

}
