import { CookiesPost } from './../../models/CookiesPost.model copy';
import { Component, OnInit } from '@angular/core';
import DateUtil from 'src/app/helper/Date.Util';
import { FinanceMagicCookiseService } from 'src/app/services/FinancemagicCookies.service';

import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-cookies-box',
  templateUrl: './cookies-box.component.html',
  styleUrls: ['./cookies-box.component.scss']
})

export class CookiesBoxComponent implements OnInit {
  public isCoockiesShow:boolean = true;
  public isMannageCoockies:boolean = false;
  public _financemagicCookiesServices: FinanceMagicCookiseService;
  public _cookies : CookiesPost

  constructor(
    financemagicCookiesServices: FinanceMagicCookiseService,
    private deviceService: DeviceDetectorService
  ) {
   this._financemagicCookiesServices = financemagicCookiesServices
   this._cookies = {} as CookiesPost;
  }

  ngOnInit(): void {
    this._cookies.CreationDate = DateUtil.getDefaultDateTime();
    this._cookies.IsEssential = false;
    this._cookies.IsTargetedAdvertising = false;
    this._cookies.IsAnalytics = false;

    this.getDeviceId();
    this.getCookieSetting();
  }

  public setCoockies() {
    this.sendeCookies();
  }

  public getDeviceId(){
    this._cookies.DeviceId = 0;
    console.log(this.deviceService.getDeviceInfo());
    console.log(this.deviceService.isDesktop());
  }

  public rejectCookies(){
    this.isCoockiesShow = false;
    console.log("cookies rejected")
  }

  public changeMannageCoockies(){
    this.isMannageCoockies = !this.isMannageCoockies;
  }

  public setEssential(){
    this._cookies.IsEssential = true;
    this._cookies.IsTargetedAdvertising = false;
    this._cookies.IsAnalytics = false;
    console.log(this._cookies.IsEssential)
  }

  public setTargeted(){
    this._cookies.IsEssential = false;
    this._cookies.IsTargetedAdvertising = true;
    this._cookies.IsAnalytics = false;
    console.log(this._cookies.IsTargetedAdvertising)
  }

  public setAnalytics(){
    this._cookies.IsEssential = false;
    this._cookies.IsTargetedAdvertising = false;
    this._cookies.IsAnalytics = true;
    console.log(this._cookies.IsAnalytics)
  }

  public sendeCookies(){
    try {
      
      this._financemagicCookiesServices.postCookise(this._cookies).subscribe({
        next: (responseData) => {
          console.log(responseData);
          this.isCoockiesShow = false;
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  public getCookieSetting(){
    try {
      
      this._financemagicCookiesServices.getByDeviceId(this._cookies.DeviceId).subscribe({
        next: (responseData) => {
          console.log(responseData);
          //this.isCoockiesShow = false;
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

}
