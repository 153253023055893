import { Component } from '@angular/core';

@Component({
  selector: 'app-project-add-form',
  templateUrl: './project-add-form.component.html',
  styleUrls: ['./project-add-form.component.scss']
})
export class ProjectAddFormComponent {

}
