import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import StorageKeyName from '../helper/StorageKeyName';
import { CompanyStartupsKeyInformation } from '../models/CompanyStartupsKeyInformation.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyStartupsKeyInformationService {
    headers: HttpHeaders = new HttpHeaders();
    baseApiUrl: string = environment.UVCSERVERBASEAPI;
    requestOptions: any = {};

    constructor(private http: HttpClient) {
        this.initHTTPHeaderWithAutorization();
    }
    private initHTTPHeaderWithAutorization() {
        this.requestOptions.headers = new HttpHeaders();
        var refreshToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);
        this.requestOptions.headers = this.requestOptions.headers.set('Content-Type', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Accept', 'application/json');

        if (accessToken != null && accessToken.length > 0) {
            this.requestOptions.headers = this.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
            this.requestOptions.headers = this.requestOptions.headers.set('refreshToken', refreshToken);
        }

    }

    post(companyStartupsKeyInformation: CompanyStartupsKeyInformation): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/companystartupskeyinformation/post', companyStartupsKeyInformation, this.requestOptions);
    }

    put(companyStartupsKeyInformation: CompanyStartupsKeyInformation): Observable<any> {

        return this.http.put<any>(
            this.baseApiUrl + '/api/companystartupskeyinformation/put', companyStartupsKeyInformation, this.requestOptions);
    }


    getKeyInformationByCompanyIdAndUserId(UserId, UserCompanyId): Observable<any> {

        this.initHTTPHeaderWithAutorization();

        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupskeyinformation/getkeyinformationbycompanyidanduserid?UserCompanyId=' + UserCompanyId + '&UserId=' + UserId, this.requestOptions);
    }


    GetCompanyStartupsKeyInformationByCompanyStartupsIdeaInformationGuid(CompanyStartupsIdeaInformationId): Observable<any> {

        this.initHTTPHeaderWithAutorization();

        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupskeyinformation/getcompanystartupskeyinformationbycompanystartupsideainformationId?CompanyStartupsIdeaInformationId=' + CompanyStartupsIdeaInformationId, this.requestOptions);
    }


    getCompanystartupskeyinformationKeyInformationById(Id): Observable<any> {

        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupskeyinformation/getkeyinformationbyid?Id=' + Id, this.requestOptions);
    }




}