import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GoogleMap } from '@angular/google-maps';
import { emailValidator } from '../../utils/app-validators';
import { UAMUsersServices } from 'src/app/services/UAMUsers.service';
import { MediaService } from 'src/app/services/Media.service';
import { User } from 'src/app/models/User.model';
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';

@Component({
    selector: 'app-marketing-footer',
    templateUrl: './marketing-footer.component.html',
    styleUrls: ['./marketing-footer.component.scss']
})

export class MarketingFooterComponent implements OnInit {
    @ViewChild(GoogleMap) map: GoogleMap;
    center: google.maps.LatLngLiteral = { lat: 51.5075163, lng: -0.0739693 };
    zoom: number = 12;

    public _uAMUsersServices: UAMUsersServices;
    public _mediaService: MediaService;
    public _currentUser = {} as User;
    public _user: User;
    markerOptions: google.maps.MarkerOptions = { draggable: false };
    markerPositions: google.maps.LatLngLiteral[] = [
        { lat: 51.5075163, lng: -0.0739693 }
    ];

    mapStyles: any = [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "saturation": 36
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 40
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "visibility": "on"
                },
                {
                    "color": "#000000"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 17
                },
                {
                    "weight": 1.2
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#8b9198"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 20
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#323336"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#414954"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 21
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#2e2f31"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#7a7c80"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#242427"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#202022"
                },
                {
                    "lightness": 29
                },
                {
                    "weight": 0.2
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 18
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#393a3f"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#202022"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#393a3f"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#202022"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 19
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 17
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#202124"
                }
            ]
        }
    ];

    mapOptions: google.maps.MapOptions = {
        styles: this.mapStyles
    }
    feedbackForm: UntypedFormGroup;
    subscribeForm: UntypedFormGroup;

    constructor(
        public formBuilder: UntypedFormBuilder,
        uMAUsersServices: UAMUsersServices,
        mediaService: MediaService,
    ) {
        this._uAMUsersServices = uMAUsersServices;
        this._user = {} as User;
        this._mediaService = mediaService;
    }

    ngOnInit() {
        this.feedbackForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, emailValidator])],
            message: ['', Validators.required]
        });
        this.subscribeForm = this.formBuilder.group({
            email: ['', Validators.compose([Validators.required, emailValidator])]
        })
        this._currentUser = AuthProviderHelper.getCurrentUser();
        this.GetUserByUserId(this._currentUser.Id);
    }

    public async GetUserByUserId(UserId: number) {
      try {
        this._uAMUsersServices.getuserById(UserId).subscribe({
          next: (response) => {
            
            if (response.Success = true) {
              this._user=response.ResponseData.User;
              // const formattedDate = this.convertToHTMLDate(this._user.DateofBirth);
              // this._user.DateofBirth = formattedDate;
              // console.log(this._user);
            }
            
          },
          error: (response) => {
            console.log(response);
          },
        });
      } catch (error) {
        console.error(error);
      }
    }

    ngAfterViewInit() {
        // this.map.googleMap.setOptions({styles: this.mapStyles});
    }

    public onFeedbackFormSubmit(values: Object): void {
        if (this.feedbackForm.valid) {
            console.log(values);
        }
    }

    public onSubscribeFormSubmit(values: Object): void {
        if (this.subscribeForm.valid) {
            console.log(values);
        }
    }

}
