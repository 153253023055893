
import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl,UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router'; 
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { PostEmailPassoward } from 'src/app/models/PostEmailPassoward';
import { PostLoginByEmail } from 'src/app/models/PostLoginByEmail.model';
import { PostLoginByPhone } from 'src/app/models/PostLoginByPhone.model';
import { SentOtpPhone } from 'src/app/models/SentOtpPhone.model';
import { User } from 'src/app/models/User.model';
import { CompanyStartupsKeyInformationService } from 'src/app/services/CompanyStartupsKeyInformation.service';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import { DealRoomService } from 'src/app/services/DealRoom.service';
@Component({
  selector: 'app-project-details-key-info',
  templateUrl: './project-details-key-info.component.html',
  styleUrls: ['./project-details-key-info.component.scss']
})
export class ProjectDetailsKeyInfoComponent implements OnInit {
  
  public _creditMatrixLoginService: CreditMatrixLoginService;
  public _companyStartupsKeyInformationService: CompanyStartupsKeyInformationService;
  
 // public _dealRoomService: DealRoomService;

  // public _PostLoginByEmailData: PostLoginByEmail;
  // public _PostLoginByMobileData: PostLoginByPhone;
  // public _SentOtpPhoneModle: SentOtpPhone;
  // public _PostEmailPassoward: PostEmailPassoward;

  isLoading = true;
  public hide = true;
  public KeyInfoList:any;
  dynamicForm!: FormGroup;
  public isLoginByEmail = true;
  public isOtpRequestSent = false;
  public submitted=false;
  public ErrorMessage:string="";
  public isSuccess=true;
  ticketFormGroup: FormGroup[];
  public ticket: UntypedFormGroup;
  public ticketforPhone: UntypedFormGroup;
  public _currentUser: User;


  
  constructor(
    public router:Router,
    public formBuilder: FormBuilder,
    creditMatrixLoginService: CreditMatrixLoginService,
    companyStartupsKeyInformationService: CompanyStartupsKeyInformationService,
    private fb: FormBuilder
  ) {
    this._companyStartupsKeyInformationService= companyStartupsKeyInformationService;
    this._creditMatrixLoginService = creditMatrixLoginService;
    // this._PostLoginByEmailData = {} as PostLoginByEmail;
    // this._PostLoginByMobileData = {} as PostLoginByPhone;
    // this._SentOtpPhoneModle = {} as SentOtpPhone;
    // this._PostEmailPassoward = {} as PostEmailPassoward;
    this._currentUser = AuthProviderHelper.getCurrentUser();

    
  }
  ngOnInit() {
    this.GetAllKeyInformation();
  } 
  public async GetAllKeyInformation() {
    this.isLoading=true
    try {
      this._companyStartupsKeyInformationService.getKeyInformationByCompanyIdAndUserId( this._currentUser.Id, this._currentUser.UserCompanyId ).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null) {
            this.KeyInfoList=responseData.ResponseData.companyStartupsKeyInfoData;
            this.isLoading=false;
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

}


