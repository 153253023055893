import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IntroducerTypeItemModel } from 'src/app/models/IntroducerTypeItem.model copy';
import { SignUpQrComponent } from '../sign-up-qr/sign-up-qr.component';

@Component({
  selector: 'app-introducer-type-card',
  templateUrl: './introducer-type-card.component.html',
  styleUrls: ['./introducer-type-card.component.scss']
})
export class IntroducerTypeCardComponent implements OnInit {

  @Input() introducerTypeItem:IntroducerTypeItemModel ;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
  }

  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }

}
