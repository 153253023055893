import { Component } from '@angular/core';

@Component({
  selector: 'app-compare-loans-header',
  templateUrl: './compare-loans-header.component.html',
  styleUrls: ['./compare-loans-header.component.scss']
})
export class CompareLoansHeaderComponent {

}
