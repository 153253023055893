import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-cards-trailer',
  templateUrl: './credit-cards-trailer.component.html',
  styleUrls: ['./credit-cards-trailer.component.scss']
})
export class CreditCardsTrailerComponent {

  cardViewInfo = [
    {
      childCardViewInfo: [
        {
          id: 1,
          headingTitle: 'Credit card with 0% balance transfer',
          description:
            'This card allows you to pay less interest on your existing credit card balance. You can transfer your balance to a 0% balance transfer card and pay no interest for a set period of time. Remember that you may be charged a one-time transfer fee.',
        },
        {
          id: 2,
          headingTitle: 'Credit builder card ',
          description:
            "If you've never used credit before or have a history of late payments, your credit rating may be lower than you'd like. A credit builder card can assist you in establishing a credit history. As long as you make your payments on time, this type of card can help you improve your credit score over time.",
        },
      ],
    },

    {
      childCardViewInfo: [
        {
          id: 3,
          headingTitle: 'Purchase card with 0% interest',
          description:
            'When you plan to make a large purchase, you can spread the cost out without paying interest by using a 0% purchase card. You can enjoy no interest on your purchases as long as you pay off the balance on the card before the end of the initial 0% period.',
        },
        {
          id: 4,
          headingTitle: 'Reward card',
          description:
            'A reward credit card functions similarly to any other credit card, with the exception that you earn points or cashback every time you use it. The more frequently you use your credit card, the more points or cashback you will earn. Depending on the card, you can use your points to pay for flights, hotel stays, gas, or shopping.',
        },
      ],

    },
  ];
}
