import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { PostEmailPassoward } from 'src/app/models/PostEmailPassoward';
import { PostLoginByEmail } from 'src/app/models/PostLoginByEmail.model';
import { PostLoginByPhone } from 'src/app/models/PostLoginByPhone.model';
import { SentOtpPhone } from 'src/app/models/SentOtpPhone.model';
import { User } from 'src/app/models/User.model';
import { CompanyStartupsDocumentInfoService } from 'src/app/services/CompanyStartupsDocumentInfo.service';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import { DealRoomService } from 'src/app/services/DealRoom.service';


@Component({
  selector: 'app-project-documents-tab',
  templateUrl: './project-documents-tab.component.html',
  styleUrls: ['./project-documents-tab.component.scss']
})
export class ProjectDocumentsTabComponent {
  public _creditMatrixLoginService: CreditMatrixLoginService;
  public _companyStartupsDocumentInfoService: CompanyStartupsDocumentInfoService;

  public _dealRoomService: DealRoomService;
  public _PostLoginByEmailData: PostLoginByEmail;
  public _PostLoginByMobileData: PostLoginByPhone;
  public _SentOtpPhoneModle: SentOtpPhone;
  public _PostEmailPassoward: PostEmailPassoward;
  public isShowuBOpopup = false;

  public addDocumentDiv = false;
  public hide = true;
  public documentInfoList: any;
  dynamicForm!: FormGroup;
  public isLoginByEmail = true;
  public isOtpRequestSent = false;
  public submitted = false;
  public ErrorMessage: string = "";
  public documentUrl: string = "";
  public isSuccess = true;
  ticketFormGroup: FormGroup[];
  public ticket: UntypedFormGroup;
  public ticketforPhone: UntypedFormGroup;
  public _currentUser: User;



  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    creditMatrixLoginService: CreditMatrixLoginService,
    dealRoomService: DealRoomService,
    private fb: FormBuilder,
    companyStartupsDocumentInfoService: CompanyStartupsDocumentInfoService
  ) {
    this._dealRoomService = dealRoomService;
    this._creditMatrixLoginService = creditMatrixLoginService;
    this._PostLoginByEmailData = {} as PostLoginByEmail;
    this._PostLoginByMobileData = {} as PostLoginByPhone;
    this._SentOtpPhoneModle = {} as SentOtpPhone;
    this._PostEmailPassoward = {} as PostEmailPassoward;
    this._companyStartupsDocumentInfoService = companyStartupsDocumentInfoService;
    this._currentUser = AuthProviderHelper.getCurrentUser();


  }
  
  ngOnInit() {
    this.GetAllStartUpsDocumentInfo();
  }

  openAddDocument() {
    this.addDocumentDiv = !this.addDocumentDiv;
  }

  public async GetAllStartUpsDocumentInfo() {

    try {
      this._companyStartupsDocumentInfoService.GetCompanyStartupsDocumentInfoByUserIdAndUserCompanyId(this._currentUser.Id, this._currentUser.UserCompanyId).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null) {
            this.documentInfoList = responseData.ResponseData.CompanyStartupsDocumentInfoList;
            //this.documentUrl=this.getFilename(documentInfoList.);
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  public openUBOPopup(data) {
    
    if (data) {
      localStorage.setItem(StorageKeyName.PROJECT_DOCUMENT_ID, data.Id);
      localStorage.setItem(StorageKeyName.PROJECT_DOCUMENT_Edit_Date, data);
    } else {
      localStorage.setItem(StorageKeyName.PROJECT_DOCUMENT_ID, "");
      localStorage.setItem(StorageKeyName.PROJECT_DOCUMENT_Edit_Date, "");
    }

    this.GetAllStartUpsDocumentInfo();
    if(this.isShowuBOpopup == false){
      this.isShowuBOpopup = true;
    }else{
      this.isShowuBOpopup= false
    }
    
  }

  public DownloadeDocment(DownloadDocById: number) {
    this.DownloadDocById(DownloadDocById)
  }

  public async DownloadDocById(DownloadDocById: number) {
    try {
      this._companyStartupsDocumentInfoService.downloadAmazonS3FileUrlByCompanyStartupsDocumentInfoId(DownloadDocById).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null) {
            var binaryString = window.atob(responseData.ResponseData.fileContents);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
              var ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            var blob = new Blob([bytes], { type: responseData.ResponseData.contentType });
            var url = window.URL.createObjectURL(blob);

            var link = document.createElement("a");
            link.setAttribute("href", url);
            // var imageUrlList = imageUrl.split('.');
            var name = responseData.ResponseData.fileDownloadName;
            //var name = mortgageCaseDocumentItem.Name.replaceAll(' ', '_') + '.' + imageUrl.split('.')[1];
            link.setAttribute("download", name);
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  getFilename(url: string): string {
    const segments = url.split('/');
    return segments.pop() || '';
  }


  viewDocument(url) {

    //window.open(url, "_blank");    

    try {
      this._companyStartupsDocumentInfoService.getAmazonS3FileUrlByWebFileUrl(url).subscribe({
        next: (responseData) => {
          // 
          if (responseData.Success) {

            window.open(responseData.ResponseData.FileUrl, "_blank");
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  downloadDocument(companyStartupsDocument) {

    //window.open(url, "_blank");    

    try {
      this._companyStartupsDocumentInfoService.downloadAmazonS3FileUrlByCompanyStartupsDocumentInfoId(companyStartupsDocument.Id).subscribe({
        next: (response) => {
          // 
          if (response.Success) {

            var binaryString = window.atob(response.ResponseData.fileContents);
            var binaryLen = binaryString.length;
            var bytes = new Uint8Array(binaryLen);
            for (var i = 0; i < binaryLen; i++) {
              var ascii = binaryString.charCodeAt(i);
              bytes[i] = ascii;
            }

            var blob = new Blob([bytes], { type: response.ResponseData.contentType });
            var url = window.URL.createObjectURL(blob);

            var link = document.createElement("a");
            link.setAttribute("href", url);
            var imageUrlList = companyStartupsDocument.DocumentUrl.split('.');
            var name = companyStartupsDocument.Name.replaceAll(' ', '_') + '.' + imageUrlList[imageUrlList.length - 1];
            link.setAttribute("download", name);
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }


  public async deleteCompanyStartUpsDocumentInfoByCompanyStartupsDocumentInfoId(id: number) {
    try {
      this._companyStartupsDocumentInfoService.deleteStartUpsDocumentInfoById(id).subscribe({
        next: (response) => {

          if (response.Success) {
            this.GetAllStartUpsDocumentInfo();
          }
          else {

          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }


}
