import { Component, Input } from '@angular/core';
import { CompanyStartupsIdeaInformation } from 'src/app/models/CompanyStartupsIdeaInformation.model';
import { DealRoomModel } from 'src/app/models/DealRoom.model';

@Component({
  selector: 'app-room-item',
  templateUrl: './room-item.component.html',
  styleUrls: ['./room-item.component.scss']
})
export class RoomItemComponent  {

  @Input() profile:DealRoomModel;

  //@Input() _companyStartupsIdeaInformation:CompanyStartupsIdeaInformation;

  constructor() {}

  ngOnInit() {}

}
