import { Component } from '@angular/core';

@Component({
  selector: 'app-portfolio-chart',
  templateUrl: './portfolio-chart.component.html',
  styleUrls: ['./portfolio-chart.component.scss']
})
export class PortfolioColumnChartComponent {
  // for column chart
  chartOptions = {
    title: {
      text: ""
    },
    data: [{
      type: "column",
      color: "#358DB2",
      dataPoints: [
        { label: "Aug",  y: 25  },
        { label: "Sep", y: 42  },
        { label: "Oct", y: 28  },
        { label: "Nov",  y: 20  },
        { label: "Dec",  y: 22  }
      ]
    }]                
  };

// for pie chart
  chartOption = {
	  animationEnabled: true,
	  title:{
		text: ""
	  },
	  data: [{
		type: "doughnut",
		yValueFormatString: "#,###.##'%'",
		indexLabel: "{name}",
		dataPoints: [	 
      { y: 50, name: "50% Progress Fund" },
      { y: 5, name: "5% Bismillah Fund" },
		  { y: 15, name: "15% Humble Fund" },
      { y: 30, name: "30% Smart Fund" },
		 
		]
	  }]
	}	
}
