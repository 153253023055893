import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortgagemagicProperty } from '../models/MortgagemagicProperty.model';
import { ContactPost } from '../models/ContactPost.model';
import { PostLoginByEmail } from '../models/PostLoginByEmail.model';
import { SentOtpPhone } from '../models/SentOtpPhone.model';
import { PostLoginByPhone } from '../models/PostLoginByPhone.model';
import StorageKeyName from '../helper/StorageKeyName';
import { PostEmailPassoward } from '../models/PostEmailPassoward';
import { CompanyStartupsIdeaInformation } from '../models/CompanyStartupsIdeaInformation.model';
import { CompanyStartupsKeyInformation } from '../models/CompanyStartupsKeyInformation.model';
import { CompanyStartupsTeams } from '../models/CompanyStartupsTeams.model';
import { CompanyStartupsDocumentInfo } from '../models/CompanyStartupsDocumentInfo.model';

@Injectable({
    providedIn: 'root'
})
export class DealRoomService {
    headers: HttpHeaders = new HttpHeaders();
    
    baseApiUrl: string = environment.UVCSERVERBASEAPI;
    requestOptions:any={};

    constructor(private http: HttpClient) {
        this.initHTTPHeaderWithAutorization();
    }
    private initHTTPHeaderWithAutorization() {
        this.requestOptions.headers = new HttpHeaders();
        var getToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);
        this.requestOptions.headers = this.requestOptions.headers.set('Content-Type', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Accept', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
        this.requestOptions.headers = this.requestOptions.headers.set('refreshToken', getToken);
    }

    getDealRoomInfo(): Observable<any> {
        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/getallcompanyinfo', this.requestOptions);
    }
    
    getDealRoomInfoById(Id): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/getallcompanyinfobyid?Id=' + Id, this.requestOptions);
    }

    getIdeaInformationById(Id): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/getideainformationbyid?Id=' + Id, this.requestOptions);
    }
    postIdeaInformation(companyStartupsIdeaInformation: CompanyStartupsIdeaInformation): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/postideainformation', companyStartupsIdeaInformation, this.requestOptions);
    }
    updateIdeaInformation(companyStartupsIdeaInformation: CompanyStartupsIdeaInformation): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/updateideainformation', companyStartupsIdeaInformation, this.requestOptions);
    }
    deleteIdeaInfoById(Id): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/deleteideainfobyid?Id=' + Id, this.requestOptions);
    }
    getAllIdeaInformation(): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/getallideainformation', this.requestOptions);
    }
    getIdeaInformationByCompanyId(UserCompanyId): Observable<any> {
        
        return this.http.get<any>(
            this.baseApiUrl + '/api/startupscompany/getideainformationbycompanyid?UserCompanyId='+UserCompanyId,this.requestOptions);
    }

    // getAllKeyInformation(): Observable<any> {

    //     return this.http.post<any>(
    //         this.baseApiUrl + '/api/startupscompany/getallkeyinformation', this.requestOptions);
    // }

    getKeyInformationById(Id): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/getkeyinformationbyid?Id=' + Id, this.requestOptions);
    }
    postKeyInformation(companyStartupsKeyInformation: CompanyStartupsKeyInformation): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/postkeyinformation' + companyStartupsKeyInformation, this.requestOptions);
    }
    updateKeyInformation(companyStartupsKeyInformation: CompanyStartupsKeyInformation): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/updatekeyinformation', companyStartupsKeyInformation, this.requestOptions);
    }
    deleteInformation(Id): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/deletekeyinformationbyid?id=' + Id, this.requestOptions);
    }

    getAllTeamsInformation(): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/getallteamsinformation', this.requestOptions);
    }
    getTeamsInformationbyId(Id): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/getteamsinformationbyid?id=' + Id, this.requestOptions);
    }
    postTeamsInformation(companyStartupsTeams: CompanyStartupsTeams): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/postteamsinformation', companyStartupsTeams, this.requestOptions);
    }
    UpdateTeamsInformation(companyStartupsTeams: CompanyStartupsTeams): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/updateteamsinformation', companyStartupsTeams, this.requestOptions);
    }
    DeleteTeamsInformationById(Id): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/startupscompany/deleteteamsinformationbyid?id=' + Id, this.requestOptions);
    }



    // getAllStartUpsDocumentInfo(): Observable<any> {

    //     return this.http.post<any>(
    //         this.baseApiUrl + '/api/startupscompany/getallstarupsdocumentinfo', this.requestOptions);
    // }
    // getStartUpsDocumentInoById(Id): Observable<any> {

    //     return this.http.post<any>(
    //         this.baseApiUrl + '/api/startupscompany/getsatrtupsdocumentinfobyid?id=' + Id, this.requestOptions);
    // }
    // postStartUpsDocumentInfo(companyStartupsDocumentInfo: CompanyStartupsDocumentInfo): Observable<any> {

    //     return this.http.post<any>(
    //         this.baseApiUrl + '/api/startupscompany/poststartupsdocumentinfo', companyStartupsDocumentInfo, this.requestOptions);
    // }
    // updateStartUpsDocumentInfo(companyStartupsDocumentInfo: CompanyStartupsDocumentInfo): Observable<any> {

    //     return this.http.post<any>(
    //         this.baseApiUrl + '/api/startupscompany/updatestartupsdocumentinfo', companyStartupsDocumentInfo, this.requestOptions);
    // }
    // deleteStartUpsDocumentInfoById(Id): Observable<any> {

    //     return this.http.post<any>(
    //         this.baseApiUrl + '/api/startupscompany/deletestartupsdocumentinfobyid?id=' + Id, this.requestOptions);
    // }

}