import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-info-address',
  templateUrl: './account-info-address.component.html',
  styleUrls: ['./account-info-address.component.scss']
})
export class AccountInfoAddressComponent implements OnInit {

  addressDetails = [
    'Flat 5/A McDonald Hut Street, London, N22',
    'Flat 2/B English Street Road, London, N70',
    'Flat 8/C British Hut Street, London, N87',
  ];

  linkAddressDetails = [1, 2, 3];


  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

}
