import { Component } from '@angular/core';

@Component({
  selector: 'app-service-top-section',
  templateUrl: './service-top-section.component.html',
  styleUrls: ['./service-top-section.component.scss']
})
export class ServiceTopSectionComponent {

}
