import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { GoogleMapsModule } from '@angular/google-maps';  
 
import { PipesModule } from '../theme/pipes/pipes.module';
import { DirectivesModule } from '../theme/directives/directives.module'; 

import { HeaderImageComponent } from './header-image/header-image.component';
import { HeaderCarouselComponent } from './header-carousel/header-carousel.component';
import { PropertyItemComponent } from './property-item/property-item.component';
import { LoadMoreComponent } from './load-more/load-more.component';
import { PropertiesToolbarComponent } from './properties-toolbar/properties-toolbar.component';
import { PropertiesSearchComponent } from './properties-search/properties-search.component';
import { CompareOverviewComponent } from './compare-overview/compare-overview.component';
import { RatingComponent } from './rating/rating.component';
import { PropertiesSearchResultsFiltersComponent } from './properties-search-results-filters/properties-search-results-filters.component';
import { PropertiesCarouselComponent } from './properties-carousel/properties-carousel.component';
import { ClientsComponent } from './clients/clients.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { CommentsComponent } from './comments/comments.component';
import { TestimonialsComponent } from './testimonials/testimonials.component'; 
import { OurAgentsComponent } from './our-agents/our-agents.component';
import { MissionComponent } from './mission/mission.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { LogoComponent } from './logo/logo.component';
import { HeaderMapComponent } from './header-map/header-map.component';
import { HeaderVideoComponent } from './header-video/header-video.component'; 
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component'; 
import { DialogHeaderControlsComponent } from './dialog-header-controls/dialog-header-controls.component';
import { EmailDialogComponent } from './email-dialog/email-dialog.component';
import { BlogCardComponent } from './blog-card/blog-card.component';
import { NewsCardComponent } from './news-card/news-card.component';
import { JobCardComponent } from './job-card/job-card.component';
import { HerosComponent } from './heros/heros.component';
import { CaseTypeCardComponent } from './case-type-card/case-type-card.component';
import { MainCaseTypeCardComponent } from './main-case-type-card/main-case-type-card.component';
import { EndlessCardComponent } from './endless-card/endless-card.component';
import { HerosV2Component } from './heros-v2/heros-v2.component';
import { BestMortgageCardComponent } from './best-mortgage-card/best-mortgage-card.component';
import { MortgageProductCardComponent } from './mortgage-product-card/mortgage-product-card.component';
import { FimgertipsSectionComponent } from './fimgertips-section/fimgertips-section.component';
import { ExperienceCreditSectionComponent } from './experience-credit-section/experience-credit-section.component';
import { BestMortgageInfoCardComponent } from './best-mortgage-info-card/best-mortgage-info-card.component';
import { SignUpQrComponent } from './sign-up-qr/sign-up-qr.component';
//import { BestMortgagePageItemComponent } from './best-mortgage-page-item/best-mortgage-page-item.component';
import { InsuranceQrComponent } from './insurance-qr/insurance-qr.component';
import { CreditReportQrComponent } from './credit-report-qr/credit-report-qr.component';
import { GetFinanceAppSectionComponent } from './get-finance-app-section/get-finance-app-section.component';
import { CalculateSectionComponent } from './calculate-section/calculate-section.component';
import { FromTestComponent } from './from-test/from-test.component';
import { ReportNavberComponent } from './report-navber/report-navber.component';
import { ServiceTopSectionComponent } from './service-top-section/service-top-section.component';
import { IntroducerTypeCardComponent } from './introducer-type-card/introducer-type-card.component';
import { IntroducerExpansionPalelCardComponent } from './introducer-expansion-palel-card/introducer-expansion-palel-card.component';
import { SpecialAboutFMSectionComponent } from './special-about-fm-section/special-about-fm-section.component';
import { CookiesBoxComponent } from './cookies-box/cookies-box.component';
import { SpecialAboutFMSectionMarketingComponent } from './special-about-fm-section-marketing/special-about-fm-section-marketing.component';
import { MyCreditScoreComponent } from './my-credit-score/my-credit-score.component';
import { MyCreditOverviewComponent } from './my-credit-overview/my-credit-overview.component';
import { MyScoreHistoryComponent } from './my-score-history/my-score-history.component';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { AccountInfoFinacialComponent } from './account-info-finacial/account-info-finacial.component';
import { AccountInfoCreditSearchComponent } from './account-info-credit-search/account-info-credit-search.component';
import { AccountInfoAddressComponent } from './account-info-address/account-info-address.component';
import { AccountInfoConnectionsComponent } from './account-info-connections/account-info-connections.component';
import { AccountInfoElectoralComponent } from './account-info-electoral/account-info-electoral.component';
import { AccountInfoPublicComponent } from './account-info-public/account-info-public.component';
import { AccountInfoNoticesComponent } from './account-info-notices/account-info-notices.component';
import { CompareLoansHeaderComponent } from './compare-loans-header/compare-loans-header.component';
import { CompareLoansTypesComponent } from './compare-loans-types/compare-loans-types.component';
import { CompareLoansTrailerComponent } from './compare-loans-trailer/compare-loans-trailer.component';
import { CompareLoansHelpInfoComponent } from './compare-loans-help-info/compare-loans-help-info.component';
import { CreditCardsHeaderComponent } from './credit-cards-header/credit-cards-header.component';
import { CreditCardsTripleLockComponent } from './credit-cards-triple-lock/credit-cards-triple-lock.component';
import { CreditCardsCardViewComponent } from './credit-cards-card-view/credit-cards-card-view.component';
import { CreditCardsBestCreditComponent } from './credit-cards-best-credit/credit-cards-best-credit.component';
import { CreditCardsTrailerComponent } from './credit-cards-trailer/credit-cards-trailer.component';
import { PortfolioColumnChartComponent } from './portfolio-chart/portfolio-chart.component';
import { InvestmentAccountTrailerComponent } from './investment-account-trailer/investment-account-trailer.component';
import { RegisterAddUBOComponent } from './register-add-ubo/register-add-ubo.component';
import { RoomItemComponent } from './room-item/room-item.component';
import { DealRoomTabForLoginComponent } from './deal-room-tab-for-login/deal-room-tab-for-login.component';
import { DealRoomConceptTabComponent } from './deal-room-concept-tab/deal-room-concept-tab.component';
import { DealRoomKeyInformationTabComponent } from './deal-room-key-information-tab/deal-room-key-information-tab.component';
import { DealRoomKeyTeamTabComponent } from './deal-room-key-team-tab/deal-room-key-team-tab.component';
import { DealRoomDiscussionsTabComponent } from './deal-room-discussions-tab/deal-room-discussions-tab.component';
import { DealRoomDocumentsTabComponent } from './deal-room-documents-tab/deal-room-documents-tab.component';
import { DealRoomAlsoLikeComponent } from './deal-room-also-like/deal-room-also-like.component';
import { ProjectDetailsConeceptComponent } from './project-details-conecept/project-details-conecept.component';
import { ProjectDetailsKeyInfoComponent } from './project-details-key-info/project-details-key-info.component';
import { ProjectDetailsTeamComponent } from './project-details-team/project-details-team.component';
import { ProjectDetailsDocumentsComponent } from './project-details-documents/project-details-documents.component';
import { TeamCardComponent } from './team-card/team-card.component';
import {FormsModule} from '@angular/forms';
import { ProjectAddFormComponent } from './project-add-form/project-add-form.component';
import { ProjectConceptTabComponent } from './project-concept-tab/project-concept-tab.component';
import { ProjectKeyInformationTabComponent } from './project-key-information-tab/project-key-information-tab.component';
import { ProjectTeamTabComponent } from './project-team-tab/project-team-tab.component';
import { ProjectDocumentsTabComponent } from './project-documents-tab/project-documents-tab.component';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { AlertNotificationComponent } from './alert-notification/alert-notification.component';
import { RoomItemForDealRoompageComponent } from './room-item-for-deal-roompage/room-item-for-deal-roompage.component';
import { ProjectDocumentPopupComponent } from './project-document-popup/project-document-popup.component';
// import { AlertModule } from '@coreui/angular';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SwiperModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    NgScrollbarModule,
    GoogleMapsModule,
    PipesModule,
    DirectivesModule,
    FormsModule,
    // AlertModule,

  ],
  exports: [
    RouterModule,
    ReactiveFormsModule,
    SwiperModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    NgScrollbarModule,
    GoogleMapsModule,
    PipesModule,
    DirectivesModule, 
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    PropertyItemComponent,
    LoadMoreComponent,
    PropertiesToolbarComponent,
    PropertiesSearchComponent,
    CompareOverviewComponent,
    RatingComponent,
    PropertiesSearchResultsFiltersComponent,
    PropertiesCarouselComponent,
    ClientsComponent,
    GetInTouchComponent,
    CommentsComponent,
    TestimonialsComponent,   
    OurAgentsComponent, 
    MissionComponent, 
    OurServicesComponent,
    HeaderMapComponent,
    HeaderVideoComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    DialogHeaderControlsComponent,
    BlogCardComponent,
    NewsCardComponent,
    JobCardComponent,
    HerosComponent,
    CaseTypeCardComponent,
    MainCaseTypeCardComponent,
    EndlessCardComponent,
    HerosV2Component,
    BestMortgageCardComponent,
    MortgageProductCardComponent,
    FimgertipsSectionComponent,
    ExperienceCreditSectionComponent,
    BestMortgageInfoCardComponent,
    SignUpQrComponent,
    //BestMortgagePageItemComponent,
    InsuranceQrComponent,
    CreditReportQrComponent,
    GetFinanceAppSectionComponent,
    CalculateSectionComponent,
    FromTestComponent,
    ReportNavberComponent,
    ServiceTopSectionComponent,
    IntroducerTypeCardComponent,
    IntroducerExpansionPalelCardComponent,
    SpecialAboutFMSectionComponent,
    CookiesBoxComponent,
    SpecialAboutFMSectionMarketingComponent,

    MyCreditScoreComponent,
    MyCreditOverviewComponent,
    MyScoreHistoryComponent,
    CanvasJSAngularChartsModule,
    AccountInfoFinacialComponent,
    AccountInfoCreditSearchComponent,
    AccountInfoAddressComponent,
    AccountInfoConnectionsComponent,
    AccountInfoElectoralComponent,
    AccountInfoPublicComponent,
    AccountInfoNoticesComponent,
    CompareLoansHeaderComponent,
    CompareLoansTypesComponent,
    CompareLoansTrailerComponent,
    CompareLoansHelpInfoComponent,
    CreditCardsHeaderComponent,
    CreditCardsTripleLockComponent,
    CreditCardsCardViewComponent,
    CreditCardsBestCreditComponent,
    CreditCardsTrailerComponent,
    PortfolioColumnChartComponent,
    InvestmentAccountTrailerComponent,
    RegisterAddUBOComponent,
    RoomItemComponent,
    DealRoomTabForLoginComponent,
    DealRoomConceptTabComponent,
    DealRoomKeyInformationTabComponent,
    DealRoomKeyTeamTabComponent,
    DealRoomDiscussionsTabComponent,
    DealRoomDocumentsTabComponent,
    DealRoomAlsoLikeComponent,
    ProjectDetailsConeceptComponent,
    ProjectDetailsKeyInfoComponent,
    ProjectDetailsTeamComponent,
    ProjectDetailsDocumentsComponent,
    TeamCardComponent,
    ProjectAddFormComponent,
    ProjectConceptTabComponent,
    ProjectKeyInformationTabComponent,
    ProjectTeamTabComponent,
    ProjectDocumentsTabComponent,
    ProfileMenuComponent,
    AlertNotificationComponent,
    RoomItemForDealRoompageComponent,
    ProjectDocumentPopupComponent,
  ],
  declarations: [ 
    TeamCardComponent,
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    PropertyItemComponent,
    LoadMoreComponent,
    PropertiesToolbarComponent,
    PropertiesSearchComponent,
    CompareOverviewComponent,
    RatingComponent,
    PropertiesSearchResultsFiltersComponent,
    PropertiesCarouselComponent,
    ClientsComponent,
    GetInTouchComponent,
    CommentsComponent,
    TestimonialsComponent,   
    OurAgentsComponent, 
    MissionComponent, 
    OurServicesComponent, 
    HeaderMapComponent, 
    HeaderVideoComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    DialogHeaderControlsComponent,
    EmailDialogComponent,
    BlogCardComponent,
    NewsCardComponent,
    JobCardComponent,
    HerosComponent,
    CaseTypeCardComponent,
    MainCaseTypeCardComponent,
    EndlessCardComponent,
    HerosV2Component,
    BestMortgageCardComponent,
    MortgageProductCardComponent,
    FimgertipsSectionComponent,
    ExperienceCreditSectionComponent,
    BestMortgageInfoCardComponent,
    SignUpQrComponent,
    //BestMortgagePageItemComponent,
    InsuranceQrComponent,
    CreditReportQrComponent,
    GetFinanceAppSectionComponent,
    CalculateSectionComponent,
    FromTestComponent,
    ReportNavberComponent,
    ServiceTopSectionComponent,
    IntroducerTypeCardComponent,
    IntroducerExpansionPalelCardComponent,
    SpecialAboutFMSectionComponent,
    CookiesBoxComponent,
    SpecialAboutFMSectionMarketingComponent,
    MyCreditScoreComponent,
    MyCreditOverviewComponent,
    MyScoreHistoryComponent,
    AccountInfoFinacialComponent,
    AccountInfoCreditSearchComponent,
    AccountInfoAddressComponent,
    AccountInfoConnectionsComponent,
    AccountInfoElectoralComponent,
    AccountInfoPublicComponent,
    AccountInfoNoticesComponent,
    CompareLoansHeaderComponent,
    CompareLoansTypesComponent,
    CompareLoansTrailerComponent,
    CompareLoansHelpInfoComponent,
    CreditCardsHeaderComponent,
    CreditCardsTripleLockComponent,
    CreditCardsCardViewComponent,
    CreditCardsBestCreditComponent,
    CreditCardsTrailerComponent,
    PortfolioColumnChartComponent,
    InvestmentAccountTrailerComponent,
    RegisterAddUBOComponent,
    RoomItemComponent,
    DealRoomTabForLoginComponent,
    DealRoomConceptTabComponent,
    DealRoomKeyInformationTabComponent,
    DealRoomKeyTeamTabComponent,
    DealRoomDiscussionsTabComponent,
    DealRoomDocumentsTabComponent,
    DealRoomAlsoLikeComponent,
    ProjectDetailsConeceptComponent,
    ProjectDetailsKeyInfoComponent,
    ProjectDetailsTeamComponent,
    ProjectDetailsDocumentsComponent,
    ProjectAddFormComponent,
    ProjectConceptTabComponent,
    ProjectKeyInformationTabComponent,
    ProjectTeamTabComponent,
    ProjectDocumentsTabComponent,
    ProfileMenuComponent,
    AlertNotificationComponent,
    RoomItemForDealRoompageComponent,
    ProjectDocumentPopupComponent,
  ]
})
export class SharedModule {}
