import { Component } from '@angular/core';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss', './email-dialog.component.css']
})
export class EmailDialogComponent {

}
