import { Component, Input, OnInit } from '@angular/core';
import { MainCaseType } from 'src/app/models/MainCase.model';

@Component({
  selector: 'app-main-case-type-card',
  templateUrl: './main-case-type-card.component.html',
  styleUrls: ['./main-case-type-card.component.scss']
})
export class MainCaseTypeCardComponent implements OnInit {
  @Input() mainCaseType: MainCaseType;
  
  constructor() { }

  ngOnInit() {

  }

}
