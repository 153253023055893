import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-cards-triple-lock',
  templateUrl: './credit-cards-triple-lock.component.html',
  styleUrls: ['./credit-cards-triple-lock.component.scss']
})
export class CreditCardsTripleLockComponent {

}
