
import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl,UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router'; 
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { PostEmailPassoward } from 'src/app/models/PostEmailPassoward';
import { PostLoginByEmail } from 'src/app/models/PostLoginByEmail.model';
import { PostLoginByPhone } from 'src/app/models/PostLoginByPhone.model';
import { SentOtpPhone } from 'src/app/models/SentOtpPhone.model';
import { User } from 'src/app/models/User.model';
import { CompanyStartupsDocumentInfoService } from 'src/app/services/CompanyStartupsDocumentInfo.service';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import { DealRoomService } from 'src/app/services/DealRoom.service';



@Component({
  selector: 'app-deal-room-documents-tab',
  templateUrl: './deal-room-documents-tab.component.html',
  styleUrls: ['./deal-room-documents-tab.component.scss']
})
export class DealRoomDocumentsTabComponent implements OnInit {
  
  public _creditMatrixLoginService: CreditMatrixLoginService;  
  public _companyStartupsDocumentInfoService: CompanyStartupsDocumentInfoService;

  public _dealRoomService: DealRoomService;
  public _PostLoginByEmailData: PostLoginByEmail;
  public _PostLoginByMobileData: PostLoginByPhone;
  public _SentOtpPhoneModle: SentOtpPhone;
  public _PostEmailPassoward: PostEmailPassoward;


  public hide = true;
  public documentInfoList:any;
  dynamicForm!: FormGroup;
  public isLoginByEmail = true;
  public isOtpRequestSent = false;
  public submitted=false;
  public ErrorMessage:string="";
  public documentUrl:string="";
  public isSuccess=true;
  ticketFormGroup: FormGroup[];
  public ticket: UntypedFormGroup;
  public ticketforPhone: UntypedFormGroup;

  public _currentUser: User;

  
  constructor(
    public router:Router,
    public formBuilder: FormBuilder,
    creditMatrixLoginService: CreditMatrixLoginService,
    dealRoomService: DealRoomService,
    private fb: FormBuilder,
    companyStartupsDocumentInfoService: CompanyStartupsDocumentInfoService
  ) {
    this._dealRoomService=dealRoomService;
    this._creditMatrixLoginService = creditMatrixLoginService;
    this._PostLoginByEmailData = {} as PostLoginByEmail;
    this._PostLoginByMobileData = {} as PostLoginByPhone;
    this._SentOtpPhoneModle = {} as SentOtpPhone;
    this._PostEmailPassoward = {} as PostEmailPassoward;

    this._companyStartupsDocumentInfoService = companyStartupsDocumentInfoService;
    this._currentUser = AuthProviderHelper.getCurrentUser();
    
  }
  ngOnInit() {
    this.GetAllStartUpsDocumentInfo();
  } 
  public async GetAllStartUpsDocumentInfo() {

    try {
      this._companyStartupsDocumentInfoService.GetCompanyStartupsDocumentInfoByUserIdAndUserCompanyId(this._currentUser.Id, this._currentUser.UserCompanyId).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null) {
            this.documentInfoList=responseData.ResponseData.companyStartupsDocumentInfoList;
            //this.documentUrl=this.getFilename(documentInfoList.);
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }
  getFilename(url: string): string {
    const segments = url.split('/');
    return segments.pop() || '';
  }

}


