import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DealRoomModel } from 'src/app/models/DealRoom.model';

@Component({
  selector: 'app-room-item-for-deal-roompage',
  templateUrl: './room-item-for-deal-roompage.component.html',
  styleUrls: ['./room-item-for-deal-roompage.component.scss']
})
export class RoomItemForDealRoompageComponent {

  @Input() profile:DealRoomModel;

  //@Input() _companyStartupsIdeaInformation:CompanyStartupsIdeaInformation;

  constructor(public router: Router,) {}

  ngOnInit() {}

  


  public onclikStartCompanyForDetails(CompanyStartupsIdeaInformationId)
  {    
   // var FundSubscriberIdGuId :any='1AF0FF78-279B-48A0-BC98-16A3E6C5653B';
    this.router.navigate(['/deal-room-details'], { queryParams: { key: CompanyStartupsIdeaInformationId } });

  }

}
