import { Component } from '@angular/core';

@Component({
  selector: 'app-report-navber',
  templateUrl: './report-navber.component.html',
  styleUrls: ['./report-navber.component.scss']
})
export class ReportNavberComponent {

}
