import { Component } from '@angular/core';

@Component({
  selector: 'app-compare-loans-trailer',
  templateUrl: './compare-loans-trailer.component.html',
  styleUrls: ['./compare-loans-trailer.component.scss']
})
export class CompareLoansTrailerComponent {

  compareLoanTrailerInfo = [
    {
      id: 1,
      imgPath: '../../../assets/compare-loans-assets/trailer-search-icon.svg',
      headingTitle: 'Credit Matrix compares  what types of loans?',
      description:
        'The lenders we compare provide loans ranging from £1000 to £100,000, with APRs ranging from 0% to 99.9%. Repayment terms range from one to twenty years.',
    },
    {
      id: 2,
      imgPath: '../../../assets/compare-loans-assets/trailer-loan-cost-icon.svg',
      headingTitle: 'How much will a loan cost me?',
      description:
        'For instance, suppose you borrow £10,000 for 48 months at a nominal fixed annual rate of 2.8 percent. Repayments per month: £220.30 Total amount repaid: £10,574.40',
    },
  ];

}
