import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MobilOTP } from 'src/app/models/MobilOTP.model';
import { FMMobileOTPService } from 'src/app/services/FMMobileOTP.service';

@Component({
  selector: 'app-sign-up-qr',
  templateUrl: './sign-up-qr.component.html',
  styleUrls: ['./sign-up-qr.component.scss']
})

export class SignUpQrComponent implements OnInit {
  dynamicForm!: FormGroup;
  submitted = false;
  isSucessAniimationShow= false;

  public _fmMobileOTPService: FMMobileOTPService;
  public _signinQRModel: MobilOTP;

  constructor(
    public formBuilder: FormBuilder,
    fmMobileOTPService: FMMobileOTPService,
  ) {
    this._fmMobileOTPService = fmMobileOTPService;
    this._signinQRModel = {} as MobilOTP;
  }

  ngOnInit() {
    this.dynamicForm = this.formBuilder.group({
      MobileOTP1: new FormArray([])
    });

    this.t.push(this.formBuilder.group({
      CountryCode: ['44', Validators.required],
      MobileNumber: ['', Validators.required],
    }));
  }

  // convenience getters for easy access to form fields
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.MobileOTP1 as FormArray; }
  get ticketFormGroups() { return this.t.controls as FormGroup[]; }

  public onMobileQRSubmit(): void {

    // stop here if form is invalid
    if (this.dynamicForm.invalid) {
      return;
    }
    this.submitted = true;

    this._signinQRModel.MobileNumber = this.dynamicForm.value.MobileOTP1[0].MobileNumber;
    this._signinQRModel.CountryCode = this.dynamicForm.value.MobileOTP1[0].CountryCode;
     this._signinQRModel.Status = 101;
    console.log(this._signinQRModel);
    this.postMobileQR();
    // this.dynamicForm.value.MobileOTP1[0].MobileNumber = '';
    // this.dynamicForm.value.MobileOTP1[0].CountryCode = '';
  }

  public async postMobileQR() {
    try {
      this._fmMobileOTPService.postMobileQR(this._signinQRModel).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null) {
            this.sendSMSNotificationByUserOTPId(responseData.ResponseData.UserOTP.Id);
            
            this.isSucessAniimationShow = true;
          }

        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }


  public async sendSMSNotificationByUserOTPId(uerOTPId) {
    try {
      this._fmMobileOTPService.sendSMSNotification(uerOTPId).subscribe({
        next: (responseData) => {
          console.log("api calling");
          console.log(responseData);
          if(responseData.Success)
          {
            // Call success pop up message 
            

          }

          
        },
        error: (response) => {
          console.log(response);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }


}
