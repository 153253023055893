import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignUpQrComponent } from 'src/app/shared/sign-up-qr/sign-up-qr.component';
//
import { OnInit } from '@angular/core';
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { UMANotificatonServices } from 'src/app/services/UAMNotificaton.service';

import { User } from 'src/app/models/User.model';
import { Company } from 'src/app/models/Company.model';
import { Notification } from 'src/app/models/Notification.model';
import { warn } from 'console';
import { Router } from '@angular/router';
import { UAMUsersServices } from 'src/app/services/UAMUsers.service';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';
import StatusUtility from 'src/app/utility/Status.Utility';

@Component({
  selector: 'app-universal-navbar',
  templateUrl: './universal-navbar.component.html',
  styleUrls: ['./universal-navbar.component.scss']
})
export class UniversalNavbarComponent implements OnInit {
  public _uamUsersServices: UAMUsersServices;
  public _creditMatrixLoginService: CreditMatrixLoginService;
  public _uMANotificatonServices: UMANotificatonServices;
  public _userNotifications: Notification[] = [];
  public _user: User;
  public _company: Company;
  public _logoutinfo: any;
  public _currentUser: any;
  public isMobileMenuShow: boolean = false;
  public isNavFixed: boolean = false;
  public isSignUpListShow: boolean = false;

  @ViewChild('profileDropdown') profileDropdown: ElementRef;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    uMANotificatonServices: UMANotificatonServices,
    uamUsersServices: UAMUsersServices,
    creditMatrixLoginService: CreditMatrixLoginService,
    private renderer: Renderer2,
  ) {
    this._uamUsersServices = uamUsersServices;
    this._creditMatrixLoginService = creditMatrixLoginService;
    this._uMANotificatonServices = uMANotificatonServices;
    this._user = {} as User;
    this._company = {} as Company;


    //For checking if user clicking outside profile button and hide menu
    //   this.renderer.listen('window', 'click',(e:Event)=>{

    //     if(e.target !== this.profileDropdown.nativeElement){
    //         this.hideProfileDropdownMenu()
    //     }
    //    });
  }

  ngOnInit() {
    this._currentUser = AuthProviderHelper.getCurrentUser();

    if (this._currentUser) {
      this.UnreadallnotificationCount(this._currentUser.Id, this._currentUser.UserCompanyId);
    }

    this.GetUserByUserId(this._currentUser.Id);
  }

  public async GetUserByUserId(UserId: number) {
    try {
      this._uamUsersServices.getuserById(UserId).subscribe({
        next: (response) => {

          if (response.Success = true && response.ResponseData) {
            this._user = response.ResponseData.User;
            // const formattedDate = this.convertToHTMLDate(this._user.DateofBirth);
            // this._user.DateofBirth = formattedDate;
            // console.log(this._user);
          }

        },
        error: (response) => {
          console.log(response);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  openProfileDropdownMenu() {
    var dropdown = document.getElementById("myDropdown");
    dropdown.classList.toggle("show");
    dropdown.classList.toggle("hide");
  }

  logout() {
    this.UserLogout();

  }

  

  public async UserLogout() {
    try {
      this._creditMatrixLoginService.logOutForUAM().subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success) {

            AuthProviderHelper.setResetCurrentUser();

            localStorage.clear();
            this.router.navigate(['']);
            window.location.href = '';
            // StatusUtility.reloadPage();
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }


  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }

  public hideProfileDropdownMenu() {

    this.isMobileMenuShow = false

  }

  public mobileMenuShow() {
    if (this.isMobileMenuShow) {
      this.isMobileMenuShow = false
    } else {
      this.isMobileMenuShow = true;
    }
  }

  @HostListener('window:scroll') onWindowScroll() {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

    // if (this.isSignUpListShow) {
    //   this.isSignUpListShow = false;
    // }

    if (this.isSignUpListShow) {
      this.isSignUpListShow = false;
    }
    if (this.isMobileMenuShow) {
      this.isMobileMenuShow = false;
    }
    // this.isMobileMenuShow = false;
  }

  public signUpListToggle() {
    // this.isSignUpListShow = !this.isSignUpListShow;
    console.log('calling')
    if (this.isSignUpListShow == true) {
      this.isSignUpListShow = false;
    } else if (this.isSignUpListShow == false) {
      this.isSignUpListShow = true;
    }
  }
  // Unread  notification count 
  public async UnreadallnotificationCount(userId: Number, UserCompanyId: Number) {
    try {
      this._uMANotificatonServices.getnotificationbyuseridandusercompanyid(userId, UserCompanyId).subscribe({
        next: (responseData) => {


          if (responseData.Success = true) {
            this._userNotifications = responseData.ResponseData.Notifications;

            if (this._userNotifications.length > 0) {
              var _unreadNotificationCount = 0;
              for (var i = 0; i < this._userNotifications.length; ++i) {
                if (this._userNotifications[i].IsRead == false) {
                  ++_unreadNotificationCount;
                }
              }

              const LoddedNotificationsJSON = JSON.stringify(_unreadNotificationCount);
              localStorage.setItem(StorageKeyName.KEY_USER_NOTIFCATION_COUNT, LoddedNotificationsJSON);
            }

          }
        },
        error: (response) => {
          console.log(response);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  public onClickProjectButton() {
    var project_Added = localStorage.getItem(StorageKeyName.PROJECT_ALREADY_ADDED);
    if (project_Added != null && project_Added == "YES") {
      this.router.navigate(['/dashboard/project']);
    }
    else {
      this.router.navigate(['/dashboard/project-dashboard']);

    }
   // this.router.navigate(['/']);
  }


}
