import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortgagemagicProperty } from '../models/MortgagemagicProperty.model';
import { CookiesPost } from '../models/CookiesPost.model copy';
// import { CookisePost } from '../models/CookisePost.model';


@Injectable({
    providedIn: 'root'
})

export class FinanceMagicCookiseService {


    headers: HttpHeaders = new HttpHeaders();


    //baseApiUrl: string = environment.url;
    baseApiUrl: string = environment.UVCSERVERBASEAPI;

    constructor(private http: HttpClient) {

        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Accept', 'application/json');
        //this.headers.append('Access-Control-Allow-Origin', 'http://localhost:59082');        
        this.headers.append('Access-Control-Allow-Origin', 'https://enm.financemagic.co.uk');      
        this.headers.append('Access-Control-Allow-Methods', 'DELETE, PUT, GET, POST');
        this.headers.append('Access-Control-Allow-Credentials', 'true');
    }


    postCookise(resolutionEntityModel: CookiesPost): Observable<any> {

        const requestOptions = { headers: this.headers };
        
        var body  = {

            UpdatedDate : "22-Sep-2023",
            Status:101,
            Title:"Cookise from FM Web",
            // Description:resolutionEntityModel.Description,
            Remarks:"",
            InitiatorId:0,
            ServiceDate:"22-Sep-2023",
            ResolutionType:"CRM",
            ParentId:0,
            AssigneeId:0,
            AssigneeName:"",
            LeadStatus:"",
            // FirstName: resolutionEntityModel.FirstName,
            MiddleName:"",
            LastName:"",
            // PhoneNumber:resolutionEntityModel.MobileNumber,
            CountryCode:"44",
            // EmailAddress:resolutionEntityModel.EmailAddress,
            DateofBirth:"",
            ReferenceId:0,
            Stage:"Added",
            Probability:0,
            TitleOthers:"",
            Address:"",
            EstimatedEarning:0,
            IsLockAutoCall:0,
            SupportAdminId:0,
            UserNoteEntityId:0,
            UserNoteEntityName:"",
            NegotiatorId:0,
            CommunityId:3,
            isTermsConditions:true,
            UserCompanyId:1,
            CookiseNumber:"",
            // Messages: resolutionEntityModel.Massage,
            // QueryType:resolutionEntityModel.QueryType,
            CompanyName:"",
            // CapchaToken : resolutionEntityModel.CapchaToken
        };
       

        return this.http.post<any>('https://enm.financemagic.co.uk/api/cookiesetting/post', resolutionEntityModel, requestOptions);

    }



    getByDeviceId(deviceId: number): Observable<any> {

        const requestOptions = { headers: this.headers };
    
        return this.http.get<any>('https://enm.financemagic.co.uk/api/cookiesetting/getByDeviceId?deviceId='+deviceId, requestOptions);

    }


    
}
