import { Component } from '@angular/core';

@Component({
  selector: 'app-account-info-electoral',
  templateUrl: './account-info-electoral.component.html',
  styleUrls: ['./account-info-electoral.component.scss']
})
export class AccountInfoElectoralComponent {

}
