import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { DeshboardWrapperComponent } from './pages/dashboard-pages/deshboard-wrapper.component';

export const routes: Routes = [
    {
        path: '', 
        component: PagesComponent, 
        children: [
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'properties', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            { path: 'agents', loadChildren: () => import('./pages/agents/agents.module').then(m => m.AgentsModule) },
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule) },
            { path: 'pricing', loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule) },
            { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
            { path: 'Invest-login', loadChildren: () => import('./pages/login-Invest/Invest-login.module').then(m => m.InvestLoginModule) },
            { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) }, 
            { path: 'submit-property', loadChildren: () => import('./pages/submit-property/submit-property.module').then(m => m.SubmitPropertyModule) },
            { path: 'email-contact', loadChildren: () => import('./pages/email-contact/email-contact.module').then(m => m.EmailContactModule) },

            { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SigninModule) },

            { path: 'accessibility', loadChildren: () => import('./pages/accessibility/accessibility.module').then(m => m.AccessibilityComponentModule) },
            { path: 'blog', loadChildren: () => import('./pages/blogs/blogs.module').then(m => m.BlogsComponentModule) },
            { path: 'blog', loadChildren: () => import('./pages/blog-single/blog-single.module').then(m => m.BlogSingleComponentModule) },
            { path: 'news', loadChildren: () => import('./pages/news/news.module').then(m => m.NewsComponentModule) },
            { path: 'news-single', loadChildren: () => import('./pages/news-single/news-single.module').then(m => m.NewsSingleComponentModule) },
            { path: 'career', loadChildren: () => import('./pages/career/career.module').then(m => m.CareerComponentModule) },
            { path: 'carear-single', loadChildren: () => import('./pages/carear-single/carear-single.module').then(m => m.CarearSingleComponentModule) },

            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
            { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },

            { path: 'pdf', loadChildren: () => import('./pages/pdf/pdf.module').then(m => m.PdfModule) },
            { path: 'otp-verification/:otpCode', loadChildren: () => import('./pages/otp-verify-page/otp-verify-page.module').then(m => m.OTPVerifyPageComponentModule) },

            { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
            { path: 'cookie-policy', loadChildren: () => import('./pages/cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule) },
            
            { path: 'terms-conditions', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
            { path: 'security', loadChildren: () => import('./pages/security/security.module').then(m => m.SecurityComponentModule) },
            { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyComponentModule) },
            { path: 'preference-center', loadChildren: () => import('./pages/preference-center/preference-center.module').then(m => m.PreferenceCenterComponentModule) },
            { path: 'about-our-service', loadChildren: () => import('./pages/about-our-service/about-our-service.module').then(m => m.aboutOurServiceComponentModule) },
            //{ path: 'best-rate-search', loadChildren: () => import('./pages/best-rate-search/best-rate-search.module').then(m => m.BestRateSearchComponentModule) },
            //{ path: 'best-mortgage', loadChildren: () => import('./pages/best-mortgage/best-mortgage.module').then(m => m.BestMortgageComponentModule) },
            { path: 'introducer-registration', loadChildren: () => import('./pages/Introducer-registration/Introducer-registration.module').then(m => m.IntroducerRegistrationComponentModule) },
            // { path: 'services-mortgage', loadChildren: () => import('./pages/services-mortgage/services-mortgage.module').then(m => m.ServicesMortgageComponentModule) },
            // { path: 'services-Insurance', loadChildren: () => import('./pages/services-Insurance/services-Insurance.module').then(m => m.ServicesInsuranceComponentModule) },
            // { path: 'services-commercial', loadChildren: () => import('./pages/services-commercial/services-commercial.module').then(m => m.ServicesCommercialComponentModule) },
            // { path: 'services-buy-let', loadChildren: () => import('./pages/services-buy-to-let/services-buy-to-let.module').then(m => m.ServicesBuyToLetComponentModule) },
            // { path: 'services-bridging', loadChildren: () => import('./pages/services-bridging/services-bridging.module').then(m => m.ServicesBridgingComponentModule) },
            // { path: 'services-equity-release', loadChildren: () => import('./pages/services-equity-release/services-equity-release.module').then(m => m.ServicesEquityReleaseComponentModule) },
            // { path: 'services-Business', loadChildren: () => import('./pages/services-business/services-business.module').then(m => m.ServicesBusinessComponentModule) },
            // { path: 'services-development', loadChildren: () => import('./pages/services-development/services-development.module').then(m => m.ServicesDevelopmentComponentModule) },
            
            // new include 
            // { path: 'progress-fund', loadChildren: () => import('./pages/progress-fund/progress-fund.module').then(m => m.ProgressFundComponentModule) },
            // { path: 'fund', loadChildren: () => import('./pages/funds/funds.module').then(m => m.FundsComponentModule) },

            { path: 'funds', loadChildren: () => import('./pages/funds/funds.module').then(m => m.FundsComponentModule) },
            { path: 'progress-fund', loadChildren: () => import('./pages/progress-fund/progress-fund.module').then(m => m.ProgressFundComponentModule) },

            { path: 'humble-fund', loadChildren: () => import('./pages/humble-fund/humble-fund.module').then(m => m.HumbleFundComponentModule) },
            { path: 'smart-fund', loadChildren: () => import('./pages/smart-fund/smart-fund.module').then(m => m.SmartFundComponentModule) },
            { path: 'bismillah-fund', loadChildren: () => import('./pages/bismillah-fund/bismillah-fund.module').then(m => m.BismillahFundComponentModule) },
            { path: 'team', loadChildren: () => import('./pages/team/team.module').then(m => m.TeamComponentModule) },
            //my included
            { path: 'faq-my-account', loadChildren: () => import('./pages/faq-my-account/faq-my-account.module').then(m => m.FaqMyAccountComponentModule) },
            { path: 'faq-my-account-login', loadChildren: () => import('./pages/faq-my-account-login/faq-my-account-login.module').then(m => m.FaqMyAccountLoginComponentModule) },
            { path: 'report-score', loadChildren: () => import('./pages/report-score/report-score.module').then(m => m.ReportScoreComponentModule) },
            { path: 'report-score-credit-report', loadChildren: () => import('./pages/report-score-credit-report/report-score-credit-report.module').then(m => m.ReportScoreCreditReportComponentModule) },
            // { path: 'report-score-credit-report/:id', loadChildren: () => import('./pages/report-score-credit-report-details/report-score-credit-report-details.module').then(m => m.ReportScoreCreditReportDetailsComponentModule) },
            { path: 'report-score-credit-report-details', loadChildren: () => import('./pages/report-score-credit-report-details/report-score-credit-report-details.module').then(m => m.ReportScoreCreditReportDetailsComponentModule) },
            { path: 'faq-my-account-login-details', loadChildren: () => import('./pages/faq-my-account-login-details/faq-my-account-login-details.module').then(m => m.FaqMyAccountLogintDetailsComponentModule) }, 
            { path: 'faq-about-credit-metrix', loadChildren: () => import('./pages/faq-about-credit-metrix/faq-about-credit-metrix.module').then(m => m.FaqAboutCreditMetrixComponenttModule) },
            { path: 'faq-about-credit-metrix-view-more', loadChildren: () => import('./pages/faq-about-credit-metrix-view-more/faq-about-credit-metrix-view-more.module').then(m => m.FaqAboutCreditMetrixViewMoreComponentModule) },
            { path: 'faq-about-credit-metrix-details', loadChildren: () => import('./pages/faq-about-credit-metrix-details/faq-about-credit-metrix-details.module').then(m => m.FaqMyAccountLogintDetailsComponentModule) },
            { path: 'compare-loans', loadChildren: () => import('./pages/compare-loans/compare-loans.module').then(m => m.CompareLoansComponentModule) },
            { path: 'credit-cards', loadChildren: () => import('./pages/credit-cards/credit-cards.module').then(m => m.CreditCardsComponentModule) },
 
            { path: 'thesis', loadChildren: () => import('./pages/thesis/thesis.module').then(m => m.ThesisComponentModule) },

            // v2
            { path: 'genesis', loadChildren: () => import('./pages/genesis/genesis.module').then(m => m.GenesisComponentModule) },
            { path: 'capital', loadChildren: () => import('./pages/capital/capital.module').then(m => m.CapitalComponentModule) },
            { path: 'collective', loadChildren: () => import('./pages/collective/collective.module').then(m => m.CollectiveComponentModule) },
 
            { path: 'deal-room', loadChildren: () => import('./pages/deal-room/dealroom.module').then(m => m.DealRoomModule) },
            { path: 'deal-room-details', loadChildren: () => import('./pages/deal-room-details/dealRoomDetails.module').then(m => m.DealRoomDetailsModule) },
        ]
    }, 
    

    {
        path: 'dashboard', 
        component: DeshboardWrapperComponent, 
        children: [
            { path: '', loadChildren: () => import('./pages/dashboard-pages/deshboard-not-used/dashboard.module').then(m => m.DashboardComponentModule) },
            { path: 'project-dashboard', loadChildren: () => import('./pages/dashboard-pages/dashboard-new/dashboard-new.module').then(m => m.DashboardNewModule) },

            { path: 'home', loadChildren: () => import('./pages/dashboard-pages/deshboard-home/dashboard.module').then(m => m.DashboardComponentModule) },
            { path: 'project', loadChildren: () => import('./pages/dashboard-pages/dashboard-project/dashboard-project.module').then(m => m.DashboardProjectModule) },
            { path: 'project-details', loadChildren: () => import('./pages/dashboard-pages/project-details/project-details.module').then(m => m.ProjectDetailsModule) },
            { path: 'project-concept', loadChildren: () => import('./pages/dashboard-pages/project-concept/project-concept.module').then(m => m.ProjectConceptModule) },
            { path: 'dashboard-new', loadChildren: () => import('./pages/dashboard-pages/dashboard-new/dashboard-new.module').then(m => m.DashboardNewModule) },
            { path: 'discussions', loadChildren: () => import('./pages/dashboard-pages/discussions/discussions.module').then(m => m.DiscussionsModule) },
            
            { path: 'about', loadChildren: () => import('./pages/dashboard-pages/about/about.module').then(m => m.AboutModule) },
            { path: 'terms-conditions', loadChildren: () => import('./pages/dashboard-pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsComponentModule) },
            { path: 'privacy-policy', loadChildren: () => import('./pages/dashboard-pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyComponentModule) },
            { path: 'business-term', loadChildren: () => import('./pages/dashboard-pages/business-team/business-team.module').then(m => m.BusinessTeamComponentModule) },
            { path: 'badges', loadChildren: () => import('./pages/dashboard-pages/uam-portal-badges/uam-portal-badges.module').then(m => m.UamPortalBadgesComponentModule) },
            { path: 'notification-setting', loadChildren: () => import('./pages/dashboard-pages/notification-setting/notification-setting.module').then(m => m.NotificationSettingComponenttModule) },
            { path: 'settings', loadChildren: () => import('./pages/dashboard-pages/settings/settings.module').then(m => m.SettingsComponentModule) },
            { path: 'notification', loadChildren: () => import('./pages/dashboard-pages/notification/notification.module').then(m => m.NotificationComponentModule) },
            { path: 'track-sells', loadChildren: () => import('./pages/dashboard-pages/track-sells/track-sells.module').then(m => m.TrackSellsComponentModule) },
            { path: 'profile', loadChildren: () => import('./pages/dashboard-pages/profile/profile.module').then(m => m.ProfileComponentModule) },
            { path: 'edit-profile', loadChildren: () => import('./pages/dashboard-pages/edit-profile/edit-profile.module').then(m => m.EditProfileComponentModule) },
            { path: 'project-idea', loadChildren: () => import('./pages/dashboard-pages/project-idea/project-idea.module').then(m => m.ProjectIdeaModule) },
            { path: 'project-key-information', loadChildren: () => import('./pages/dashboard-pages/project-key-information/project-key-information.module').then(m => m.ProjectKeyInformationModule) },
            { path: 'project-team', loadChildren: () => import('./pages/dashboard-pages/project-team/project-team.module').then(m => m.ProjectTeamModule) },

            { path: 'portfolio', loadChildren: () => import('./pages/dashboard-pages/portfolio/portfolio.module').then(m => m.PortfolioComponentModule) },
            { path: 'tax-documents', loadChildren: () => import('./pages/dashboard-pages/tax-documents/tax-documents.module').then(m => m.TaxDocumentsComponentModule) },

            { path: 'investment-account', loadChildren: () => import('./pages/dashboard-pages/investment-account/investment-account.module').then(m => m.InvestmentAccountComponentModule) },
            { path: 'profile', loadChildren: () => import('./pages/dashboard-pages/profile/profile.module').then(m => m.ProfileComponentModule) },
            { path: 'sell-shares', loadChildren: () => import('./pages/dashboard-pages/sell-shares/sell-shares.module').then(m => m.SellSharesComponentModule) },
            { path: 'track-sells', loadChildren: () => import('./pages/dashboard-pages/track-sells/track-sells.module').then(m => m.TrackSellsComponentModule) },
            
           
        ]
    },

    //{ path: 'credit-report-onboarding', loadChildren: () => import('./pages/fm-markating-home/fm-markating-home.module').then(m => m.FmMarkatingHomeComponentModule) },

    // { path: 'credit-education-report', loadChildren: () => import('./pages/report-credit-education/report-credit-education.module').then(m => m.ReportCreditEducationModule) },
    // { path: 'credit-score-recipe', loadChildren: () => import('./pages/report-credit-score-recipe/report-credit-score-recipe.module').then(m => m.CreditScoreRecipeModule) },
    // { path: 'credit-how-to-get-credit', loadChildren: () => import('./pages/report-how-to-get-credit/report-how-to-get-credit.module').then(m => m.ReportHowToGetCreditModule) },
    // { path: 'credit-misconceptions', loadChildren: () => import('./pages/report-credit-misconceptions/report-credit-misconceptions.module').then(m => m.ReportCreditMisconceptionsModule) },
    // { path: 'credit-savings', loadChildren: () => import('./pages/report-credit-savings/report-credit-savings.module').then(m => m.ReportCreditSavingsModule) },
    // { path: 'credit-budgeting-trips', loadChildren: () => import('./pages/report-credit-budgeting-trips/report-credit-budgeting-trips.module').then(m => m.ReportCreditSavingsModule) },
    // { path: 'credit-fixing-mistakes', loadChildren: () => import('./pages/report-credit-fixing-mistakes/report-credit-fixing-mistakes.module').then(m => m.ReportCreditFixingMistakesModule) },
    // { path: 'credit-planning', loadChildren: () => import('./pages/report-credit-planning/report-credit-planning.module').then(m => m.ReportCreditPlanningModule) },
    // { path: 'credit-spotting-identity-theft', loadChildren: () => import('./pages/report-credit-spotting-identity-theft/report-credit-spotting-identity-theft.module').then(m => m.ReportCreditSpottingIdentityTheftModule) },
    // { path: 'credit-identity-theft-protection', loadChildren: () => import('./pages/report-credit-identity-theft-protection/report-credit-identity-theft-protection.module').then(m => m.ReportCreditIdentityTheftProtectionModule) },
    // { path: 'credit-cifas', loadChildren: () => import('./pages/report-credit-cifas/report-credit-cifas.module').then(m => m.ReportCreditCifasModule) },

    { path: 'mob-dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardComponentModule) },
    { path: 'mob-profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileComponentModule) },
    { path: 'mob-credit-report', loadChildren: () => import('./pages/credit-report/credit-report.module').then(m => m.CreditReportComponentModule) },
    
    { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    
    { path: 'lock-screen', component: LockScreenComponent },
    { path: '**', component: NotFoundComponent },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }