import { Component } from '@angular/core';

@Component({
  selector: 'app-compare-loans-types',
  templateUrl: './compare-loans-types.component.html',
  styleUrls: ['./compare-loans-types.component.scss']
})
export class CompareLoansTypesComponent {


  compareLoanTypeInfo = [
    {
      id: 1,
      imgPath: '../../../assets/compare-loans-assets/personal-use-logo.svg',
      headingTitle: 'Loan for personal use',
      description:
        'A personal loan, also known as an unsecured loan, does not require you to put up collateral. If you have a good credit score and a credit history that demonstrates to lenders that you can manage credit responsibly, you will be more likely to be approved for a personal loan.',
    },
    {
      id: 2,
      imgPath: '../../../assets/compare-loans-assets/collateral-logo.svg',
      headingTitle: 'Loans with collateral',
      description:
        'With a secured loan or a homeowner loan, you must put up an asset as collateral to guarantee that you will repay the loan. It is critical that you make your loan payments on time; failing to do so may result in the loss of your home.',
    },
    {
      id: 3,
      imgPath: '../../../assets/compare-loans-assets/guarantee-logo.svg',
      headingTitle: 'Loans with a guarantee',
      description:
        'A guarantor loan may be appropriate for you if you have a low credit score. A guarantor is required for this type of loan, who promises to make your repayments on your behalf if you are unable to do so. Usually, a family member is asked, but your guarantor does not have to be a relative.',
    },
    {
      id: 4,
      imgPath: '../../../assets/compare-loans-assets/debt-consolidation-logo.svg',
      headingTitle: 'Loans for debt consolidation',
      description:
        'If you have multiple debts from personal loans, credit cards, or store cards, this type of loan may be an option for you. A debt consolidation loan allows you to combine your debt balances into a single loan. You may find that having one monthly payment rather than several makes it easier to manage paying off your debt..',
    },
  ];

}
