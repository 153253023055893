import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpecialAbout } from 'src/app/models/SpecialAbout.model';
import { SignUpQrComponent } from '../sign-up-qr/sign-up-qr.component';

@Component({
  selector: 'app-special-about-fm-section-marketing',
  templateUrl: './special-about-fm-section-marketing.component.html',
  styleUrls: ['./special-about-fm-section-marketing.component.scss']
})
export class SpecialAboutFMSectionMarketingComponent {
  @Input() endless: SpecialAbout;

  ngOnInit() {

  }
  
  constructor(public dialog: MatDialog) {}

  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }

}
