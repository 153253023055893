import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CardInterbace } from 'src/app/models/ContactPost.model';
import { SignUpQrComponent } from '../sign-up-qr/sign-up-qr.component';

@Component({
  selector: 'app-best-mortgage-info-card',
  templateUrl: './best-mortgage-info-card.component.html',
  styleUrls: ['./best-mortgage-info-card.component.scss']
})

export class BestMortgageInfoCardComponent implements OnInit {
  @Input() bestMortgage: CardInterbace;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    console.log(this.bestMortgage.title)
  }

  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }
}
