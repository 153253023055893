import { Component, Input, OnInit } from '@angular/core';
import { CaseType } from 'src/app/models/CaseType.model';

@Component({
  selector: 'app-case-type-card',
  templateUrl: './case-type-card.component.html',
  styleUrls: ['./case-type-card.component.scss']
})
export class CaseTypeCardComponent implements OnInit {
  @Input() caseType:CaseType;

  constructor() { }

  ngOnInit() {

  }
}
