import { Component, Input} from '@angular/core';
import { JobCard } from 'src/app/models/JobCard.model';

@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss']
})
export class JobCardComponent {
  @Input() id: Object;
  @Input() vacancy: JobCard;
  public showMoreContent: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  public togContent() {
    this.showMoreContent = !this.showMoreContent;
  }
}
