import { Component, Input } from '@angular/core';
import { DealRoomModel } from 'src/app/models/DealRoom.model';

@Component({
  selector: 'app-deal-room-also-like',
  templateUrl: './deal-room-also-like.component.html',
  styleUrls: ['./deal-room-also-like.component.scss']
})
export class DealRoomAlsoLikeComponent {

  @Input() profile: DealRoomModel

}
