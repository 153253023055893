import { Component } from '@angular/core';

@Component({
  selector: 'app-investment-account-trailer',
  templateUrl: './investment-account-trailer.component.html',
  styleUrls: ['./investment-account-trailer.component.scss']
})
export class InvestmentAccountTrailerComponent {

}
