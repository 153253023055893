import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MobilOTP } from 'src/app/models/MobilOTP.model';
import { FMMobileOTPService } from 'src/app/services/FMMobileOTP.service';

@Component({
  selector: 'app-credit-report-qr',
  templateUrl: './credit-report-qr.component.html',
  styleUrls: ['./credit-report-qr.component.scss']
})
export class CreditReportQrComponent {
  dynamicForm!: FormGroup;
  
  public _fmMobileOTPService: FMMobileOTPService;
  public _signinQRModel: MobilOTP;

  constructor(
    public formBuilder: FormBuilder,
    fmMobileOTPService: FMMobileOTPService,
  ) {
    this._fmMobileOTPService = fmMobileOTPService;
    this._signinQRModel = {} as MobilOTP;
  }

  ngOnInit() {
    this.dynamicForm = this.formBuilder.group({
      MobileOTP1: new FormArray([])
    });

    this.t.push(this.formBuilder.group({
      MobleNumber: [''],
      CountryCode: ['+44'],
    }));
  }

  // convenience getters for easy access to form fields
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.MobileOTP1 as FormArray; }
  get ticketFormGroups() { return this.t.controls as FormGroup[]; }

  public onMobileQRSubmit():void {
    this._signinQRModel.MobileNumber  = this.dynamicForm.value.MobileOTP1[0].MobleNumber;
    this._signinQRModel.CountryCode = this.dynamicForm.value.MobileOTP1[0].CountryCode;
    console.log(this._signinQRModel);
    this.postMobileQR();
    this.dynamicForm.value.MobileOTP1[0].MobleNumber = '';
    this.dynamicForm.value.MobileOTP1[0].CountryCode = '';
  }

  public async postMobileQR() {
    try {
      this._fmMobileOTPService.postMobileQR(this._signinQRModel).subscribe({
        next: (responseData) => {
          console.log(responseData);
          
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }
}
