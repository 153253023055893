import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignUpQrComponent } from '../sign-up-qr/sign-up-qr.component';
import { LenderProduct } from 'src/app/models/LenderProduct';

@Component({
  selector: 'app-mortgage-product-card',
  templateUrl: './mortgage-product-card.component.html',
  styleUrls: ['./mortgage-product-card.component.scss']
})
export class MortgageProductCardComponent implements OnInit  {
  public isShow : boolean = false;

  @Input() lenderProduct: LenderProduct;

  ngOnInit() {

  }

  constructor(public dialog: MatDialog) {}

  public toogleDescription() { 
    this.isShow = !this.isShow;
  }

  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }
}
