import DateUtil from "./Date.Util";
import StorageKeyName from "./StorageKeyName";
import { AuthorizationGuard } from '../services/authorization.guard';
import { User } from '../models/User.model';
import { OnInit } from "@angular/core";

//import { AuthorizationGuard } from '../app/services/authorization.guard';

export default class AuthProviderHelper {

  public static CurrentUser = {} as User;


  public static checkExpiration() {
    //check if past expiration date
    var setupDate = localStorage.getItem(StorageKeyName.KEY_SET_UP_PRODUCT_DATE);
    var todayDateString = DateUtil.getTodayDateString();
    if (setupDate == null) {
      localStorage.setItem(StorageKeyName.KEY_SET_UP_PRODUCT_DATE, todayDateString);
      return true;
    }
    else if (setupDate != todayDateString) {
      localStorage.setItem(StorageKeyName.KEY_SET_UP_PRODUCT_DATE, todayDateString);
      return false;
    }
    else {
      return true;
    }
  }

  public static getCurrentUser(): User {

    if (this.CurrentUser == null || !this.CurrentUser.Id || this.CurrentUser.Id == 0) {
      var loddedInUserJSON = localStorage.getItem(StorageKeyName.KEY_LOGGEDIN_USER);

      if (loddedInUserJSON != null && loddedInUserJSON.length > 10) {
        this.CurrentUser = JSON.parse(loddedInUserJSON);
      }
    }

    return this.CurrentUser;
  }

  public static setResetCurrentUser() {
    
    localStorage.setItem(StorageKeyName.KEY_LOGGEDIN_USER, "");
  }



}