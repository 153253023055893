import { Component } from '@angular/core';

@Component({
  selector: 'app-fimgertips-section',
  templateUrl: './fimgertips-section.component.html',
  styleUrls: ['./fimgertips-section.component.scss']
})
export class FimgertipsSectionComponent {

}
