import { DatePipe } from "@angular/common";

export default class DateUtil {
    public static getDefaultDateTime(): Date {
        const date = new Date(1970, 0, 1, 0, 0, 0); // Month is 0-indexed in JavaScript Date object
        return date;
    }

        public static  getTodayDateString() : any
        {
            var todayDate = new Date();
            const datepipe: DatePipe = new DatePipe('en-US')
           let formattedDateString = datepipe.transform( todayDate, 'dd-MMM-YYYY')
           
            return formattedDateString;
        }



    //     public static DateTime getLastTimeDay(DateTime date)
    //     {
    //         date = new DateTime(date.Year, date.Month, date.Day, 23, 59, 58);
    //         return date;
    //     }

    //     public static DateTime getStartTimeDay(DateTime date)
    //     {
    //         date = new DateTime(date.Year, date.Month, date.Day, 0, 0, 0);
    //         return date;
    //     }

    //     public static DateTime getDefaultValidationDateTime()
    //     {
    //         DateTime date = new DateTime(1900, 1, 1, 0, 0, 0);
    //         return date;
    //     }

    

    //     public static string getDateTimeString(DateTime date)
    //     {
    //         string dateString = date.ToString("dd-MMM-yyyy HH:mm");
    //         return dateString;
    //     }
    //     public static string getDateStringForMessage(DateTime date)
    //     {
    //         string dateString = date.ToString("dd MMMM");
    //         return dateString;
    //     }

    //     public static DateTime getDateFromString(string strDateValue)
    //     {
    //         //return strDateValue.ToDa

    //         DateTime Date = DateTime.UtcNow;
    //         try
    //         {
    //             string format = "dd-MMM-yyyy";
    //             if (strDateValue != null && strDateValue.Contains('/'))
    //             {
    //                 format = "MM/dd/yyyy";
    //             }
    //             //string dateString = "2/1/2018 6:36 PM";
    //             Date = DateTime.ParseExact(strDateValue, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(Date.ToString());
    //         }
    //         catch (Exception ex)
    //         {
    //             try
    //             {
    //                 string format = "dd-MMM-yy";
    //                 Date = DateTime.ParseExact(strDateValue, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                 Console.Write(Date.ToString());
    //             }
    //             catch (Exception ex1) { }
    //         }

    //         return Date;
    //     }

    //     public static DateTime checkValidDate(DateTime dateTime)
    //     {
    //         DateTime date = dateTime.Year == 1 || dateTime.Year == 1970 ? DateUtil.getDefaultDateTime() : dateTime;

    //         return date;


    //     }

    //     public static DateTime getCurrentTimeLastTimeDay(DateTime date)
    //     {
    //         date = new DateTime(date.Year, date.Month, date.Day, 23, 59, 58);
    //         return date;
    //     }

    //     public static DateTime getLastMonthDateTime(DateTime dateTime)
    //     {
    //         DateTime lastDayOfMonth = new DateTime(dateTime.Year, dateTime.Month,
    //                           DateTime.DaysInMonth(dateTime.Year, dateTime.Month));
    //         return lastDayOfMonth;
    //     }

    //     public static string getLastMonthDateTimeString(DateTime dateTime)
    //     {
    //         DateTime lastDayOfMonth = new DateTime(dateTime.Year, dateTime.Month,
    //                                 DateTime.DaysInMonth(dateTime.Year, dateTime.Month));


    //         //var month = dateTime.Month + 1;
    //   //      var lastDayOfMonth = dateTime.DaysInMonth(dateTime.Year, dateTime.Month));
    //         //lastDayOfMonth.AddDays(-1);
    //         string dateString = lastDayOfMonth.ToString("dd-MMM-yyyy");
    //         return dateString;
    //     }

    //     public static string getLastMonthYearDateTimeString(DateTime dateTime)
    //     {
    //         DateTime lastDayOfMonth = new DateTime(dateTime.Year, dateTime.Month,
    //                           DateTime.DaysInMonth(dateTime.Year, dateTime.Month));

    //         string dateString = lastDayOfMonth.ToString("MM-yyyy");
    //         return dateString;
    //     }

    //     public static string getDateStringFromDateString(string dateStringData)
    //     {
    //         var date = new DateTime();
    //         try
    //         {
    //             string format = "dd-MM-yyyy";
    //             if (dateStringData != null && dateStringData.Contains('/'))
    //             {
    //                 format = "MM/dd/yyyy";
    //             }

    //             date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(date.ToString());

    //         }
    //         catch (Exception ex1)
    //         {
    //             try
    //             {
    //                 string format = "dd-MMM-yyyy";
    //                 date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                 Console.Write(date.ToString());
    //             }
    //             catch (Exception ex2)
    //             {

    //                 try
    //                 {
    //                     string format = "yyyy-mm-dd";
    //                     date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                     Console.Write(date.ToString());
    //                 }
    //                 catch (Exception ex3)
    //                 {

    //                 }
    //             }


    //         }

    //         string dateString = date.ToString("dd-MMM-yyyy");
    //         return dateString;

          
    //     }

    //     public static string getDateStringFromDateStringForAccessDataEntry(string dateStringData)
    //     {
    //         var date = new DateTime();
    //         try
    //         {
    //             string format = "dd-MM-yyyy";
    //             if (dateStringData != null && dateStringData.Contains('/'))
    //             {
    //                 format = "dd/MM/yyyy";
    //             }

    //             date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(date.ToString());

    //         }
    //         catch (Exception ex1)
    //         {
    //             try
    //             {
    //                 string format = "dd-MMM-yyyy";
    //                 date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                 Console.Write(date.ToString());

    //             }
    //             catch (Exception ex2)
    //             {


    //             }
    //         }

    //         string dateString = date.ToString("dd-MMM-yyyy");
    //         return dateString;

    //         return "";
    //     }

    //     public static string getUKDateStringFromDateString(string dateStringData)
    //     {
    //         var date = new DateTime();
    //         try
    //         {
    //             string format = "dd-MMM-yyyy";
    //             if (dateStringData != null && dateStringData.Contains('/'))
    //             {
    //                 format = "MM/dd/yyyy";
    //             }

    //             date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(date.ToString());

    //         }
    //         catch (Exception ex1)
    //         {
    //             try
    //             {
    //                 string format = "dd-MMM-yyyy";
    //                 date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                 Console.Write(date.ToString());
    //             }
    //             catch (Exception ex2)
    //             {
    //             }
    //         }

    //         string dateString = date.ToString("MM/dd/yyyy");
    //         return dateString;

    //         //  return "";
    //     }

    //     public static string getYearsFromDate(DateTime date)
    //     {
    //         int days = (DateTime.Today - date).Days;

    //         //assume 365.25 days per year
    //         decimal years = days / 365.25m;

    //         return years.ToString();
    //     }

    //     public static int getYearsFromDateAndCurrentDate(DateTime date)
    //     {
    //         int days = (DateTime.Today - date).Days;

    //         //assume 365.25 days per year
    //         decimal years = days / 365.25m;

    //         return (int)years;
    //     }

    //     public static int getMonthFromDate(DateTime date)
    //     {
    //         int days = (date - DateTime.Today).Days;

    //         //assume 365.25 days per year
    //         int month = days / 12;

    //         return month;
    //     }

    //     public static string getMonthStringFromDate(DateTime date)
    //     {
    //         var month = date.ToString("MMMM");

    //         //assume 365.25 days per year
    //        // int month = days / 12;

    //         return month;
    //     }
    //     //ToString("MMMM");


    //     public static string getUKDateStringFromDate(DateTime dateData)
    //     {
    //         // var date = new DateTime();

    //         string dateString = dateData.ToString("MM/dd/yyyy");
    //         return dateString;
    //     }

    //     public static DateTime getFirstDayOfYear(int year)
    //     {
    //         var date = new DateTime(year, 1, 1, 00, 00, 00);
    //         return date;
    //     }

    //     public static DateTime getDateTimeBySubtractDay(int Day)
    //     {
    //         var date = DateTime.UtcNow;
    //         date = date.AddDays(-Day);
    //         return date;
    //     }


    //     public static string getDateIpipelineFormatStringFromDateString(string dateStringData)
    //     {
    //         var date = new DateTime();
    //         try
    //         {
    //             string format = "dd-MMM-yyyy";
    //             if (dateStringData != null && dateStringData.Contains('/'))
    //             {
    //                 format = "MM/dd/yyyy";
    //             }

    //             date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(date.ToString());

    //         }
    //         catch (Exception ex1)
    //         {
    //             try
    //             {
    //                 string format = "dd-MMM-yyyy";
    //                 date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                 date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                 Console.Write(date.ToString());

    //             }
    //             catch (Exception ex2)
    //             {
    //             }
    //     }

    //         string dateString = date.ToString("yyyy-MM-dd");
    //         return dateString;

    //         // return "";
    //     }

    //     public static string getDefaultIpipelineTime()
    //     {
    //         var date = DateTime.Now;
    //         //try
    //         //{
    //         //    string format = "dd-MMM-yyyy";
    //         //    if (dateStringData != null && dateStringData.Contains('/'))
    //         //    {
    //         //        format = "MM/dd/yyyy";
    //         //    }

    //         //    date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //         //    Console.Write(date.ToString());

    //         //}
    //         //catch (Exception ex1)
    //         //{

    //         //    string format = "dd-MMM-yyyy";
    //         //    date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //         //    Console.Write(date.ToString());
    //         //}

    //         try
    //         {
    //             string dateString = date.ToString("yyyy-MM-ddThh:mm:ss");
    //             return dateString;
    //         }
    //         catch (Exception ex) { }

    //         return "";
    //     }

    //     public static YearsMonths getYearAndMonthFromDateAndTime(DateTime startDate, DateTime endDate)
    //     {
    //         int monthDiff = ((endDate.Year * 12) + endDate.Month) - ((startDate.Year * 12) + startDate.Month) + 1;
    //         int years = (int)Math.Floor((decimal)(monthDiff / 12));
    //         int months = monthDiff % 12;
    //         return new YearsMonths
    //         {
    //             TotalMonths = monthDiff,
    //             Years = years,
    //             Months = months
    //         };
    //     }

    //     //void getYearAndMonthFromDateAndTime(DateTime compareTo)
    //     //{
    //     //    //  DateTime compareTo = DateTime.Parse("8/13/2010 8:33:21 AM");
    //     //    DateTime now = DateTime.UtcNow;
    //     //    var dateSpan = DateTimeSpan.CompareDates(compareTo, now);
    //     //    Console.WriteLine("Years: " + dateSpan.Years);
    //     //    Console.WriteLine("Months: " + dateSpan.Months);
    //     //    Console.WriteLine("Days: " + dateSpan.Days);
    //     //    Console.WriteLine("Hours: " + dateSpan.Hours);
    //     //    Console.WriteLine("Minutes: " + dateSpan.Minutes);
    //     //    Console.WriteLine("Seconds: " + dateSpan.Seconds);
    //     //    Console.WriteLine("Milliseconds: " + dateSpan.Milliseconds);
    //     //}

    //     public static int getSixMonthlyScale(int month)
    //     {
    //         var montlyScale = 6;
    //         if(month <=6 )
    //         {
    //             montlyScale = 6;
    //         }
    //         else if (month > 6 && month <= 12)
    //         {
    //             montlyScale = 12;
    //         }
    //         else if (month > 12 && month <= 24)
    //         {
    //             montlyScale = 24;
    //         }
    //         else if (month > 24 && month <= 36)
    //         {
    //             montlyScale = 36;
    //         }
    //         else if (month > 36 && month <= 48)
    //         {
    //             montlyScale = 48;
    //         }
    //         else if (month > 48 && month <= 60)
    //         {
    //             montlyScale = 60;
    //         }

    //         return montlyScale;
    //     }


    //     public static string getDateStringFromDateStringForPassport(string dateStringData)
    //     {
    //         var date = new DateTime();
    //         try
    //         {
    //             dateStringData = dateStringData.Trim();
    //             if (dateStringData.Contains("."))
    //             {
    //                 dateStringData = dateStringData.Replace('.', '-');
    //             }

    //             string format = "dd.MM.yyyy";
    //             if (dateStringData != null && dateStringData.Contains('/'))
    //             {
    //                 format = "dd/MM/yyyy";
    //             }
    //             else if (dateStringData != null && dateStringData.Contains('-'))
    //             {
    //                 format = "dd-MM-yyyy";
    //             }

    //             //  date = DateTime.ParseExact("24/01/2013", format, CultureInfo.InvariantCulture);
    //             //DateTime date3 = DateTime.ParseExact("22/11/2009", "dd/MM/yyyy", null);

    //             //DateTime date1 = DateTime.ParseExact(dateStringData, format, null);
    //            // date = DateTime.ParseExact(dateStringData, format, CultureInfo.InvariantCulture);

    //              date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(date.ToString());

    //         }
    //         catch (Exception ex1)
    //         {
    //             try
    //             {
    //                 string format = format = "dd.MMM.yy";
    //                 date = DateTime.ParseExact(dateStringData, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                 Console.Write(date.ToString());

    //             }
    //             catch (Exception ex2)
    //             {


    //             }
    //         }
    //         if (date > getDefaultValidationDateTime())
    //         {
    //             string dateString = date.ToString("dd-MMM-yyyy");
    //             return dateString;
    //         }           

    //         return "";
    //     }

    //     //public static string getDateTimeString(DateTime date)
    //     //{
    //     //    string dateString = date.ToString("dd-MMM-yyyy HH:mm");
    //     //    return dateString;
    //     //}


    //     public static DateTime getDateTimeFromString(string strDateValue)
    //     {
    //         //return strDateValue.ToDa

    //         DateTime Date = DateTime.UtcNow;
    //         try
    //         {
    //             string format = "dd-MMM-yyyy hh:mm tt";
    //             if (strDateValue != null && strDateValue.Contains('/'))
    //             {
    //                 format = "MM/dd/yyyy";
    //             }
    //             //string dateString = "2/1/2018 6:36 PM";
    //             Date = DateTime.ParseExact(strDateValue, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(Date.ToString());
    //         }
    //         catch (Exception ex)
    //         {
    //             try
    //             {
    //                 string format = "dd-MMM-yy hh:mm a";
    //                 Date = DateTime.ParseExact(strDateValue, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                 Console.Write(Date.ToString());
    //             }
    //             catch (Exception ex1) { }
    //         }

    //         return Date;
    //     }


    //     public static string getDefaultUKTime()
    //     {
    //         var serverDate = DateTime.Now;

    //         try
    //         {
    //             var britishZone = TimeZoneInfo.FindSystemTimeZoneById("GMT Standard Time");

    //             var newDate = TimeZoneInfo.ConvertTime(serverDate, TimeZoneInfo.Local, britishZone);

    //             string dateString = newDate.ToString("dd-MMM-yyyy hh:mm tt");
    //             return dateString;
    //         }
    //         catch (Exception ex) { }

    //         return "";
    //     }

    //     public static DateTime getDateFromStringWithDefaultDateForTransUnion(string strDateValue)
    //     {
    //         //return strDateValue.ToDa

    //         DateTime Date = DateTime.UtcNow;
    //         try
    //         {
    //             string format = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
    //             if (strDateValue != null && strDateValue.Contains('/'))
    //             {
    //                 format = "MM/dd/yyyy";
    //             }
    //             //string dateString = "2/1/2018 6:36 PM";
    //             Date = DateTime.ParseExact(strDateValue, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(Date.ToString());
    //         }
    //         catch (Exception ex)
    //         {
    //             try
    //             {
    //                 string format = "yyyy-MM-dd'T'HH:mm:ss";
    //                 Date = DateTime.ParseExact(strDateValue, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //                 Console.Write(Date.ToString());
    //             }
    //             catch (Exception ex1) {

    //                 Date = getDefaultDateTime();
    //             }
    //         }

    //         return Date;
    //     }


    //     public static DateTime getDefaultServiceStartDateTime(DateTime date)
    //     {
    //         date = new DateTime(date.Year, date.Month, date.Day, 9, 00, 00);
    //         return date;
    //     }
    //     public static DateTime getDefaultServiceEndDateTime(DateTime date)
    //     {
    //         date = new DateTime(date.Year, date.Month, date.Day, 9, 30, 00);
    //         return date;
    //     }


    //     public static DateTime getUKTimeAdjustmentTime(DateTime date)
    //     {
    //         //date = new DateTime(date.Year, date.Month, date.Day, 9, 30, 00);

    //         try
    //         {
    //             // UK goverment changed time for few month day light saving +> so they increase time to GMT+1 
    //             var hourAdjustment = 1;
    //             date = date.AddHours(hourAdjustment);

    //         }
    //         catch (Exception ex) { }

    //         return date;
    //     }

    //     public static DateTime? retriveDateTimeFromString(string str)
    //     {
    //         bool ret = false;
    //         DateTime? resDate = new DateTime?();
    //         Regex rgx = new Regex(@"(Jan(uary)?|Feb(ruary)?|Mar(ch)?|Apr(il)?|May|Jun(e)?|Jul(dy)?|Aug(ust)?|Sep(tember)?|Oct(ober)?|Nov(ember)?|Dec(ember)?|Jan.|Feb.|Mar.|Apr.|May.|Jun.|Jul.|Aug.|Sep.|Oct.|Nov.|Dec.)\s+\d{1,2},\s+\d{4}");
    //         Match mat = rgx.Match(str);
    //         if (mat.Success)
    //         {
    //             DateTime enteredDate = DateTime.Parse(mat.Value);
    //             resDate = enteredDate;
    //             ret = true;
    //         }
    //         if (!ret)
    //         {
    //             rgx = new Regex(@"(\d+)[-.\/](\d+)[-.\/](\d+)");
    //             mat = rgx.Match(str);
    //             if (mat.Success)
    //             {
    //                 try
    //                 {
    //                     DateTime enteredDate = DateTime.Parse(mat.Value);
    //                     resDate = enteredDate;
    //                     ret = true;
    //                 }
    //                 catch { }
    //                 try
    //                 {
    //                     DateTime enteredDate = DateTime.ParseExact(mat.Value, "dd-MM-yyyy", CultureInfo.InvariantCulture);
    //                     resDate = enteredDate;
    //                     ret = true;
    //                 }
    //                 catch { }
    //             }
    //         }
    //         if (!ret)
    //         {
    //             DateTimeRoutines.ParsedDateTime pdt;
    //             if (DateTimeRoutines.TryParseDate(str, DateTimeRoutines.DateTimeFormat.USA_DATE, out pdt))
    //             {
    //                 DateTime enteredDate = DateTime.Parse(pdt.DateTime.ToString());
    //                 resDate = enteredDate;
    //                 ret = true;
    //             }
    //         }
    //         if (!ret)
    //         {
    //             rgx = new Regex(@"^\d{4}$");
    //             mat = rgx.Match(str);
    //             if (mat.Success)
    //             {
    //                 string iDate = "01/01/" + str;
    //                 DateTime oDate = Convert.ToDateTime(iDate);
    //                 resDate = oDate;
    //                 ret = true;
    //             }
    //         }
    //         if (!ret)
    //         {
    //             string[] splStr = Regex.Split(str, "-");
    //             if (splStr.Count() > 1)
    //             {
    //                 rgx = new Regex(@"^\d{4}$");
    //                 mat = rgx.Match(splStr[0]);
    //                 if (mat.Success)
    //                 {
    //                     string iDate = "01/" + Convert.ToInt32(splStr[1]).ToString("00") + "/" + splStr[0];
    //                     DateTime oDate = Convert.ToDateTime(iDate);
    //                     resDate = oDate;
    //                     ret = true;
    //                 }
    //             }
    //         }
    //         return resDate;
    //     }

    //     //<option value = "January" ng-selected="'January' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">January</option>
    //     //                      <option value = "February" ng-selected="'February' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">February</option>
    //     //                      <option value = "March" ng-selected="'March' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">March</option>
    //     //                      <option value = "April" ng-selected="'April' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">April</option>
    //     //                      <option value = "May" ng-selected="'May' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">May</option>
    //     //                      <option value = "June" ng-selected="'June' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">June</option>
    //     //                      <option value = "July" ng-selected="'July' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">July</option>
    //     //                      <option value = "August" ng-selected="'August' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">August</option>
    //     //                      <option value = "September" ng-selected="'September' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">September</option>
    //     //                      <option value = "October" ng-selected="'October' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">October</option>
    //     //                      <option value = "November" ng-selected="'November' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">November</option>
    //     //                      <option value = "December" ng-selected="'December' == billingViewModel.ServiceBillingPaymentDetails.PaymentMonth">December</option>
    //     // var monthStart = new DateTime(today. Year, today. Month, 1); 

    //     public static int getMonthInteger(string monthString)
    //     {
    //         //  var month = 1;
    //         switch (monthString)
    //         {
    //             case "January":
    //                 return 1;

    //             case "February":
    //                 return 2;

    //             case "March":
    //                 return 3;

    //             case "April":
    //                 return 4;

    //             case "May":
    //                 return 5;

    //             case "June":
    //                 return 6;

    //             case "July":
    //                 return 7;

    //             case "August":
    //                 return 8;

    //             case "September":
    //                 return 9;

    //             case "October":
    //                 return 10;

    //             case "November":
    //                 return 11;

    //             case "December":
    //                 return 12;
    //             default:
    //                 return -1;
    //         }

    //         return -1;
    //     }

    //     public static string getDateStringFromIRESSDATEString(string strDateValue)
    //     {
    //         string dateString = "";
    //     //return strDateValue.ToDa

    //     DateTime Date = DateTime.UtcNow;
    //         try
    //         {
    //             string format = "yyyymmdd";
    //             if (strDateValue != null && strDateValue.Contains('/'))
    //             {
    //                 format = "MM/dd/yyyy";
    //             }
    //             //string dateString = "2/1/2018 6:36 PM";
    //             Date = DateTime.ParseExact(strDateValue, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(Date.ToString());

    //              dateString = Date.ToString("dd-MMM-yyyy");
    //             //return dateString;


    //         }
    //         catch (Exception ex)
    //         {
    //         }

    //         return dateString;
    //     }

    //     public static string getDateStringFromFormattedDateString(string strDateValue)
    //     {
    //         string dateString = "";
    //         //return strDateValue.ToDa

    //         DateTime Date = DateTime.UtcNow;
    //         try
    //         {
    //             string format = "yyyymmdd";
    //             if (strDateValue != null && strDateValue.Contains('/'))
    //             {
    //                 format = "MM/dd/yyyy";
    //             }
    //             //string dateString = "2/1/2018 6:36 PM";
    //             Date = DateTime.ParseExact(strDateValue, format, new CultureInfo("en-US"), DateTimeStyles.None);
    //             Console.Write(Date.ToString());

    //             dateString = Date.ToString("dd-MMM-yyyy");
    //             //return dateString;


    //         }
    //         catch (Exception ex)
    //         {
    //         }

    //         return dateString;
    //     }
}
