import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { DealRoomService } from 'src/app/services/DealRoom.service';
import { ActivatedRoute } from "@angular/router";
import { CompanyStartupsIdeaInformation } from 'src/app/models/CompanyStartupsIdeaInformation.model';
import { CompanyStartupsIdeaInformationService } from 'src/app/services/CompanyStartupsIdeaInformation.service';
import { MediaService } from 'src/app/services/Media.service';
import { ProjectConceptModel } from 'src/app/models/ProjectConcept.model';
import { ProjectDocumentModel } from 'src/app/models/projectDocument';
import { CompanyStartupsDocumentInfo } from 'src/app/models/CompanyStartupsDocumentInfo.model';
import { CompanyStartupsDocumentInfoService } from 'src/app/services/CompanyStartupsDocumentInfo.service';
import { User } from 'src/app/models/User.model';
import { Company } from 'src/app/models/Company.model';
import MaxFileSizeHelper from 'src/app/helper/MaxFileSize';

@Component({
  selector: 'app-project-document-popup',
  templateUrl: './project-document-popup.component.html',
  styleUrls: ['./project-document-popup.component.scss']
})
export class ProjectDocumentPopupComponent implements OnInit {

  @Output() openUBOPopup = new EventEmitter();

  // public type: string = '';
  // public title: string = '';
  // public purpose: string = '';
  public isSubmited: boolean = false;
  // public descuotion: string = '';
  public _documentInput: CompanyStartupsDocumentInfo;

  public imageURl: string = '';
  public videoUrl: string = '';
  public docUrl: string = '';
  public isImageExist: boolean = false;
  public isVideoExist: boolean = false;
  public isDocExist: boolean = false;
  public fileSizeExistLimite: boolean = false;
  public fileName: string | null = null;
  public _projectConcept: ProjectConceptModel;
  _IdeainfoByconpanyId: any;
  usercompanyId: any;
  projectForm: FormGroup;
  public _companyStartupsIdeaInformation: CompanyStartupsIdeaInformation;
  public _dealRoomService: DealRoomService;
  public _companyStartupsIdeaInformationService: CompanyStartupsIdeaInformationService;
  public _companyStartupsDocumentInfoService: CompanyStartupsDocumentInfoService;
  public _mediaService: MediaService;
  public _currrentUser: User;
  public _currrentCompany: Company;
  public _documentId: number = 0;

  constructor(dealRoomService: DealRoomService, private activatedRoute: ActivatedRoute,
    companyStartupsIdeaInformationService: CompanyStartupsIdeaInformationService,
    companyStartupsDocumentInfoService: CompanyStartupsDocumentInfoService,
    mediaService: MediaService) {
    this._dealRoomService = dealRoomService;
    this._companyStartupsIdeaInformationService = companyStartupsIdeaInformationService;
    this._companyStartupsDocumentInfoService = companyStartupsDocumentInfoService;
    this._mediaService = mediaService;
    this._companyStartupsIdeaInformation = {} as CompanyStartupsIdeaInformation;
  }

  public initModel() {
    this._companyStartupsIdeaInformation = {
      Id: 0,
      UserId: this._currrentUser.Id,
      UserCompanyId: this._currrentUser.UserCompanyId,
      Status: 101,
      CreationDate: new Date(),
      UpdatedDate: new Date(),

    } as CompanyStartupsIdeaInformation;


    this._documentInput = {
      Id: 0,
      UserId: this._currrentUser.Id,
      UserCompanyId: this._currrentUser.UserCompanyId,
      Status: 101,
      CreationDate: new Date(),
      UpdatedDate: new Date(),


      Name: "",
      Remarks: "",
      Type: "",
      Purpose: "",
      DocumentUrl: "",
      ImageLocation: "aws",

    } as CompanyStartupsDocumentInfo;

  }

  ngOnInit() {
    var currentUserJSON = localStorage.getItem(StorageKeyName.KEY_LOGGEDIN_USER);
    this._currrentUser = JSON.parse(currentUserJSON);
    var currentCompanyJSON = localStorage.getItem(StorageKeyName.KEY_LOGGEDIN_USER);
    this._currrentCompany = JSON.parse(currentCompanyJSON);

    this.initModel();


    this._documentId = 0;
    console.log(this._documentId);
    // for edit
    var documentId = localStorage.getItem(StorageKeyName.PROJECT_DOCUMENT_ID);
    // console.log(documentId);
    if (documentId) {
      this._documentId = Number(documentId);
      console.log(this._documentId);
      this.isDocExist = true;
      this.GetCompanyStartUpsDocumentInfoByCompanyStartupsDocumentInfoId(this._documentId);
    } else {
      this.isDocExist = false;
    }
    // var dataForEdit = localStorage.getItem(StorageKeyName.PROJECT_DOCUMENT_Edit_Date);
    // if (dataForEdit) {
    //   this._documentInput = JSON.parse(dataForEdit);
    //}

  }
  errors = ""


  public onFileSelected(event: any) {
    const file: File = event.target.files[0];

    const fileSize = event.target.files[0].size;
    this.fileSizeExistLimite = MaxFileSizeHelper.MaxFileSize(fileSize);
    if (file && !this.fileSizeExistLimite) {

      var formData = new FormData();
      const maxSizeInMB = 10;


      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > maxSizeInMB) {
        this.errors = "File size exceeds the maximum limit (10MB)";
        console.error('File size exceeds the maximum limit.');
        return;
      }
      formData.append('file', file);
      var reader = new FileReader();
      this.uploadFiles(formData);
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.docUrl = event.target.result;
        this.isDocExist = true;
      }
    }
    // if(fileSize > 10485760){
    //   this.isDocExist = false;
    //   this.fileSizeExistLimite = true;
    // }


  }

  // public onFileSelected(event: any) {

  //   const file: File = event.target.files[0];
  //   if (file) {

  //     var formData = new FormData();
  //     const file = event.target.files[0] as File;
  //     const maxSizeInMB = 10; 


  //     const fileSizeInMB = File.size / (1024 * 1024); 
  //     if (fileSizeInMB > maxSizeInMB) {
  //         this.errors = "File size exceeds the maximum limit (10MB)"; 
  //         console.error('File size exceeds the maximum limit.');
  //         return;
  //     }
  //     formData.append('file', file);
  //     var reader = new FileReader();
  //     this.uploadFiles(formData);
  //     reader.readAsDataURL(event.target.files[0]);
  //     reader.onload = (event: any) => {
  //       this.docUrl = event.target.result;
  //       this.isDocExist = true;
  //     }
  //   }


  // }

  public onFileRemove() {
    this.isDocExist = false;
  }

  public async uploadFiles(fromdata: FormData) {
    try {
      this._mediaService.UploadFiles(fromdata).subscribe({
        next: (response) => {

          if (response.Success) {
            //   var responseValue = response.ResponseData.Images[0].Url;
            this._documentInput.DocumentUrl = response.ResponseData.Media.Url;
            this._documentInput.Name = response.ResponseData.Media.Name;
            //  this._videoFileName = response.ResponseData.Images[0].Name;
            this.isDocExist = true;
          }
          else {
            console.log("No file uploaded")
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  viewDocument(url) {

    //window.open(url, "_blank");    

    try {
      this._companyStartupsIdeaInformationService.getAmazonS3FileUrlByWebFileUrl(url).subscribe({
        next: (responseData) => {
          // 
          if (responseData.Success) {

            window.open(responseData.ResponseData.FileUrl, "_blank");
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  public onSubmit() {
    // this._documentInput.DocumentUrl = this.docUrl;
    this._documentInput.UserId = this._currrentUser.Id;
    this._documentInput.UserCompanyId = this._currrentUser.UserCompanyId;
    console.log(this._documentInput);
    this.isSubmited = true;

    if (!this._documentInput.Type || this._documentInput.Type.length == 0) {
      return
    }
    if (!this._documentInput.Purpose || this._documentInput.Purpose.length == 0) {
      return
    }
    if (!this._documentInput.Name || this._documentInput.Name.length == 0) {
      return
    }
    if (!this._documentInput.Remarks || this._documentInput.Remarks.length == 0) {
      return
    }

    this.openUBOPopup.emit();
    this.postStartUpsDocumentInfo(this._documentInput)
  }

  public async postStartUpsDocumentInfo(documentInput: CompanyStartupsDocumentInfo) {
    try {
      this._companyStartupsDocumentInfoService.postStartUpsDocumentInfo(documentInput).subscribe({
        next: (response) => {

          if (response.Success) {
            console.log(response)

            this.initModel();
          }
          else {

          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  public onUpdate() {
    //this._documentInput.DocumentUrl = this.docUrl;

    this._documentInput.UserId = this._currrentUser.Id;
    this._documentInput.UserCompanyId = this._currrentUser.UserCompanyId;

    this.updateStartUpsDocumentInfo(this._documentInput)
  }

  public async updateStartUpsDocumentInfo(documentInput: CompanyStartupsDocumentInfo) {
    try {
      this._companyStartupsDocumentInfoService.updateStartUpsDocumentInfo(documentInput).subscribe({
        next: (response) => {

          if (response.Success) {
            this.openUBOPopup.emit();
            this.initModel();
          }
          else {

          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  public async GetCompanyStartUpsDocumentInfoByCompanyStartupsDocumentInfoId(id: number) {
    try {
      this._companyStartupsDocumentInfoService.getCompanyStartUpsDocumentInoById(id).subscribe({
        next: (response) => {

          if (response.Success) {
            this._documentInput = response.ResponseData.CompanyStartupsDocumentInfo;
          }
          else {

          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

}

