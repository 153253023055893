import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import StorageKeyName from 'src/app/helper/StorageKeyName';
//import { GoSourceProductRequest } from 'src/app/models/GoSourceProductRequest.model';
import { LenderProduct } from 'src/app/models/LenderProduct';
//import { GoSourceLenderProductService } from 'src/app/services/GoSourceLenderProduct.service';

@Component({
  selector: 'app-best-mortgage-card',
  templateUrl: './best-mortgage-card.component.html',
  styleUrls: ['./best-mortgage-card.component.scss']
})
export class BestMortgageCardComponent implements OnInit {
  
  @Input() lenderProduct: LenderProduct;
  @Input() productList:  any;
  @Input() productType:  number;

  //public _searchParameter : GoSourceProductRequest;
  public _lenderProductList : any = [];
  //public _goSourceLenderProductService: GoSourceLenderProductService;
  public _IsAllDataLoaded = false;

  lilstItem : object = [
    {
      id:1,
    },
    {
      id:2,
    },
    {
      id:3,
    },
    {
      id:4,
    },
  ]

  constructor(private router: Router
    
  ) { 
   // this._searchParameter = {} as GoSourceProductRequest;
   // this._goSourceLenderProductService = goSourceLenderProductService;
  }

  ngOnInit(){}
  
  public setLocalstorage(){
    // this._searchParameter.ProductTypeId = this.productType;
    // this._searchParameter.PaymentMethod = 'Repayment';
    // this._searchParameter.IsProductTypeFixed = true;
    // this._searchParameter.IsProductTypeVariable = true;
    // this._searchParameter.IsProductTypeDiscount = true;
    // this._searchParameter.IsProductTypeTracker = true;
    // this._searchParameter.Criteria = 1;
    // this._searchParameter.Page = 0;
    // this._searchParameter.Count = 10;
    // this._searchParameter.PropertyValuationAmount = 250000;
    // this._searchParameter.TotalTermMonth = 20;
    // this._searchParameter.LoanAmount = 125000;
    // this._searchParameter.DepositAmount = 0;
    // this._searchParameter.PreferredMortgageTermYear =  20;
    // this._searchParameter.PreferredMortgageTermMonth = 0;

    // const lenderProductSearchModelJSON = JSON.stringify(this._searchParameter);
    // localStorage.setItem(StorageKeyName.KEY_BEST_RATES_PAGE_PRODUCT_SEARCH_MODEL, lenderProductSearchModelJSON);
    
    // if(this.productType == 1)
    // {
    //   var mortgageProductList = localStorage.getItem(StorageKeyName.KEY_MORTGAGE_PRODUCT);
    //   localStorage.setItem(StorageKeyName.KEY_BEST_RATES_PAGE_PRODUCT_DATA, mortgageProductList);
    // }
    // else if(this.productType == 3)
    // {
    //   var buytoletProductList = localStorage.getItem(StorageKeyName.KEY_BUY_TOLET_PRODUCT);
    //   localStorage.setItem(StorageKeyName.KEY_BEST_RATES_PAGE_PRODUCT_DATA, buytoletProductList);
    // }
   

    // //const lenderProductListJSON = JSON.stringify(this._lenderProductList);
   

    // //if(this._IsAllDataLoaded){
    //   this.router.navigate(['/best-rate-search']);
    // //}

    // // this.onClickGoSOurceProductSearch(this._searchParameter)

    // // if(this._IsAllDataLoaded){
    // //   this.router.navigate(['/best-rate-search']);
    // // }
    
  }

  // public onClickGoSOurceProductSearch(goSourceProductRequest: GoSourceProductRequest) {


  //   try {

  //     this._goSourceLenderProductService.GetProductListForCaseSourcingbyCaseRequirementsDataForMortgageByMobileApplication(goSourceProductRequest).subscribe({
  //       next: (responseData) => {
  //         console.log(responseData);
          
  //         if (responseData.Success && responseData.ResponseData != null && responseData.ResponseData.LenderProductList.length > 0) {

  //           if( responseData.ResponseData.LenderProductList.length < 5)
  //           {
  //             this._IsAllDataLoaded = true;
  //           }

  //           for(var k=0; k< responseData.ResponseData.LenderProductList.length; k++)
  //           {
  //             this._lenderProductList.push(responseData.ResponseData.LenderProductList[k]);
  //           }

  //           const lenderProductJSON = JSON.stringify(this._lenderProductList);
  //           localStorage.setItem(StorageKeyName.KEY_BEST_RATES_PAGE_PRODUCT_DATA, lenderProductJSON);

  //           const lenderProductSearchModelJSON = JSON.stringify(goSourceProductRequest);
  //           localStorage.setItem(StorageKeyName.KEY_BEST_RATES_PAGE_PRODUCT_SEARCH_MODEL, lenderProductSearchModelJSON);

  //           // var goSourceProductRequestModel = localStorage.getItem(StorageKeyName.KEY_BEST_RATES_PAGE_PRODUCT_SEARCH_MODEL);
  //           // var gosourceProductList = localStorage.getItem(StorageKeyName.KEY_BEST_RATES_PAGE_PRODUCT_DATA);

  //           if(this._lenderProductList.length > 30)
  //           {
  //             this._IsAllDataLoaded = true;
  //           }
  //         }
  //         else 
  //         {            
  //           this._IsAllDataLoaded = true;
  //         }
  //         if(!responseData.Success){
  //           this._IsAllDataLoaded = true;
  //         }

  //         if(this._IsAllDataLoaded){
  //           this.router.navigate(['/best-rate-search']);
  //         }

  //       },
  //       error: (response) => {
  //         this._IsAllDataLoaded = true;
  //         if(this._IsAllDataLoaded){
  //           this.router.navigate(['/best-rate-search']);
  //         }
  //         console.log(response);
  //         console.log(response.error);
  //       },
  //     });
  //   } catch (error) {
  //     console.error(error);
  //     this._IsAllDataLoaded = true;
  //     if(this._IsAllDataLoaded){
  //       this.router.navigate(['/best-rate-search']);
  //     }
  //   }
  // }
}
