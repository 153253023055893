

export default class MaxFileSizeHelper {


  public static MaxFileSize(size: number) {
    if (size >= 10485760) {
      return true;
    }
    return false;
  }

  public static MaxFileSize15MB(size: number) {
    if (size >= 15728640) {
      return true;
    }
    return false;
  }

  public static MaxFileSize1MB(size: number) {
    console.log(size)
    if (size >= 1048576) {
      return true;
    }
    return false;
  }


  public static MaxHeightWidth(selectedFile: any, VideoSizeExistLimite: boolean) {
    const MAX_WIDTH = 720;
    const MAX_HEIGHT = 300;
    const image = new Image();
    image.src = URL.createObjectURL(selectedFile);

    var imageFunction = image.onload = () => {
      if (image.width > MAX_WIDTH || image.height > MAX_HEIGHT) {
        VideoSizeExistLimite = true;
        return true;
      }

      VideoSizeExistLimite = false;
      return false;
    };

    // if(imageFunction){
    //   return true
    // }
    // return false;
  }


  public static MaxHeightWidth512_512(selectedFile: any): Promise<boolean> {
    const MAX_WIDTH = 512;
    const MAX_HEIGHT = 512;
    const image = new Image();
    const url = URL.createObjectURL(selectedFile);
  
    return new Promise((resolve) => {
      image.onload = () => {
        URL.revokeObjectURL(url);  // Clean up the object URL after use
        if (image.width === MAX_WIDTH || image.height === MAX_HEIGHT) {
          console.log('LogoSizeExistLimite', false);
          resolve(false);
        } else {
          console.log('LogoSizeExistLimite', true);
          resolve(true);
        }
      };
      image.src = url;
    });
  }
  


  // public static IMgHeightWidth(MAX_WIDTH : any ,MAX_HEIGHT : any, selectedFile : any) {

  //   const image = new Image();
  //   image.src = URL.createObjectURL(selectedFile);
  //   var imageFunction = false;

  //   image.onload = () => {

  //     if (image.width > MAX_WIDTH || image.height > MAX_HEIGHT) {
  //       imageFunction = true
  //       return 0;
  //     }
  //     return 0  ;
  //   };
  // }


}