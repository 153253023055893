
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router'; 
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';
import { ChatModel } from 'src/app/models/Chat.model';
import { TimelinePost } from 'src/app/models/Timeline.model';
import { TimelinePostResponseModel } from 'src/app/models/TimelinePostResponse.model';
import { TimelinePostsRequestModel } from 'src/app/models/TimelinePostsRequest.model';
import { User } from 'src/app/models/User.model';
import { ChatService } from 'src/app/services/Chat.service';



@Component({
  selector: 'app-deal-room-discussions-tab',
  templateUrl: './deal-room-discussions-tab.component.html',
  styleUrls: ['./deal-room-discussions-tab.component.scss']
})
export class DealRoomDiscussionsTabComponent implements OnInit, AfterViewChecked  {
  
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  public _chatService: ChatService;
  //public _ChatData: ChatModel;
  public _ChatData: TimelinePost;
  public _chatRequestModel: TimelinePostsRequestModel;
  public _currentUser: User;

  

  public hide = true;
  public chatDataList : Array<TimelinePostResponseModel>;
  dynamicForm!: FormGroup;y
  public submitted=false;
  public ErrorMessage:string="";
  public isSuccess=true;
  ticketFormGroup: FormGroup[];
  public ticket: UntypedFormGroup;
  public ticketforPhone: UntypedFormGroup;


  
  constructor(
    public router:Router,
    public formBuilder: FormBuilder,
    chatService: ChatService,
    private elementRef: ElementRef
  ) {
    this._chatService = chatService;
    this._ChatData = {} as TimelinePost;
    this._chatRequestModel = {} as TimelinePostsRequestModel;

    this._currentUser = AuthProviderHelper.getCurrentUser();
    
    this.addModel();
  }

  private addModel()
  {
    // CHat model initiate 
    this._ChatData.AdditionalAttributeValue = "";
    this._ChatData.Message = "";
    this._ChatData.PostTypeName = "";
    this._ChatData.AdditionalAttributeValue = "";
    //this._ChatData.InlineTags: [],
    this._ChatData.Checkin= "";
    this._ChatData.FromLat= 0;
    this._ChatData.FromLng= 0;    
    this._ChatData.ReceiverId = 0 ;// this._currentUser.ReceiverId,
    this._ChatData.OwnerId =  this._currentUser.Id;
    this._ChatData.SenderId =  this._currentUser.Id;
    this._ChatData.UserCompanyId =  this._currentUser.UserCompanyId;



    // Requet model initiate 
    this._chatRequestModel.Count = 20;
    this._chatRequestModel.CustomerId = this._currentUser.Id;
    this._chatRequestModel.IsPrivate = false;
    this._chatRequestModel.Page = 0;
    this._chatRequestModel.ReceiverId = 0;
    this._chatRequestModel.SenderId = this._currentUser.Id;
    this._chatRequestModel.TaskId = 0;
    this._chatRequestModel.timeLineId = 0;

  }

  
  ngOnInit() {
    this.GetChatList();
  } 

  ngAfterViewChecked() {        
    this.scrollToBottom();        
} 

  public  GetChatList() {

    try {
      this._chatService.GetTimelinePosts(this._chatRequestModel).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null && responseData.ResponseData.TimelinePosts != null) {
            this.chatDataList = responseData.ResponseData.TimelinePosts;
            console.log("data loaded");
            this.scrollToBottom();
          }
        //  this.scrollDown();
      //  this.messages.changes.subscribe(this.scrollDown);
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }


  public submitChatData() {

    try {

      if(this._ChatData.Message == null || this._ChatData.Message.length ==0)
      {
        return;
      }


      this._chatService.PostToTimeline(this._ChatData).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null && responseData.ResponseData.Post != null && responseData.ResponseData.Post.Id > 0) {
           // this.chatDataList = responseData.ResponseData.TimelinePosts;
           this._ChatData.Message = "";
           this.chatDataList.push(responseData.ResponseData.Post);
           console.log("data loaded");
           this.scrollToBottom();

          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }


 public scrollToBottom(): void {
    try {
        this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch(err) { }                 
}

}



