import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-details-conecept',
  templateUrl: './project-details-conecept.component.html',
  styleUrls: ['./project-details-conecept.component.scss']
})
export class ProjectDetailsConeceptComponent implements OnInit {

  constructor() { }
  
  ngOnInit() {
    
  }
  

  public  addConecept(){
    // 
  }

  public  editConecept(){
    // 
  }

}
