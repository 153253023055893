import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import StorageKeyName from 'src/app/helper/StorageKeyName';
//import { DealRoomService } from 'src/app/services/DealRoom.service';
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyStartupsTeams } from 'src/app/models/CompanyStartupsTeams.model';
import { CompanyStartupsTeamsService } from 'src/app/services/CompanyStartupsTeams.service';
import { MediaService } from 'src/app/services/Media.service';
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';


@Component({
  selector: 'app-team-card',
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss']
})
export class TeamCardComponent {
  //@Input() teamCardData: any;
  //teamCards: Array<CompanyStartupsTeams> = [];

  @Input() _CompanyStartupsIdeaInformationId;


  count: number = 0;
  urlImage = "";
  urlVideo = "";
  urlFile = "";
  showImage: boolean = false;
  showVideo: boolean = false;
  showFile: boolean = false;
  _IdeainfoByconpanyId: any;
  usercompanyId: any;
  projectTeamsForm: FormGroup;
  @Input() _companyStartupsTeams: CompanyStartupsTeams;
  // public _dealRoomService: DealRoomService;
  public _companyStartupsTeamsService: CompanyStartupsTeamsService;
  public _mediaService: MediaService;
  public _currrentUser: any;
  public _isImageUPloded: boolean = false;
  public fileName: string = '';
  public imageURl: string = '';
  public isSubmited:boolean = false;


  constructor(private activatedRoute: ActivatedRoute, companyStartupsTeamsService: CompanyStartupsTeamsService, mediaService: MediaService, public router: Router) {
    //this._dealRoomService = dealRoomService;
    this._companyStartupsTeamsService = companyStartupsTeamsService;
    this._mediaService = mediaService;


    this._currrentUser = AuthProviderHelper.getCurrentUser();

    this._companyStartupsTeams = {
      Id: 0,
      UserId: this._currrentUser.Id,
      UserCompanyId: this._currrentUser.UserCompanyId,
      Status: 101,
      CreationDate: new Date(),
      UpdatedDate: new Date(),
      TeamMemberName: "",
      BranchId: 0,
      DepartmentId: 0,
      Designation: "",
      Experienced: "",
      Remarks: "",
      Skills: "",
      TeamMemberId: 0,
      TeamMemberImageUrl: "",
      VersionNumber: 0

    } as CompanyStartupsTeams;

  }
  public initModel() {
    this._companyStartupsTeams = {
      Id: 0,
      UserId: this._currrentUser.Id,
      UserCompanyId: this._currrentUser.UserCompanyId,
      Status: 101,
      CreationDate: new Date(),
      UpdatedDate: new Date(),
      TeamMemberName: "",
      BranchId: 0,
      DepartmentId: 0,
      Designation: "",
      Experienced: "",
      Remarks: "",
      Skills: "",
      TeamMemberId: 0,
      TeamMemberImageUrl: "",
      VersionNumber: 0,


    } as CompanyStartupsTeams;


    this._isImageUPloded = false;
  }

  ngOnInit() {

    this.usercompanyId = this.activatedRoute.queryParams.subscribe((queryParams) => {
      this._CompanyStartupsIdeaInformationId = queryParams["key"];
      this._companyStartupsTeams.CompanyStartupsIdeaInformationId = this._CompanyStartupsIdeaInformationId;
      if (this._CompanyStartupsIdeaInformationId != null && this._CompanyStartupsIdeaInformationId.length > 0) {
        //    this.GetTeamsInformationById(queryParams["key"])
      }

    });
    //  this.initModel();

  }

  public onFileSelected(event: any) {
    //const file: File = event.target.files[0];
    var formData = new FormData();
    const file = event.target.files[0] as File;
    formData.append('file', file);
    this.uploadProfilePicture(formData);
    if (file) {
      this.fileName = file.name;
      console.log(this.fileName);
      this._isImageUPloded = true;
      // this.imageURl = event.target.result;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event: any) => {
        this.imageURl = event.target.result;
      }
    }
  }

  public onFileRemove() {
    this.fileName = '';
    this._isImageUPloded = false;
    console.log(this.fileName)
    console.log(this.fileName.length)
  }

  addTeamCard() {

    this.initModel();
    //this.teamCards.push(this._companyStartupsTeams);

  }
  onSubmit() {
    this.isSubmited = true;
    if (this._companyStartupsTeams.TeamMemberName == '') {
      return;
    }
    if (this._companyStartupsTeams.Designation == '') {
      
      return;
    }
    if (this._companyStartupsTeams.Id > 0) {
      this.put();
      return;
    }
    else {
      this.post();
    }

  }

  public async post() {
    try {
      this._companyStartupsTeams.CompanyStartupsIdeaInformationId = this._CompanyStartupsIdeaInformationId;
      this._companyStartupsTeamsService.post(this._companyStartupsTeams).subscribe({
        next: (responseData) => {
          //this.router.navigate(['/dashboard/home']);

          //this.addTeamCard();
          this.refreshPage();
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }
  public async put() {
    try {
      this._companyStartupsTeams.CompanyStartupsIdeaInformationId = this._CompanyStartupsIdeaInformationId;
      this._companyStartupsTeamsService.put(this._companyStartupsTeams).subscribe({
        next: (responseData) => {
          // this.addTeamCard();
          this.refreshPage();
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  public async uploadProfilePicture(fromdata: FormData) {
    try {
      this._mediaService.UploadFileToServer(fromdata).subscribe({
        next: (response) => {
          this._companyStartupsTeams.TeamMemberImageUrl = response.ResponseData.Media.ThumbnailUrl;
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  public async GetTeamsInformationById(Id) {

    try {
      this._companyStartupsTeamsService.getTeamsInformationbyId(Id).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null) {
            this._companyStartupsTeams = responseData.ResponseData.companyStartupsTeams;
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  // public async GetTeamsInformationByCompanyIdAndUserId(UserId,CompanyId) {
  //   try {
  //     this._companyStartupsTeamsService.getTeamsInformationByCompanyIdAndUserId(UserId,CompanyId).subscribe({
  //       next: (responseData) => {
  //         console.log(responseData);
  //         if (responseData.Success && responseData.ResponseData != null) {
  //           this._companyStartupsTeams = responseData.ResponseData.companyStartupsTeamsInformation;
  //         }
  //       },
  //       error: (response) => {
  //         console.log(response);
  //         console.log(response.error);
  //       },
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }


  public refreshPage() {
    //this.router.navigate(['/dashboard/project-team']);
    window.location.reload();

  }

  public async deleteTeamMemberByTeamMemberId(Id) {

    try {
      this._companyStartupsTeamsService.DeleteTeamMemberById(Id).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success) {
            //this._companyStartupsTeams = responseData.ResponseData.companyStartupsTeams;
            this.refreshPage();
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

}

