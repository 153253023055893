import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { PostEmailPassoward } from 'src/app/models/PostEmailPassoward';
import { PostLoginByEmail } from 'src/app/models/PostLoginByEmail.model';
import { PostLoginByPhone } from 'src/app/models/PostLoginByPhone.model';
import { SentOtpPhone } from 'src/app/models/SentOtpPhone.model';
import { CompanyStartupsTeamsService } from 'src/app/services/CompanyStartupsTeams.service';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import { DealRoomService } from 'src/app/services/DealRoom.service';

@Component({
  selector: 'app-deal-room-key-team-tab',
  templateUrl: './deal-room-key-team-tab.component.html',
  styleUrls: ['./deal-room-key-team-tab.component.scss']
})
export class DealRoomKeyTeamTabComponent implements OnInit {

  public _companyStartupsTeamsService: CompanyStartupsTeamsService;

  @Input() _companyStartupsTeamsList

  // public _creditMatrixLoginService: CreditMatrixLoginService;
  // public _dealRoomService: DealRoomService;
  // public _PostLoginByEmailData: PostLoginByEmail;
  // public _PostLoginByMobileData: PostLoginByPhone;
  // public _SentOtpPhoneModle: SentOtpPhone;
  // public _PostEmailPassoward: PostEmailPassoward;


  public hide = true;
  public teamsList: any;
  dynamicForm!: FormGroup;
  public isLoginByEmail = true;
  public isOtpRequestSent = false;
  public submitted = false;
  public ErrorMessage: string = "";
  public isSuccess = true;
  ticketFormGroup: FormGroup[];
  public ticket: UntypedFormGroup;
  public ticketforPhone: UntypedFormGroup;



  constructor(
    public router: Router,
    public formBuilder: FormBuilder,
    private fb: FormBuilder,
    companyStartupsTeamsService: CompanyStartupsTeamsService
  ) {
    this._companyStartupsTeamsService = companyStartupsTeamsService;
  }
  ngOnInit() {
    //this.GetAllTeamsInformation();
  }


}
