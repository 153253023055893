import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignUpQrComponent } from '../sign-up-qr/sign-up-qr.component';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-experience-credit-section',
  templateUrl: './experience-credit-section.component.html',
  styleUrls: ['./experience-credit-section.component.scss']
})
export class ExperienceCreditSectionComponent implements OnInit {
  useMax: boolean = false;
  private rotationInterval: any;
  deg = 30;
  counter: number = 10;
  private counterSubscription: Subscription;

  constructor(public dialog: MatDialog, private el: ElementRef) {}

  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }

  ngOnInit(): void {
    // Start the counter using RxJS interval
    this.counterSubscription = interval(250).subscribe(() => {
      this.counter = this.counter + 100;

      //this.counterSubscription.unsubscribe();

      if (this.counter >= 710) {
        // Stop the counter when it reaches 576
        this.counter = 10
        
      }else{
      }
    });

    if (this.useMax) {
      this.rotateBox(0);
    } else {
      this.startRotation();
    }
  }

  private startRotation(): void {
    this.rotationInterval = setInterval(() => {
      this.deg = this.deg + 10;
      if (Math.abs(this.deg) > 120) {
        //clearInterval(this.rotationInterval);
        this.deg = 37;
      } else {
        this.rotateBox(this.deg);
      }
    }, 210);
  }

  private rotateBox(deg: number): void {
    const boxElement = this.el.nativeElement.querySelector('.rotedBox1');
    if (boxElement) {
      boxElement.style.transform = `rotate(${deg}deg)`;
      // boxElement.style.webkitTransform = `rotate(${deg}deg)`;
      // boxElement.style.mozTransform = `rotate(${deg}deg)`;
      // boxElement.style.msTransform = `rotate(${deg}deg)`;
    }
  }
}