import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortgagemagicProperty } from '../models/MortgagemagicProperty.model';
import { ContactPost } from '../models/ContactPost.model';
import { PostLoginByEmail } from '../models/PostLoginByEmail.model';
import { SentOtpPhone } from '../models/SentOtpPhone.model';
import { PostLoginByPhone } from '../models/PostLoginByPhone.model';
import StorageKeyName from '../helper/StorageKeyName';
import { PostEmailPassoward } from '../models/PostEmailPassoward';
import { CompanyStartupsIdeaInformation } from '../models/CompanyStartupsIdeaInformation.model';
import { CompanyStartupsKeyInformation } from '../models/CompanyStartupsKeyInformation.model';
import { CompanyStartupsTeams } from '../models/CompanyStartupsTeams.model';
import { CompanyStartupsDocumentInfo } from '../models/CompanyStartupsDocumentInfo.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyStartupsIdeaInformationService {
    headers: HttpHeaders = new HttpHeaders();
    baseApiUrl: string = environment.UVCSERVERBASEAPI;
    requestOptions: any = {};



    constructor(private http: HttpClient) {
        this.initHTTPHeaderWithAutorization();
    }

    private initHTTPHeaderWithAutorization() {
        this.requestOptions.headers = new HttpHeaders();
        var refreshToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);
        this.requestOptions.headers = this.requestOptions.headers.set('Content-Type', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Accept', 'application/json');
        
        if (accessToken != null && accessToken.length > 0) {
            this.requestOptions.headers = this.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
            this.requestOptions.headers = this.requestOptions.headers.set('refreshToken', refreshToken);
        }
    }



    post(companyStartupsIdeaInformation: CompanyStartupsIdeaInformation): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/companystartupsideainformation/post', companyStartupsIdeaInformation, this.requestOptions);
    }

    put(companyStartupsIdeaInformation: CompanyStartupsIdeaInformation): Observable<any> {

        return this.http.put<any>(
            this.baseApiUrl + '/api/companystartupsideainformation/put', companyStartupsIdeaInformation, this.requestOptions);
    }

    getIdeaInformationByCompanyStartupsIdeaInformationId(CompanyStartupsIdeaInformationId: string): Observable<any> {

        this.initHTTPHeaderWithAutorization();
        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupsideainformation/getcompanystartupsideainformationbycompanystartupsideainformationid?CompanyStartupsIdeaInformationId=' + CompanyStartupsIdeaInformationId, this.requestOptions);
    }


    getIdeaInformationByCompanyIdAndUserIdFromLoginScreen(UserId, UserCompanyId): Observable<any> {

        this.initHTTPHeaderWithAutorization();

        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupsideainformation/getideainformationbycompanyidanduserid?UserCompanyId=' + UserCompanyId + '&UserId=' + UserId, this.requestOptions);
    }


    getIdeaInformationByCompanyIdAndUserId(UserId, UserCompanyId): Observable<any> {

        this.initHTTPHeaderWithAutorization();
        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupsideainformation/getideainformationbycompanyidanduserid?UserCompanyId=' + UserCompanyId + '&UserId=' + UserId, this.requestOptions);
    }

    getAmazonS3FileUrlByWebFileUrl(FileUrl: string): Observable<any> {

        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupsideainformation/getamazons3urlbygeneralurl?FileUrl=' + FileUrl, this.requestOptions);
    }

    GetAllCompanyStartupsIdeaInformationForPublicView(Page: number, Count: number): Observable<any> {

        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupsideainformation/getallcompanystartupsideainformationforpublicview?Page=' + Page + '&Count=' + Count, this.requestOptions);
    }


    downloadAmazonS3FileUrlById(documentid: any): Observable<any> {

        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupsdocumentinfo/downloadfroms3?docId=' + documentid, this.requestOptions);
    }

    // getIdeaInformationByCompanyId(UserCompanyId): Observable<any> {

    //     return this.http.get<any>(
    //         this.baseApiUrl + '/api/startupscompany/getideainformationbycompanyid?UserCompanyId=' + UserCompanyId, this.requestOptions);
    // }


}