import { Component } from '@angular/core';

@Component({
  selector: 'app-account-info-connections',
  templateUrl: './account-info-connections.component.html',
  styleUrls: ['./account-info-connections.component.scss']
})
export class AccountInfoConnectionsComponent {

}
