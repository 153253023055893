import { Component } from '@angular/core';

@Component({
  selector: 'app-account-info-notices',
  templateUrl: './account-info-notices.component.html',
  styleUrls: ['./account-info-notices.component.scss']
})
export class AccountInfoNoticesComponent {

}
