import { Component } from '@angular/core';

@Component({
  selector: 'app-compare-loans-help-info',
  templateUrl: './compare-loans-help-info.component.html',
  styleUrls: ['./compare-loans-help-info.component.scss']
})
export class CompareLoansHelpInfoComponent {

  compareLaonsHelpInfo = [
    {
      id: 1,
      img_path: '../../../assets/compare-loans-assets/debt-consolidation-logo.svg',
      headingTilte: 'You may have to pay more interest',
      description: 'The average APR is 56%',
    },
    {
      id: 2,
      img_path: '../../../assets/compare-loans-assets/collateral-logo.svg',
      headingTilte: 'You may provide security',
      description: 'Such as a house, car, or savings account',
    },
  ];
}
