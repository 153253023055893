import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-info-credit-search',
  templateUrl: './account-info-credit-search.component.html',
  styleUrls: ['./account-info-credit-search.component.scss']
})
export class AccountInfoCreditSearchComponent implements OnInit {
  isActive: boolean = true;

  topText =
    'Queries on your details via TransUnion within the last 24 months. These searches may appear when a third party reviews the details in TransUnion.';
  bottomText =  'No searches for this address have been recorded in the past 24 months.';

  currentAddressSearch = [
    {
      id: 0,
      companyType : 'Money Supermarket Credit Monitor',
      companyInfo : {
        Purpose: 'Customer Credit file request ',
        Date: '22-06-2023',
      }, 
    },

    {
      id: 1,
      companyType : 'Money Supermarket Credit Monitor',
      companyInfo : {
        Purpose: 'Customer Credit file request ',
        Date: '22-06-2023',
      },
    }
  ]




  otherSearchText() {
    this.isActive = true;

    this.topText = 'Queries on your details via TransUnion within the last 24 months. These searches may appear when a third party reviews the details in TransUnion.';
    this.bottomText =
        'No searches for this address have been recorded in the past 24 months.';

  }
  yourSearchText() {
    this.isActive = false;

    this.topText =
      ".Searches you've done on your credit report about TransUnion in the last 24 months. Lenders don't see these searches during your credit application.";
    this.bottomText = "You haven't searched your report in the last 24 months.";
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

}
