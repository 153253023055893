import { Component, Input, } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router'; 
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { PostEmailPassoward } from 'src/app/models/PostEmailPassoward';
import { PostLoginByEmail } from 'src/app/models/PostLoginByEmail.model';
import { PostLoginByPhone } from 'src/app/models/PostLoginByPhone.model';
import { SentOtpPhone } from 'src/app/models/SentOtpPhone.model';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
//import { DealRoomService } from 'src/app/services/DealRoom.service';
import { CompanyStartupsKeyInformation } from 'src/app/models/CompanyStartupsKeyInformation.model'
import { CompanyStartupsKeyInformationService } from 'src/app/services/CompanyStartupsKeyInformation.service';

@Component({
  selector: 'app-project-key-information-tab',
  templateUrl: './project-key-information-tab.component.html',
  styleUrls: ['./project-key-information-tab.component.scss']
})
export class ProjectKeyInformationTabComponent {

@Input() _companyStartupsKeyInformation; 

  
  public _creditMatrixLoginService: CreditMatrixLoginService;
  //public _dealRoomService: DealRoomService;

  // public _PostLoginByEmailData: PostLoginByEmail;
  // public _PostLoginByMobileData: PostLoginByPhone;
  // public _SentOtpPhoneModle: SentOtpPhone;
  // public _PostEmailPassoward: PostEmailPassoward;

  isLoading = true;
  public hide = true;
  public KeyInfoList:any;
  public _currentUser:any;
  dynamicForm!: FormGroup;
  public isLoginByEmail = true;
  public isOtpRequestSent = false;
  public submitted=false;
  public ErrorMessage:string="";
  public isSuccess=true;
  ticketFormGroup: FormGroup[];
  public ticket: UntypedFormGroup;
  public ticketforPhone: UntypedFormGroup;

  public _companyStartupsKeyInformationService: CompanyStartupsKeyInformationService;
  //public _companyStartupsKeyInformation: CompanyStartupsKeyInformation;
  
  constructor(
    public router:Router,
    public formBuilder: FormBuilder,
    creditMatrixLoginService: CreditMatrixLoginService,
   // dealRoomService: DealRoomService,
    private fb: FormBuilder,
    companyStartupsKeyInformationService: CompanyStartupsKeyInformationService
  ) {
   // this._dealRoomService=dealRoomService;
    this._creditMatrixLoginService = creditMatrixLoginService;
    this._companyStartupsKeyInformationService=companyStartupsKeyInformationService;


   // this._companyStartupsKeyInformation = {} as CompanyStartupsKeyInformation;

    // this._PostLoginByEmailData = {} as PostLoginByEmail;
    // this._PostLoginByMobileData = {} as PostLoginByPhone;
    // this._SentOtpPhoneModle = {} as SentOtpPhone;
    // this._PostEmailPassoward = {} as PostEmailPassoward;

    
  }
  ngOnInit() {
    var currentUserJSON = localStorage.getItem(StorageKeyName.KEY_LOGGEDIN_USER);
    this._currentUser = JSON.parse(currentUserJSON);
   
  //  this.GetKeyInformationByCompanyIdAndUserId(this._currentUser.Id,this._currentUser.UserCompanyId);
    //this.GetAllKeyInformation();
  } 
  

  public async GetKeyInformationByCompanyIdAndUserId(UserId,CompanyId) {
    try {
      this._companyStartupsKeyInformationService.getKeyInformationByCompanyIdAndUserId(UserId,CompanyId).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null) {
            this._companyStartupsKeyInformation = responseData.ResponseData.CompanyStartupsKeyInformation;
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  
  public onClickKeyInfo(Id)
  {
    this.router.navigate(['/dashboard/project-key-information'], { queryParams: { key: Id } });
    // this.router.navigate(['/dashboard/project-key-information']);
  }

}
