import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortgagemagicProperty } from '../models/MortgagemagicProperty.model';
import { ContactPost } from '../models/ContactPost.model';
import { PostLoginByEmail } from '../models/PostLoginByEmail.model';
import { SentOtpPhone } from '../models/SentOtpPhone.model';
import { PostLoginByPhone } from '../models/PostLoginByPhone.model';
import StorageKeyName from '../helper/StorageKeyName';
import { PostEmailPassoward } from '../models/PostEmailPassoward';
import { MagicAuthOTP } from '../models/MagicAuthOTP.model';

@Injectable({
    providedIn: 'root'
})

export class CreditMatrixLoginService {

    headers: HttpHeaders = new HttpHeaders();

    baseApiUrl: string = environment.UVCSERVERBASEAPI;
    requestOptions: any = {};
    public _MagicAuthModel: MagicAuthOTP;
    constructor(private http: HttpClient) {
        this.initHTTPHeaderWithAutorization();
        this._MagicAuthModel= {} as MagicAuthOTP;
        // var getToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        // const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);


        // this.headers.append('Content-Type', 'application/json');
        // this.headers.append('Accept', 'application/json');
        // this.headers.append('Authorization', `Bearer ${accessToken}`); 
        // this.headers.append('refreshToken', getToken); 
        // this.headers.append('Access-Control-Allow-Origin', 'https://demo.financemagic.co.uk');      
        // this.headers.append('Access-Control-Allow-Methods', 'DELETE, PUT, GET, POST');
        // this.headers.append('Access-Control-Allow-Credentials', 'true');
    }

    private initHTTPHeaderWithAutorization() {
        this.requestOptions.headers = new HttpHeaders();
        var getToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);
        this.requestOptions.headers = this.requestOptions.headers.set('Content-Type', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Accept', 'application/json');
        // this.requestOptions.headers = this.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
        // this.requestOptions.headers = this.requestOptions.headers.set('refreshToken', getToken);   

        if (accessToken != null && accessToken.length > 0) {
            this.requestOptions.headers = this.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
            this.requestOptions.headers = this.requestOptions.headers.set('refreshToken', getToken);
        }
    }


    // private refreshHeaders() {
    //     const refreshToken = JSON.parse(localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN));
    //     const accessToken = JSON.parse(localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN));

    //     this.headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json',
    //         'Authorization': `Bearer ${accessToken}`,
    //         'refreshToken': refreshToken,
    //         'Access-Control-Allow-Origin': 'https://demo.financemagic.co.uk',
    //         'Access-Control-Allow-Methods': 'DELETE, PUT, GET, POST',
    //         'Access-Control-Allow-Credentials': 'true'
    //     });
    // }

    // for test parpase

    crCreditData(resolutionLoinModel: object): Observable<any> {
        // this.refreshHeaders();

        const requestOptions = { headers: this.headers };

        return this.http.post<any>('https://demo.financemagic.co.uk/api/crcreditdata/post/getreport',
            resolutionLoinModel, requestOptions);

        //return this.http.post<any>('http://mortgage-magic.co.uk/api/crcreditdata/post/getreport', resolutionLoinModel, requestOptions);

    }

    // GetProductListForCaseSourcingbyCaseRequirementsDataForMortgageByMobileApplication(goSourceProductRequest: GoSourceProductRequest): Observable<any> {
    //     const requestOptions = { headers: this.headers };
    //     return this.http.post<any>(this.baseURl + '/api/lenderproduct/getproductlistforcasesourcingbycaserequirementsdataformortggagecompanybyfmwebapplication', goSourceProductRequest, requestOptions);
    // }

    postEmailOtp(resolutionEmail: string): Observable<any> {

        const requestOptions = { headers: this.headers };
        var body = {
            Email: resolutionEmail,
            Status: 101,
            OTPCode: ""
        }

        return this.http.post<any>(this.baseApiUrl + '/api/userotp/postemailotp', body);
    }

    sendUserEmailOtp(resolutionOtpID: number): Observable<any> {

        const requestOptions = { headers: this.headers };

        return this.http.get<any>(
            this.baseApiUrl + '/api/userotp/senduseremailotp?otpId=' + resolutionOtpID);

    }

    loginByEmailOtp(resolutionLoinModel: PostLoginByEmail): Observable<any> {

        const requestOptions = { headers: this.headers };
        console.log('login token=', requestOptions);
        return this.http.post<any>(
            this.baseApiUrl + '/api/authentication/loginemailotpbymobileappv2', resolutionLoinModel);

    }

    // login with mobile number
    postPhoneOtp(resolutionPhoneModle: SentOtpPhone): Observable<any> {

        const requestOptions = { headers: this.headers };

        return this.http.post<any>(this.baseApiUrl + '/api/userotp/post', resolutionPhoneModle, requestOptions);
    }

    sendUserPhoneOtp(resolutionOtpID: number): Observable<any> {

        const requestOptions = { headers: this.headers };

        return this.http.get<any>(
            this.baseApiUrl + '/api/userotp/sendotpnotification?otpId=' + resolutionOtpID);

    }

    loginByPhoneOtp(resolutionLoinModel: PostLoginByPhone): Observable<any> {

        const requestOptions = { headers: this.headers };

        return this.http.post<any>(
            this.baseApiUrl + '/api/authentication/loginphonumberotpbymobileappv2', resolutionLoinModel);

    }


    logOut(): Observable<any> {

        this.initHTTPHeaderWithAutorization();

        var user = JSON.parse(localStorage.getItem(StorageKeyName.KEY_LOGGEDIN_USER));

        const requestOptions = { headers: this.headers }
        console.log(requestOptions)

        var body = {
            UserId: user.Id,
            UserCompanyId: user.UserCompanyId,
            Reason: "session time out",
        }

        return this.http.post<any>(this.baseApiUrl + '/api/authentication/logoutv2', body, this.requestOptions);
    }

    logOutForUAM(): Observable<any> {

        this.initHTTPHeaderWithAutorization();
        var user = JSON.parse(localStorage.getItem(StorageKeyName.KEY_LOGGEDIN_USER));

        // // const requestOptions = { headers: this.headers }
        // // console.log(requestOptions)

        var body = {
            UserId: user.Id,
            UserCompanyId: user.UserCompanyId,
            Reason: "User logout",
        }

        return this.http.post<any>(this.baseApiUrl + '/api/authentication/logout', body, this.requestOptions);
    }

    loginbyEmailPassoward(resolutionModel: PostEmailPassoward): Observable<any> {

        const requestOptions = { headers: this.headers };
        // var body = {
        //     Email : resolutionEmail,
        //     Status : 101,
        //     OTPCode : ""
        // }
        console.log(requestOptions);

        return this.http.post<any>(this.baseApiUrl + '/api/authentication/loginapi2', resolutionModel);
    }

    emailValidationForSendLoginLing(Email: string): Observable<any> {

        const requestOptions = { headers: this.headers };
        this._MagicAuthModel.Email=Email;
        return this.http.post<any>(
            this.baseApiUrl + '/api/magicauthotp/postv2',this._MagicAuthModel,this.requestOptions);

    }

    sendLoginLinkInEmail(MagicAuthModel: MagicAuthOTP): Observable<any> {

        const requestOptions = { headers: this.headers };
        
        return this.http.get<any>(
            this.baseApiUrl + '/api/magicauthotp/sendloginlinkinemail?otpCode='+ MagicAuthModel.OTPCode);

    }

    loginByEmailLink(OTPCode: string): Observable<any> {

        const requestOptions = { headers: this.headers };
        console.log('login token=', requestOptions);
        return this.http.get<any>(
            this.baseApiUrl + '/api/authentication/loginbymagiclink?OTPCode='+ OTPCode);

    }

    // loginByEmailLinkV2(MagicAuthModel: MagicAuthOTP): Observable<any> {

    //     const requestOptions = { headers: this.headers };
    //     console.log('login token=', requestOptions);
    //     return this.http.get<any>(
    //         this.baseApiUrl + '/api/authentication/loginbymagiclink?OTPCode='+ MagicAuthModel.OTPCode);

    // }
    loginByEmailLinkV2(MagicAuthModel: MagicAuthOTP): Observable<any> {

        const requestOptions = { headers: this.headers };
        
        return this.http.get<any>(
            this.baseApiUrl + '/api/authentication/loginbymagiclink?OTPCode='+ MagicAuthModel.OTPCode);

    }


}
