import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-cards-best-credit',
  templateUrl: './credit-cards-best-credit.component.html',
  styleUrls: ['./credit-cards-best-credit.component.scss']
})
export class CreditCardsBestCreditComponent {

  creditCardsBestCreditInfo = [
    {
      id: 1,
      img_path: '../../../assets/credit-cards-assets/register-logo.svg',
      headingTilte: 'Register with Credit Matrix',
      description:
        'Sign up for Credit Matrix for free and sign \n in to your account.',
    },
    {
      id: 2,
      img_path: '../../../assets/credit-cards-assets/best-credit-card-logo.svg',
      headingTilte: 'Choose the best credit card',
      description: 'Check your card eligibility and apply with\n confidence.',
    },
    {
      id: 3,
      img_path: '../../../assets/credit-cards-assets/credit-cards-offer-logo.svg',
      headingTilte: 'Analyze credit card offers',
      description:
        'Check your offers to see how they compare to your\n personalised deals.',
    },
  ];
}
