
export default class StorageKeyName {

    public static KEY_SET_UP_PRODUCT_DATE: string = "SETUP_PRODUCTS_DATE";
    public static KEY_BUY_TOLET_PRODUCT: string = "HOMEPAGE_BTL_PRODUCTS";
    public static KEY_MORTGAGE_PRODUCT: string = "HOMEPAGE_RESIDENTIAL_MORTGAGE_PRODUCTS";

    public static KEY_BRIDGING_PRODUCT: string = "SERVICEPAGE_BTL_PRODUCTS";
    public static KEY_BUSINESS_LENDING_PRODUCT: string = "SERVICEPAGE_BUSINESS_LENDING_PRODUCTS";
    public static KEY_COMMERCIAL_LOAN_PRODUCT: string = "SERVICEPAGE_COMMERCIAL_LOAN_PRODUCTS";
    public static KEY_DEVELOPMENT_FINANCE_PRODUCT: string = "SERVICEPAGE_DEVELOPMENT_FINANCE_PRODUCTS";
    public static KEY_EQUITY_RELEASE_PRODUCT: string = "SERVICEPAGE_EQUITY_RELEASE_PRODUCTS";


    public static KEY_BEST_RATES_PAGE_PRODUCT_SEARCH_MODEL: string = "BEST_RATES_PAGE_PRODUCT_SEARCH_MODEL";
    public static KEY_BEST_RATES_PAGE_PRODUCT_DATA: string = "BEST_RATES_PAGE_PRODUCT_DATA";

    public static KEY_SCORE_REPORT: string = "SCORE_REPORT";
    public static KEY_FAQ_MY_ACCOUNT: string = "FAQ_MY_ACCOUNT";
    public static KEY_FAQ_ABOUT_CREDIT_METRIX: string = "FAQ_ABOUT_CREDIT_METRIXT";

  
    public static KEY_LOGIN_ACCESS_TOKEN: string = "ACCESS_TOKEN";
    public static KEY_LOGIN_REFRESH_TOKEN: string = "REFRESH_TOKEN";
    
    public static KEY_LOGGEDIN_USER: string = "LOGGEDIN_USER";
    public static KEY_LOGGEDIN_COMPANY: string = "LOGGEDIN_COMPANY";
    public static KEY_USER_NOTIFCATION: string = "USER__NOTIFCATION";

    // new include 
    public static KEY_USER_NOTIFCATION_COUNT: string = "USER_NOTIFCATION_COUNT";
    public static PROJECT_ALREADY_ADDED: string = "PROJECT_ALREADY_ADDED";

    // project document
    public static PROJECT_DOCUMENT_ID: string = "PROJECT_DOCUMENT_ID";
    public static PROJECT_DOCUMENT_Edit_Date: string = "PROJECT_DOCUMENT_Edit_Date";

}