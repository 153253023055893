
import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl,UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router'; 
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { PostEmailPassoward } from 'src/app/models/PostEmailPassoward';
import { PostLoginByEmail } from 'src/app/models/PostLoginByEmail.model';
import { PostLoginByPhone } from 'src/app/models/PostLoginByPhone.model';
import { SentOtpPhone } from 'src/app/models/SentOtpPhone.model';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import { DealRoomService } from 'src/app/services/DealRoom.service';

@Component({
  selector: 'app-project-details-team',
  templateUrl: './project-details-team.component.html',
  styleUrls: ['./project-details-team.component.scss']
})
export class ProjectDetailsTeamComponent implements OnInit{
  
  public _creditMatrixLoginService: CreditMatrixLoginService;
  public _dealRoomService: DealRoomService;
  public _PostLoginByEmailData: PostLoginByEmail;
  public _PostLoginByMobileData: PostLoginByPhone;
  public _SentOtpPhoneModle: SentOtpPhone;
  public _PostEmailPassoward: PostEmailPassoward;


  public hide = true;
  public teamsList:any;
  dynamicForm!: FormGroup;
  public isLoginByEmail = true;
  public isOtpRequestSent = false;
  public submitted=false;
  public ErrorMessage:string="";
  public isSuccess=true;
  ticketFormGroup: FormGroup[];
  public ticket: UntypedFormGroup;
  public ticketforPhone: UntypedFormGroup;


  
  constructor(
    public router:Router,
    public formBuilder: FormBuilder,
    creditMatrixLoginService: CreditMatrixLoginService,
    dealRoomService: DealRoomService,
    private fb: FormBuilder
  ) {
    this._dealRoomService=dealRoomService;
    this._creditMatrixLoginService = creditMatrixLoginService;
    this._PostLoginByEmailData = {} as PostLoginByEmail;
    this._PostLoginByMobileData = {} as PostLoginByPhone;
    this._SentOtpPhoneModle = {} as SentOtpPhone;
    this._PostEmailPassoward = {} as PostEmailPassoward;

    
  }
  ngOnInit() {
    this.GetAllTeamsInformation();
  } 
  public async GetAllTeamsInformation() {

    try {
      this._dealRoomService.getAllTeamsInformation().subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null) {
            this.teamsList=responseData.ResponseData.CompanyStartupsTeamsDatas;
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }


}
