import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, ViewChild, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { User } from 'src/app/models/User.model';
import { SignUpQrComponent } from 'src/app/shared/sign-up-qr/sign-up-qr.component';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import { UAMUsersServices } from 'src/app/services/UAMUsers.service';
import { CompanyStartupsKeyInformationService } from 'src/app/services/CompanyStartupsKeyInformation.service';
import { MediaService } from 'src/app/services/Media.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent {

  public _creditMatrixLoginService: CreditMatrixLoginService;
  public _umaUsersServices: UAMUsersServices;
  public _uAMUsersServices: UAMUsersServices;
  public _mediaService: MediaService;
  public _companyStartupsKeyInformationService: CompanyStartupsKeyInformationService;
  public isMobileMenuShow : boolean = false;
  public isNavFixed : boolean = false;
  public _user: User;
  public isSignUpListShow : boolean = false;
  public isUserItemShow : boolean = false;
  public CurrentUser = {} as User;
  public _currentUser = {} as User;
  isLoading=false;
  public _logoutinfo:any;

  constructor(public router:Router, public dialog: MatDialog,
    creditMatrixLoginService: CreditMatrixLoginService,
    uMAUsersServices: UAMUsersServices,
    mediaService: MediaService,
    umaUsersServices: UAMUsersServices,companyStartupsKeyInformationService: CompanyStartupsKeyInformationService) {
      this._creditMatrixLoginService=creditMatrixLoginService;
      this._umaUsersServices=umaUsersServices;
      this._companyStartupsKeyInformationService=companyStartupsKeyInformationService;
      this._uAMUsersServices = uMAUsersServices;
      this._user = {} as User;
      this._mediaService = mediaService;
    }

  ngOnInit() {
    this._currentUser = AuthProviderHelper.getCurrentUser();
    this.GetUserByUserId(this._currentUser.Id);
  }

  logout(){
    this.UserLogout();
  }

  public async GetUserByUserId(UserId: number) {
    try {
      this._uAMUsersServices.getuserById(UserId).subscribe({
        next: (response) => {
          
          if (response.Success = true) {
            this._user=response.ResponseData.User;
            // const formattedDate = this.convertToHTMLDate(this._user.DateofBirth);
            // this._user.DateofBirth = formattedDate;
            // console.log(this._user);
          }
          
        },
        error: (response) => {
          console.log(response);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  public async UserLogout() {
    try {
      this._creditMatrixLoginService.logOutForUAM().subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success) {
            
           AuthProviderHelper.setResetCurrentUser();
           
            localStorage.clear(); 
            this.router.navigate(['']);
            window.location.href ='';
           // StatusUtility.reloadPage();
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

}
