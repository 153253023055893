import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-cards-card-view',
  templateUrl: './credit-cards-card-view.component.html',
  styleUrls: ['./credit-cards-card-view.component.scss']
})
export class CreditCardsCardViewComponent {
  cardViewInfo = [
    {
      id: 1,
      imgPath: '../../../assets/credit-cards-assets/customized-offer-logo.svg',
      headingTitle: 'Customized Offers',
      description:
        'We show you credit cards based on your credit score and explain your eligibility for each one so you know where you stand before you apply.',
    },
    {
      id: 2,
      imgPath: '../../../assets/credit-cards-assets/market-leading-logo.svg',
      headingTitle: 'Market-leading credit providers',
      description:
        'We collaborate with industry-leading credit providers to bring you great deals on balance transfer cards, purchase cards, and reward cards. We show you the most recent offers from top credit card companies like Capital One, MBNA, Virgin Money, HSBC, and Santander.',
    },
  ];
}
