import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortgagemagicProperty } from '../models/MortgagemagicProperty.model';
import { ContactPost } from '../models/ContactPost.model';
import { PostLoginByEmail } from '../models/PostLoginByEmail.model';
import { SentOtpPhone } from '../models/SentOtpPhone.model';
import { PostLoginByPhone } from '../models/PostLoginByPhone.model';
import StorageKeyName from '../helper/StorageKeyName';
import { PostEmailPassoward } from '../models/PostEmailPassoward';
import { ChatModel } from '../models/Chat.model';
import { TimelinePost } from '../models/Timeline.model';
import { TimelinePostsRequestModel } from '../models/TimelinePostsRequest.model';


@Injectable({
    providedIn: 'root'
})

export class ChatService {

    
    headers: HttpHeaders = new HttpHeaders();
    baseApiUrl: string = environment.UVCSERVERBASEAPI;
    requestOptions:any={};

    constructor(private http: HttpClient) {
        this.initHTTPHeaderWithAutorization();
    }
    private initHTTPHeaderWithAutorization() {
        this.requestOptions.headers = new HttpHeaders();
        var getToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);  
        this.requestOptions.headers = this.requestOptions.headers.set('Content-Type', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Accept', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
        this.requestOptions.headers = this.requestOptions.headers.set('refreshToken', getToken);   
      }



    // getAllChat(resolutionModel: ChatModel): Observable<any> {

    //     const requestOptions = { headers: this.headers };
    //     var timelinePostsRequestModel = {
    //         Page: 1,
    //         Count: 20,
    //         customerId: 0,
    //         TaskId: 0,
    //         timeLineId: 0,
    //         IsPrivate: 'true',
    //         ReceiverId: 180171,
    //         SenderId: 115765,
    //         CustomerId: 115765,
    //     };

    //     return this.http.post<any>(
    //         'https://mortgage-magic.co.uk/api/timeline/getbyscroll', timelinePostsRequestModel
    //     );

    // }


   public PostToTimeline(entityModel: TimelinePost): Observable<any> {

    

        return this.http.post<any>(
            this.baseApiUrl + '/api/timeline/post', entityModel, this.requestOptions);
    }
    
    public GetTimelinePosts(timelinePostsRequest: TimelinePostsRequestModel): Observable<any> {

        let params = new HttpParams().set('CustomerId', timelinePostsRequest.CustomerId)                                     
                                     .set('IsPrivate', timelinePostsRequest.IsPrivate)                                     
                                     .set('ReceiverId', timelinePostsRequest.ReceiverId)
                                     .set('SenderId', timelinePostsRequest.SenderId)
                                     .set('TaskId', timelinePostsRequest.TaskId)
                                     .set('timeLineId', timelinePostsRequest.timeLineId)
                                     .set('Count', timelinePostsRequest.Count)
                                     .set('Page', timelinePostsRequest.Page);

        this.requestOptions.params = params;

        return this.http.get<any>(
            this.baseApiUrl + '/api/timeline/get', this.requestOptions);
    }

    public DeletePost(postId: number): Observable<any> {

        return this.http.delete<any>(
            this.baseApiUrl + '/api/timeline/delete/'+ postId, this.requestOptions);
    }

    public sendEmailAndNotification(timelinePostId : number) : Observable<any> {
        return this.http.get<any>(
            this.baseApiUrl + '/api/timeline/sendemailandnotification/'+ timelinePostId, this.requestOptions);
    };  
    

}
