import { Component } from '@angular/core';

@Component({
  selector: 'app-my-score-history',
  templateUrl: './my-score-history.component.html',
  styleUrls: ['./my-score-history.component.scss'],
})
export class MyScoreHistoryComponent {
  chartOptions = {
    animationEnabled: true,
    theme: 'light3',
    title: {
      text: '',

    },
    axisX: {
      valueFormatString: 'MMM',
      intervalType: 'month', 
      interval: 1,
    },
    axisY: {
      title: '',
      suffix: '',
    },
    toolTip: {
      shared: true,
    },
    legend: {
      cursor: 'pointer',
      itemclick: function (e: any) {
        if (
          typeof e.dataSeries.visible === 'undefined' ||
          e.dataSeries.visible
        ) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chart.render();
      },
    },
    data: [
      {
        type: 'line',
        name: '',
        showInLegend: true,
        //yValueFormatString: '#,###°F',
        dataPoints: [
          { x: new Date(2021, 0, 1), y: 300 },
          { x: new Date(2021, 1, 1), y: 450 },
          { x: new Date(2021, 2, 1), y: 400 },
          { x: new Date(2021, 3, 1), y: 620 },
          { x: new Date(2021, 4, 1), y: 700 },

        ],
      },
    ],
  };
}             

