import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import StorageKeyName from '../helper/StorageKeyName';
import { User } from '../models/User.model';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})

export class UMANotificatonServices {

    headers: HttpHeaders = new HttpHeaders();

    baseApiUrl: string = environment.UVCSERVERBASEAPI;

    constructor(private http: HttpClient) {
        var getToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);

        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Accept', 'application/json');
        this.headers.append('Authorization', `Bearer ${accessToken}`); 
        this.headers.append('refreshToken', getToken); 
        this.headers.append('Access-Control-Allow-Origin', 'https://demo.financemagic.co.uk');      
        this.headers.append('Access-Control-Allow-Methods', 'DELETE, PUT, GET, POST');
        this.headers.append('Access-Control-Allow-Credentials', 'true');
    }

    getNotification(resolutionModel: User): Observable<any> {

        const requestOptions = { headers: this.headers };
    
        return this.http.get<any>(this.baseApiUrl + '/api/notifications/get' + resolutionModel, requestOptions);
    }

    getnotificationbyuseridandusercompanyid(userId: Number, UserCompanyId: Number ): Observable<any> {

        const requestOptions = { headers: this.headers };
    
        return this.http.get<any>(this.baseApiUrl + '/api/notifications/getnotificationbyuseridandusercompanyid?UserCompanyId='
         + UserCompanyId + '&userId=' + userId, requestOptions);
    }

    readallnotificationafterclickreadbutton(userId: Number, UserCompanyId: Number, ): Observable<any> {

        const requestOptions = { headers: this.headers };
                                                    //api/notifications/get/readallnotificationafterclickreadbutton?UserCompanyId
        return this.http.get<any>(this.baseApiUrl + '/api/notifications/get/readallnotificationafterclickreadbutton?UserCompanyId='
         + UserCompanyId + '&userId=' + userId, requestOptions);
    }

    
}
