import { Component } from '@angular/core';

@Component({
  selector: 'app-credit-cards-header',
  templateUrl: './credit-cards-header.component.html',
  styleUrls: ['./credit-cards-header.component.scss']
})
export class CreditCardsHeaderComponent {

}
