import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortgagemagicProperty } from '../models/MortgagemagicProperty.model';
import { ContactPost } from '../models/ContactPost.model';
import { PostLoginByEmail } from '../models/PostLoginByEmail.model';
import { SentOtpPhone } from '../models/SentOtpPhone.model';
import { PostLoginByPhone } from '../models/PostLoginByPhone.model';
import StorageKeyName from '../helper/StorageKeyName';
import { PostEmailPassoward } from '../models/PostEmailPassoward';
import { CompanyStartupsIdeaInformation } from '../models/CompanyStartupsIdeaInformation.model';
import { CompanyStartupsKeyInformation } from '../models/CompanyStartupsKeyInformation.model';
import { CompanyStartupsTeams } from '../models/CompanyStartupsTeams.model';
import { CompanyStartupsDocumentInfo } from '../models/CompanyStartupsDocumentInfo.model';

@Injectable({
    providedIn: 'root'
})
export class MediaService {
    headers: HttpHeaders = new HttpHeaders();
    baseApiUrl: string = environment.UVCSERVERBASEAPI;
    requestOptions:any={};

    constructor(private http: HttpClient) {
        this.initHTTPHeaderWithAutorization();
    }
    private initHTTPHeaderWithAutorization() {
        this.requestOptions.headers = new HttpHeaders();
        var getToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);  
        this.requestOptions.headers = this.requestOptions.headers.set('Content-Type', 'multipart/form-data'); //   "Content-Type": "multipart/form-data" // 👈
        
        undefined
        //this.requestOptions.headers = this.requestOptions.headers.set('Accept', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
        this.requestOptions.headers = this.requestOptions.headers.set('refreshToken', getToken); 
        

      }

      public UploadFiles(fromdata:FormData): Observable<any> {
        
        return this.http.post<any>(
            this.baseApiUrl + '/api/media/uploadfilesyangular',fromdata);
    }

    public UploadPictures(fromdata:FormData): Observable<any> {
        
        return this.http.post<any>(
            this.baseApiUrl + '/api/media/uploadpicturesbyangular', fromdata);//, this.requestOptions
    }

    public UploadFileToServer(fromdata:FormData): Observable<any> {
        
        return this.http.post<any>(
            this.baseApiUrl + '/api/media/uploadfilestoserverbyangular', fromdata);
    }

    
    
    
   
}