import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortgagemagicProperty } from '../models/MortgagemagicProperty.model';
import { ContactPost } from '../models/ContactPost.model';
import { PostLoginByEmail } from '../models/PostLoginByEmail.model';
import { SentOtpPhone } from '../models/SentOtpPhone.model';
import { PostLoginByPhone } from '../models/PostLoginByPhone.model';
import StorageKeyName from '../helper/StorageKeyName';
import { PostEmailPassoward } from '../models/PostEmailPassoward';
import { CompanyStartupsIdeaInformation } from '../models/CompanyStartupsIdeaInformation.model';
import { CompanyStartupsKeyInformation } from '../models/CompanyStartupsKeyInformation.model';
import { CompanyStartupsTeams } from '../models/CompanyStartupsTeams.model';
import { CompanyStartupsDocumentInfo } from '../models/CompanyStartupsDocumentInfo.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyStartupsTeamsService {
    headers: HttpHeaders = new HttpHeaders();
    baseApiUrl: string = environment.UVCSERVERBASEAPI;
    requestOptions: any = {};

    constructor(private http: HttpClient) {
        this.initHTTPHeaderWithAutorization();
    }
    private initHTTPHeaderWithAutorization() {
        this.requestOptions.headers = new HttpHeaders();
        var refreshToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);
        this.requestOptions.headers = this.requestOptions.headers.set('Content-Type', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Accept', 'application/json');
        if (accessToken != null && accessToken.length > 0) {
            this.requestOptions.headers = this.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
            this.requestOptions.headers = this.requestOptions.headers.set('refreshToken', refreshToken);
        }
    }

    getTeamsInformationbyId(Id): Observable<any> {

        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupsteams/getteamsinformationbyid?id=' + Id, this.requestOptions);
    }

    post(companyStartupsTeams: CompanyStartupsTeams): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/companystartupsteams/post', companyStartupsTeams, this.requestOptions);
    }
    put(companyStartupsTeams: CompanyStartupsTeams): Observable<any> {

        return this.http.put<any>(
            this.baseApiUrl + '/api/companystartupsteams/put', companyStartupsTeams, this.requestOptions);
    }

    DeleteTeamsInformationById(Id): Observable<any> {

        return this.http.post<any>(
            this.baseApiUrl + '/api/companystartupsteams/deleteteamsinformationbyid?id=' + Id, this.requestOptions);
    }


    GetAllCompanyStartupsTeamByUserIdAndUserCompanyId(UserId, UserCompanyId): Observable<any> {
        this.initHTTPHeaderWithAutorization();
        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupsteams/getallcompanystartupsteambyuseridandusercompanyid?UserId=' + UserId + '&UserCompanyId=' + UserCompanyId, this.requestOptions);
    }

    DeleteTeamMemberById(TeamMemberId): Observable<any> {

        return this.http.delete<any>(
            this.baseApiUrl + '/api/companystartupsteams/delete/' + TeamMemberId, this.requestOptions);
    }


    GetCompanyStartupsTeamsByCompanyStartupsIdeaInformationGuid(CompanyStartupsIdeaInformationId: string): Observable<any> {

        this.initHTTPHeaderWithAutorization();

        return this.http.get<any>(
            this.baseApiUrl + '/api/companystartupsteams/getCompanyStartupsTeamsbycompanystartupsideainformationId?CompanyStartupsIdeaInformationId=' + CompanyStartupsIdeaInformationId, this.requestOptions);
    }


}