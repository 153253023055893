import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';
import { CompanyStartupsIdeaInformation } from 'src/app/models/CompanyStartupsIdeaInformation.model';
import { CompanyStartupsKeyInformation } from 'src/app/models/CompanyStartupsKeyInformation.model';
import { CompanyStartupsIdeaInformationService } from 'src/app/services/CompanyStartupsIdeaInformation.service';

@Component({
  selector: 'app-deal-room-concept-tab',
  templateUrl: './deal-room-concept-tab.component.html',
  styleUrls: ['./deal-room-concept-tab.component.scss']
})
export class DealRoomConceptTabComponent  implements OnInit {

   
  //public _companyStartupsIdeaInformation:CompanyStartupsIdeaInformation;  
  public _companyStartupsIdeaInformationService: CompanyStartupsIdeaInformationService;

  //public _companyStartupsKeyInformation:CompanyStartupsKeyInformation;  
  public _currrentUser:any;

  @Input() _companyStartupsIdeaInformation
  @Input() _companyStartupsKeyInformation


   constructor(public router: Router, companyStartupsIdeaInformationService:CompanyStartupsIdeaInformationService) {   
    this._companyStartupsIdeaInformationService=companyStartupsIdeaInformationService;   
    // this._companyStartupsIdeaInformation= {} as CompanyStartupsIdeaInformation;
 
   }


  ngOnInit() {
   
    this._currrentUser = AuthProviderHelper.getCurrentUser();    
    //this.GetIdeaInformationByCompanyIdAndUserId(this._currrentUser.Id,this._currrentUser.UserCompanyId);

   // this.initModel();
  }


  // public async getIdeaInformationByCompanyStartupsIdeaInformationId(StartupsIdeaInformationId) {
  //   try {
  //     this._companyStartupsIdeaInformationService.getIdeaInformationByCompanyStartupsIdeaInformationId(StartupsIdeaInformationId).subscribe({
  //       next: (responseData) => {
  //         console.log(responseData);
  //         if (responseData.Success && responseData.ResponseData != null) {
  //           this._companyStartupsIdeaInformation = responseData.ResponseData.companyStartupsIdeaInformation;
  //         }
  //       },
  //       error: (response) => {
  //         console.log(response);
  //         console.log(response.error);
  //       },
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }


  viewVideo(url){

    

    try {
      this._companyStartupsIdeaInformationService.getAmazonS3FileUrlByWebFileUrl(url).subscribe({
        next: (responseData) => {
          // 
          if (responseData.Success) {
           
              window.open(responseData.ResponseData.FileUrl, "_blank");
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
    
  }

}
