
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-from-test',
  templateUrl: './from-test.component.html',
  styleUrls: ['./from-test.component.scss']
})

export class FromTestComponent implements OnInit {
    dynamicForm!: FormGroup;
    submitted = false;

    constructor(private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.dynamicForm = this.formBuilder.group({
            // numberOfTickets: ['', Validators.required],
            calculate: new FormArray([])
        });

        this.t.push(this.formBuilder.group({
          name: [''],
          email: ['']
        }));
    }

    // convenience getters for easy access to form fields
    get f() { return this.dynamicForm.controls; }
    get t() { return this.f.calculate as FormArray; }
    get ticketFormGroups() { return this.t.controls as FormGroup[]; }

    // onChangeTickets(e: any) {
    //     const numberOfTickets = e.target.value || 0;
    //     if (this.t.length < numberOfTickets) {
    //         if (this.t.length === 0) {
    //             // ensure validation messages aren't displaying when the
    //             // number of tickets is first selected (changed from zero)
    //             this.onClear();
    //         }

    //         for (let i = this.t.length; i < numberOfTickets; i++) {
    //             this.t.push(this.formBuilder.group({
    //                 name: ['test', Validators.required],
    //                 email: ['test@gmail.com', [Validators.required, Validators.email]]
    //             }));
    //         }
    //     } else {
    //         for (let i = this.t.length; i >= numberOfTickets; i--) {
    //             this.t.removeAt(i);
    //         }
    //     }
    // }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        // if (this.dynamicForm.invalid) {
        //     return;
        // }

        // display form values on success
        console.log(this.dynamicForm.value.calculate[0].name);
        alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.dynamicForm.value, null, 4));
    }

    onReset() {
        // reset whole form back to initial state
        this.submitted = false;
        this.dynamicForm.reset();
        this.t.clear();
    }

    onClear() {
        // clear errors and reset ticket fields
        this.submitted = false;
        this.t.reset();
    }
}
