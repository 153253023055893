import { Component, Input, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { SignUpQrComponent } from '../sign-up-qr/sign-up-qr.component';

@Component({
  selector: 'app-calculate-section',
  templateUrl: './calculate-section.component.html',
  styleUrls: ['./calculate-section.component.scss']
})
export class CalculateSectionComponent implements OnInit {
  dynamicForm!: FormGroup;
  submitted = false;
  @Input() userName: number;

  // public _mortgageCalculatorInput : MortgageCalculator;

  public income : number = 0;
  public deposit : number = 0;

  public isSelect:boolean = false;

  constructor(public dialog: MatDialog, public formBuilder: FormBuilder) {}

  ngOnInit() {
    // this._mortgageCalculatorInput = new MortgageCalculator();

    this.dynamicForm = this.formBuilder.group({
      calculate: new FormArray([])
    });

    this.t.push(this.formBuilder.group({
      incomeAmount: ['', Validators.required],
      annualAmount: [''],
      depositAmount: ['']
    }));
  }

  // convenience getters for easy access to form fields
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.calculate as FormArray; }
  get ticketFormGroups() { return this.t.controls as FormGroup[]; }

  public selectMathod(type:string) {
    if(type == 'Joint' ){
      this.isSelect = true
    }else{
      this.isSelect = false;
    }
  }

  public calculateFunction(){
    
    this.submitted = true;
    // stop here if form is invalid
    if (this.dynamicForm.invalid) {
      return;
    } 


    if(this.dynamicForm.value.calculate[0].incomeAmount == null || this.dynamicForm.value.calculate[0].depositAmount == null ){
      return
    }else{
      this.income = this.dynamicForm.value.calculate[0].incomeAmount * 4.5;
      this.deposit = this.income + this.dynamicForm.value.calculate[0].depositAmount;
    }
  }

  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }

}
