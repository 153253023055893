import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router'; 
import { PostEmailPassoward } from 'src/app/models/PostEmailPassoward';
import { PostLoginByEmail } from 'src/app/models/PostLoginByEmail.model';
import { PostLoginByPhone } from 'src/app/models/PostLoginByPhone.model';
import { SentOtpPhone } from 'src/app/models/SentOtpPhone.model';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import { DealRoomService } from 'src/app/services/DealRoom.service';
import { CompanyStartupsTeams } from 'src/app/models/CompanyStartupsTeams.model';
import { CompanyStartupsDocumentInfo } from 'src/app/models/CompanyStartupsDocumentInfo.model';
import { CompanyStartupsTeamsService } from 'src/app/services/CompanyStartupsTeams.service';
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';

@Component({
  selector: 'app-project-team-tab',
  templateUrl: './project-team-tab.component.html',
  styleUrls: ['./project-team-tab.component.scss']
})
export class ProjectTeamTabComponent  implements OnInit {
  public _creditMatrixLoginService: CreditMatrixLoginService;
  public _dealRoomService: DealRoomService;
  public _PostLoginByEmailData: PostLoginByEmail;
  public _PostLoginByMobileData: PostLoginByPhone;
  public _SentOtpPhoneModle: SentOtpPhone;
  public _PostEmailPassoward: PostEmailPassoward;
  public _companyStartupsTeamsService: CompanyStartupsTeamsService;
  public _companyStartupsTeams: CompanyStartupsTeams;

  public hide = true;
  public teamsList:any;
  dynamicForm!: FormGroup;
  public isLoginByEmail = true;
  public isOtpRequestSent = false;
  public submitted=false;
  public ErrorMessage:string="";
  public isSuccess=true;
  ticketFormGroup: FormGroup[];
  public ticket: UntypedFormGroup;
  public ticketforPhone: UntypedFormGroup;
  public _currentUser: any;


  
  constructor(
    public router:Router,
    public formBuilder: FormBuilder,
    creditMatrixLoginService: CreditMatrixLoginService,
    dealRoomService: DealRoomService,
    private fb: FormBuilder,
    companyStartupsTeamsService: CompanyStartupsTeamsService
  ) {
    this._dealRoomService=dealRoomService;
    this._creditMatrixLoginService = creditMatrixLoginService;
    this._companyStartupsTeamsService=companyStartupsTeamsService;
    this._PostLoginByEmailData = {} as PostLoginByEmail;
    this._PostLoginByMobileData = {} as PostLoginByPhone;
    this._SentOtpPhoneModle = {} as SentOtpPhone;
    this._PostEmailPassoward = {} as PostEmailPassoward;
    this._currentUser = AuthProviderHelper.getCurrentUser();
    
  }
  ngOnInit() {
    this.GetAllTeamsInformation(this._currentUser.Id, this._currentUser.UserCompanyId);
  } 
  public async GetAllTeamsInformation(UserId,CompanyId) {

    try {
      this._companyStartupsTeamsService.GetAllCompanyStartupsTeamByUserIdAndUserCompanyId(UserId, CompanyId).subscribe({
        next: (responseData) => {
          console.log("team");
          if (responseData.Success && responseData.ResponseData != null) {
            this.teamsList = responseData.ResponseData.CompanyStartupsTeams;
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  public onClickTeamInfo(Id)
  {
    this.router.navigate(['/dashboard/project-team'], { queryParams: { key: Id } });
  }
}
