import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-info-finacial',
  templateUrl: './account-info-finacial.component.html',
  styleUrls: ['./account-info-finacial.component.scss']
})
export class AccountInfoFinacialComponent implements OnInit {

  isActive: boolean = true;
  activeButton() {
    this.isActive = true;
  }
  activeButton2() {
    this.isActive = false;
  }


  // creditCardInfo = [
  //   'Nationwide Building Society',
  //   'Lowell',
  //   'Nationwide Building Society ',
  // ];

  // creditCardAccountInfo = {
  //   Balance: '£52,200',
  //   UpdatedOn: '25 September 2023',
  //   Status: 'Default',
  //   Name: 'Unsecured Loan',
  //   Address: 'Flat 8/C British Hut Street, London, BBQ',
  //   Date_of_Birth: '20-08-1980',
  //   Account_Type: 'Open Account',
  //   Account_Number: 45440000000,
  //   Account_Start_Date: '20-08-1980',
  //   Account_End_Date: '20-08-1980',
  //   Payment_Start_Date: '20-08-1980',
  //   Payment_Frequency: '20-08-1980',
  // };

  creditCardAccount = [
    {
      id: 0,
      landerType : 'Nationwide Building Society',
      landerAccount : {
        Balance: '£52,200',
        UpdatedOn: '25 September 2023',
        Status: 'Default',
      },
      landerdetails:{
        Name: 'Unsecured Loan',
        Address: 'Flat 8/C British Hut Street, London, BBQ',
        Date_of_Birth: '20-08-1980',
        Account_Type: 'Open Account',
        Account_Number: 45440000000,
        Account_Start_Date: '20-08-1980',
        Account_End_Date: '20-08-1980',
        Payment_Start_Date: '20-08-1980',
        Payment_Frequency: '20-08-1980',
      }
    },
    {
      id: 1,
      landerType : 'Lowell',
      landerAccount : {
        Balance: '£52,200',
        UpdatedOn: '25 September 2023',
        Status: 'Default',
      },
      landerdetails:{
        Name: 'Unsecured Loan',
        Address: 'Flat 8/C British Hut Street, London, BBQ',
        Date_of_Birth: '20-08-1980',
        Account_Type: 'Open Account',
        Account_Number: 45440000000,
        Account_Start_Date: '20-08-1980',
        Account_End_Date: '20-08-1980',
        Payment_Start_Date: '20-08-1980',
        Payment_Frequency: '20-08-1980',
      }
    },
    {
      id: 2,
      landerType : 'Nationwide Building Society',
      landerAccount : {
        Balance: '£52,200',
        UpdatedOn: '25 September 2023',
        Status: 'Default',
      },
      landerdetails:{
        Name: 'Unsecured Loan',
        Address: 'Flat 8/C British Hut Street, London, BBQ',
        Date_of_Birth: '20-08-1980',
        Account_Type: 'Open Account',
        Account_Number: 45440000000,
        Account_Start_Date: '20-08-1980',
        Account_End_Date: '20-08-1980',
        Payment_Start_Date: '20-08-1980',
        Payment_Frequency: '20-08-1980',
      }
    }
  ]



  


  ngOnInit(){
    // throw new Error('Method not implemented.');
  }


}
