import { Component } from '@angular/core';

@Component({
  selector: 'app-deal-room-tab-for-login',
  templateUrl: './deal-room-tab-for-login.component.html',
  styleUrls: ['./deal-room-tab-for-login.component.scss']
})
export class DealRoomTabForLoginComponent {

}
