import { Component } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { SignUpQrComponent } from '../sign-up-qr/sign-up-qr.component';
// import { InsuranceQrComponent } from '../insurance-qr/insurance-qr.component';
// import { CreditReportQrComponent } from '../credit-report-qr/credit-report-qr.component';

@Component({
  selector: 'app-heros-v2',
  templateUrl: './heros-v2.component.html',
  styleUrls: ['./heros-v2.component.scss']
})

export class HerosV2Component {
  constructor(public dialog: MatDialog) {}

  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }

  // openDialogInsurance() {
  //   this.dialog.open(InsuranceQrComponent, {
  //     width: '458px',
  //   });
  // }

  // openDialogCreditReport() {
  //   this.dialog.open(CreditReportQrComponent, {
  //     width: '458px',
  //   });
  // }
}
