import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-notification',
  templateUrl: './alert-notification.component.html'
})
export class AlertNotificationComponent {

  @Input() Message: any;
  @Input() AlertNotificationType:  any;

  visible = true;

  onVisibleChange(eventValue: boolean) {
    this.visible = eventValue;
  }

  onResetDismiss() {
    this.visible = true;
  }
}