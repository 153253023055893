import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpecialAbout } from 'src/app/models/SpecialAbout.model';
import { SignUpQrComponent } from '../sign-up-qr/sign-up-qr.component';
import { CreditReportQrComponent } from '../credit-report-qr/credit-report-qr.component';

@Component({
  selector: 'app-endless-card',
  templateUrl: './endless-card.component.html',
  styleUrls: ['./endless-card.component.scss']
})
export class EndlessCardComponent implements OnInit {
  @Input() endless: SpecialAbout;
  ngOnInit() {

  }

  
  constructor(public dialog: MatDialog) {}

  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }

  openDialogCreditReport() {
    this.dialog.open(CreditReportQrComponent, {
      width: '458px',
    });
  }
}
