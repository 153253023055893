import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { PostEmailPassoward } from 'src/app/models/PostEmailPassoward';
import { PostLoginByEmail } from 'src/app/models/PostLoginByEmail.model';
import { PostLoginByPhone } from 'src/app/models/PostLoginByPhone.model';
import { SentOtpPhone } from 'src/app/models/SentOtpPhone.model';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import { DealRoomService } from 'src/app/services/DealRoom.service';
import { ActivatedRoute } from "@angular/router";
import { CompanyStartupsIdeaInformation } from 'src/app/models/CompanyStartupsIdeaInformation.model';
import { MediaService } from 'src/app/services/Media.service';
import { ProjectConceptModel } from 'src/app/models/ProjectConcept.model';

import { CompanyStartupsIdeaInformationService } from 'src/app/services/CompanyStartupsIdeaInformation.service';

@Component({
  selector: 'app-project-concept-tab',
  templateUrl: './project-concept-tab.component.html',
  styleUrls: ['./project-concept-tab.component.scss']
})

export class ProjectConceptTabComponent implements OnInit {

  @Input() _companyStartupsIdeaInformation;

  public isFirstVideoShow: boolean = false;
  public isSecondVideoShow: boolean = false;
  public isThirdVideoShow: boolean = false;

  public imageURl: string = '';
  public videoUrl: string = '';
  public docUrl: string = '';
  public _projectConcept:any;
  public isImageExist:boolean = false;
  public isVideoExist:boolean = false;
  public isDocExist:boolean = false;
  public fileName: string | null = null;
  _IdeainfoByconpanyId:any;
  usercompanyId:any;
  projectForm: FormGroup;
  //public _companyStartupsIdeaInformation:CompanyStartupsIdeaInformation;  
  public _companyStartupsIdeaInformationService: CompanyStartupsIdeaInformationService;


  //public _dealRoomService: DealRoomService;
  
  public _mediaService: MediaService;
  public _currrentUser:any;

   constructor(public router: Router, private activatedRoute: ActivatedRoute,companyStartupsIdeaInformationService:CompanyStartupsIdeaInformationService,mediaService: MediaService) { 
  //   this._dealRoomService=dealRoomService;
    this._companyStartupsIdeaInformationService=companyStartupsIdeaInformationService;
    this._mediaService=mediaService;
     //this._companyStartupsIdeaInformation= {} as CompanyStartupsIdeaInformation;
 
   }

   public initModel(){
    this._companyStartupsIdeaInformation = {
      Id:0,
      UserId: this._currrentUser.Id,
      UserCompanyId: this._currrentUser.UserCompanyId,
      Status:101,
      CreationDate: new Date(),
      UpdatedDate: new Date(),
    
    } as CompanyStartupsIdeaInformation;
  }

  ngOnInit() {
    var currentUserJSON = localStorage.getItem(StorageKeyName.KEY_LOGGEDIN_USER);
    this._currrentUser = JSON.parse(currentUserJSON);
    
  //  this.GetIdeaInformationByCompanyIdAndUserId(this._currrentUser.Id,this._currrentUser.UserCompanyId);

    //this.initModel();
  }

  public onFileSelected(event: any, variavleName:string) {
    console.log(variavleName);
    const file: File = event.target.files[0];
    if (file) {
      this._projectConcept[variavleName] = file.name;
      console.log(this._projectConcept);
      if(variavleName == 'presentationImage'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.imageURl = event.target.result;
        }
        this.isImageExist= true;
      }else if(variavleName == 'presentationVideo'){
        this.isVideoExist= true;
        var reader = new FileReader();
        console.log(reader)
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.videoUrl = event.target.result;
        }
        // const videoElement = document.createElement('video');
        // videoElement.src = URL.createObjectURL(file);
        // videoElement.addEventListener('loadeddata', () => {
        //   const canvas = document.createElement('canvas');
        //   const context = canvas.getContext('2d');
        //   canvas.width = videoElement.videoWidth;
        //   canvas.height = videoElement.videoHeight;
        //   context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        //   this.videoUrl = canvas.toDataURL('image/png');
        // });
      }else if(variavleName == 'presentationDoc'){
        this.isDocExist= true;
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.docUrl = event.target.result;
        }
      }
    }
    
  }

  public onShowVido(variavleName:string) {
    this[variavleName] = !this[variavleName]
  }

  public onFileRemove(variavleName:string) {
    this._projectConcept[variavleName] = '';
    console.log(this._projectConcept)
    console.log(this._projectConcept[variavleName].length);
    
    if(variavleName == 'presentationImage'){
      this.isImageExist= false;
    }else if(variavleName =='presentationVideo'){
      this.isVideoExist= false;
    }else if(variavleName == 'presentationDoc'){
      this.isDocExist= false;
    }
  }

  public async GetIdeaInformationByCompanyIdAndUserId(UserId,CompanyId) {
    try {
      this._companyStartupsIdeaInformationService.getIdeaInformationByCompanyIdAndUserId(UserId,CompanyId).subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success && responseData.ResponseData != null) {
            this._companyStartupsIdeaInformation = responseData.ResponseData.CompanyStartupsIdeaInformation;
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  public onClickIdeaInfo(CompanyStartupsIdeaInformationId)
  {
    //this.router.navigate(['/dashboard/project-idea']);
    this.router.navigate(['/dashboard/project-idea'], { queryParams: { key: CompanyStartupsIdeaInformationId } });
  }

  
  viewVideo(url){

    

    try {
      this._companyStartupsIdeaInformationService.getAmazonS3FileUrlByWebFileUrl(url).subscribe({
        next: (responseData) => {
          // 
          if (responseData.Success) {
           
              window.open(responseData.ResponseData.FileUrl, "_blank");
          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
    
  }
  

}
