import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MobilOTP } from '../models/MobilOTP.model';


@Injectable({
    providedIn: 'root'
})

export class FMMobileOTPService {

    headers: HttpHeaders = new HttpHeaders();    
    baseApiUrl: string = environment.UVCSERVERBASEAPI;
        

    constructor(private http: HttpClient) {
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Accept', 'application/json');
    }

    postMobileQR(UserOTP: MobilOTP): Observable<any> {
        const requestOptions = { headers: this.headers };

        return this.http.post<any>(this.baseApiUrl + '/api/userotp/post/postuserotpforfinancemagicapplicationdownload', UserOTP, requestOptions);
    }

    sendSMSNotification(userOTPId): Observable<any> {

        const requestOptions = { headers: this.headers };    
        return this.http.get<any>(this.baseApiUrl +'/api/userotp/get/sendotpnotificationforfinancemagicapplicationdownload?otpId='+userOTPId, requestOptions);
    }

    
    // getServerStatus(): Observable<any> {

    //     const requestOptions = { headers: this.headers };
    
    //     return this.http.get<any>('http://localhost:59082/api/serverstatus/get/getserverlivestatus', requestOptions);

    // }

    ///http://localhost:59082/api/userotp/post/postuserotpforfinancemagicapplicationdownload

}
