import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortgagemagicProperty } from '../models/MortgagemagicProperty.model';
import { ContactPost } from '../models/ContactPost.model';
import { PostLoginByEmail } from '../models/PostLoginByEmail.model';
import { SentOtpPhone } from '../models/SentOtpPhone.model';
import { PostLoginByPhone } from '../models/PostLoginByPhone.model';
import StorageKeyName from '../helper/StorageKeyName';
import { PostEmailPassoward } from '../models/PostEmailPassoward';
import { User } from '../models/User.model';


@Injectable({
    providedIn: 'root'
})

export class UAMUsersServices {

    headers: HttpHeaders = new HttpHeaders();

    baseApiUrl: string = environment.UVCSERVERBASEAPI;

    requestOptions: any = {};

    constructor(private http: HttpClient) {
        this.initHTTPHeaderWithAutorization();
    }


    private initHTTPHeaderWithAutorization() {

        this.requestOptions.headers = new HttpHeaders();

        var getToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_REFRESH_TOKEN);
        const accessToken = localStorage.getItem(StorageKeyName.KEY_LOGIN_ACCESS_TOKEN);

        this.requestOptions.headers = this.requestOptions.headers.set('Content-Type', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Accept', 'application/json');
        this.requestOptions.headers = this.requestOptions.headers.set('Authorization', 'Bearer ' + accessToken);
        this.requestOptions.headers = this.requestOptions.headers.set('refreshToken', getToken);

    }
    getuserById(userId: number): Observable<any> {
        this.initHTTPHeaderWithAutorization();
        return this.http.get<any>(this.baseApiUrl + '/api/users/get/' + userId, this.requestOptions);
    }

    userEdit(userEntityModel: User): Observable<any> {

        return this.http.put<any>(this.baseApiUrl + '/api/users/put', userEntityModel, this.requestOptions);
    }

    IsUserLogIn(): Observable<any> {
        return this.http.get<any>(this.baseApiUrl + '/api/users/get/me', this.requestOptions);
    }

    logOutForUAM(): Observable<any> {
        this.initHTTPHeaderWithAutorization();
        return this.http.post<any>(this.baseApiUrl + '/api/authentication/logout', this.requestOptions);
    }
   


    getUserProfileVerificationDataByUserCompanyIdAndUserId(UserCompanyId: number, UserId: number): Observable<any> {
        return this.http.get<any>(
            this.baseApiUrl + '/api/users/get/getbadgesprofileverificationdata?UserCompanyId=' + UserCompanyId + "&UserId=" + UserId, this.requestOptions);
    }


}
