import { Component } from '@angular/core';

@Component({
  selector: 'app-register-add-ubo',
  templateUrl: './register-add-ubo.component.html',
  styleUrls: ['./register-add-ubo.component.scss']
})
export class RegisterAddUBOComponent {

}
