import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, ViewChild, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import AuthProviderHelper from 'src/app/helper/AuthProviderHelper';
import StorageKeyName from 'src/app/helper/StorageKeyName';
import { User } from 'src/app/models/User.model';
import { SignUpQrComponent } from 'src/app/shared/sign-up-qr/sign-up-qr.component';
import { CreditMatrixLoginService } from 'src/app/services/CreditMatrixLogin.service';
import { UAMUsersServices } from 'src/app/services/UAMUsers.service';
import { CompanyStartupsKeyInformationService } from 'src/app/services/CompanyStartupsKeyInformation.service';
import StatusUtility from 'src/app/utility/Status.Utility';
import { off } from 'process';
import moment from 'moment';

@Component({
  selector: 'app-navber',
  templateUrl: './navber.component.html',
  styleUrls: ['./navber.component.scss']
})
export class NavberComponent implements OnInit {
  public _creditMatrixLoginService: CreditMatrixLoginService;
  public _umaUsersServices: UAMUsersServices;
  public _companyStartupsKeyInformationService: CompanyStartupsKeyInformationService;
  public isMobileMenuShow: boolean = false;
  public isNavFixed: boolean = false;
  public isSignUpListShow: boolean = false;
  public isUserItemShow: boolean = false;
  public CurrentUser = {} as User;
  public _user: User;
  public _currentUser = {} as User;
  isLoading = false;
  public _logoutinfo: any;

  constructor(public router: Router, public dialog: MatDialog,
    creditMatrixLoginService: CreditMatrixLoginService,
    umaUsersServices: UAMUsersServices, companyStartupsKeyInformationService: CompanyStartupsKeyInformationService) {
    this._creditMatrixLoginService = creditMatrixLoginService;
    this._umaUsersServices = umaUsersServices;
    this._companyStartupsKeyInformationService = companyStartupsKeyInformationService;
    this._user = {} as User;

  }

  ngOnInit() {
    this._currentUser = AuthProviderHelper.getCurrentUser();
    this.GetUserByUserId(this._currentUser.Id);
  }

  openDialog() {
    this.dialog.open(SignUpQrComponent, {
      width: '458px',
    });
  }

  convertToHTMLDate(dateString: string): string {
    const dateParts = dateString.split('-');
    // Reformatting to YYYY-MM-DD format
    return `${dateParts[2]}-${this.getMonthNumber(dateParts[1])}-${dateParts[0]}`;
  }

  getMonthNumber(monthString: string): string {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return (monthNames.indexOf(monthString) + 1).toString().padStart(2, '0');
  }

  public async GetUserByUserId(UserId: number) {
    try {
      this._umaUsersServices.getuserById(UserId).subscribe({
        next: (response) => {

          if (response.Success = true) {
            this._user = response.ResponseData.User;
            
            this._user.PassportExpiryDate = moment(response.ResponseData.User.PassportExpiryDate).format('M/D/YYYY')
            console.log(response.ResponseData.User)
            
            console.log(this._user.PassportExpiryDate)
            console.log('hello')

            const formattedDate = this.convertToHTMLDate(this._user.DateofBirth);
            this._user.DateofBirth = formattedDate;
            console.log(this._user);
          }

        },
        error: (response) => {
          console.log(response);
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  public mobileMenuShow() {
    if (this.isMobileMenuShow) {
      this.isMobileMenuShow = false
    } else {
      this.isMobileMenuShow = true;
    }
  }

  public openProfileDropdownMenu() {
    this.isUserItemShow = !this.isUserItemShow;
  }

  public logOut() {
    this.UserLogout();

  }
  public logOutafterlogin() {
    this.UserLogout();

  }

  public async UserLogout() {
    this.isLoading = true
    try {
      this._creditMatrixLoginService.logOutForUAM().subscribe({
        next: (responseData) => {
          console.log(responseData);
          if (responseData.Success) {

            this._currentUser.Id = 0;

            AuthProviderHelper.setResetCurrentUser();

            localStorage.clear();
            this.router.navigate(['']);
            window.location.href = '';
            //  StatusUtility.reloadPage();

          }
        },
        error: (response) => {
          console.log(response);
          console.log(response.error);
        },
      });


    } catch (error) {
      console.error(error);
    }
  }

  
  @HostListener('window:scroll') onWindowScroll() {
    const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

    if (this.isSignUpListShow) {
      this.isSignUpListShow = false;
    }
    if (this.isMobileMenuShow) {
      this.isMobileMenuShow = false;
    }
    
    // this.isSignUpListShow = !this.isMobileMenuShow;
  }

  public signUpListToggle() {
    this.isSignUpListShow = !this.isSignUpListShow;
  }


  public hideProfileDropdownMenu() {
   
    this.isMobileMenuShow = false
  
} 


  public onClickProjectButton() {
    var project_Added = localStorage.getItem(StorageKeyName.PROJECT_ALREADY_ADDED);
    if (project_Added != null && project_Added == "YES") {
      this.router.navigate(['/dashboard/project']);
    }
    else {
      this.router.navigate(['/dashboard/project-dashboard']);
    }
  }


}
